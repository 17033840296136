import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FiCode, FiX } from 'react-icons/fi';

export default class OverlappingMenuEntry extends Component {
  render() {
    const {
      path,
      mostRecentPaneId,
      hideOverlappingMenu,
      selectNotebook,
      isActive,
      paneId,
    } = this.props;
    return (
      <div
        className={'overlapping-menu-entry' + (isActive ? ' selected' : '')}
        onClick={() => {
          selectNotebook(path);
          hideOverlappingMenu();
        }}
      >
        <FiCode className={'overlapping-icon'} size={'20px'} />
        <p className={'overlapping-name'}>{this.props.name}</p>
        <FiX
          className={'overlapping-icon close'}
          size={'14px'}
          onClick={() => {
            this.props.closeNotebook(path, paneId, true);
            this.props.updateOverlappingTabs();
          }}
        />
      </div>
    );
  }
}
OverlappingMenuEntry.propTypes = {
  name: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  selectNotebook: PropTypes.func.isRequired,
  closeNotebook: PropTypes.func.isRequired,
  hideOverlappingMenu: PropTypes.func.isRequired,
  updateOverlappingTabs: PropTypes.func.isRequired,
  mostRecentPaneId: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  paneId: PropTypes.string.isRequired,
};
