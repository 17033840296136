import React, { Component } from 'react';
import { DropTarget } from 'react-dnd';
import { FiChevronsDown, FiChevronsUp } from 'react-icons/fi';
import ReactLoading from 'react-loading';
import Measure from 'react-measure';

import LauncherTabLinkElement from './launcher/LauncherTabLinkElement.container';
import OverlappingMenu from './overlapping-menu/OverlappingMenu.container';
import TabLinkElement from './tab-link/TabLinkElement.container';
import { Pane, PaneContent } from '../../../../../workbench/types';
import { getUniqueTabNames } from '../../../utils';
import { DND_TYPES } from '../editor-parent/dndConstants';
import { elementTabLine, tabLineCollect } from '../editor-parent/dndContracts';

interface TabLineProps {
  overlappingMenuActive?: boolean;
  overlappingMenuIconActive?: boolean;
  handleSelect(...args: unknown[]): unknown;
  closeNotebook(...args: unknown[]): unknown;
  measuredParentWidth(...args: unknown[]): unknown;
  showOverlappingMenu(...args: unknown[]): unknown;
  hideOverlappingMenu(...args: unknown[]): unknown;
  connectDropTarget(...args: unknown[]): unknown;
  isOver?: boolean;
  isOverCurrent?: boolean;
  canDrop?: boolean;
  moveNotebookToAnotherPane(...args: unknown[]): unknown;
  paneId: string;
  pane: Pane | undefined;
}

/**
 * This component creates a 'TabLink'-instance for every notebook in the state.
 */
class TabLine extends Component<TabLineProps> {
  /**
   * Renders the overlapping menu icon that is supposed to be shown if not all tabs can be displayed because they're
   * too wide
   * @returns {*}
   */
  renderOverlappingMenuIcon() {
    return (
      <div
        className={`show-all-button${
          this.props.overlappingMenuActive ? ' active' : ''
        }`}
        onClick={
          this.props.overlappingMenuActive
            ? this.props.hideOverlappingMenu
            : this.props.showOverlappingMenu
        }
      >
        {this.props.overlappingMenuActive ? (
          <FiChevronsUp className={'show-all-icon'} size={'20px'} />
        ) : (
          <FiChevronsDown className={'show-all-icon'} size={'20px'} />
        )}
      </div>
    );
  }

  renderTab(contentElement: PaneContent) {
    const { paneId } = this.props;
    return (
      <TabLinkElement
        key={contentElement.path}
        name={contentElement.tabName || contentElement.name}
        path={contentElement.path}
        type={contentElement.type}
        paneId={paneId}
      />
    );
  }

  renderLauncherTab(contentElement: PaneContent) {
    const { paneId } = this.props;
    return <LauncherTabLinkElement key={contentElement.path} paneId={paneId} />;
  }

  /**
   * Renders the tabs for the remote system AND for the opened notebooks / codes / ...
   * @param measureRef
   * @returns {*}
   */
  renderContentTabs(measureRef) {
    const { pane } = this.props;

    const contentWithUniqueTabNames = pane.content
      ? getUniqueTabNames(pane.content)
      : [];

    return (
      <div ref={measureRef} className={'notebook-tab-links'}>
        {pane &&
          contentWithUniqueTabNames.map((contentElement) => {
            if (contentElement.type === 'launcher') {
              return this.renderLauncherTab(contentElement);
            }
            return this.renderTab(contentElement);
          })}
        {pane && pane.loadingContent && (
          <div className={'loading-container'}>
            <ReactLoading className={'busy'} type={'cylon'} />
          </div>
        )}
      </div>
    );
  }

  render() {
    const { connectDropTarget, isOverCurrent, canDrop, paneId } = this.props;
    return connectDropTarget(
      <div className={'notebook-tab-link-container'}>
        {isOverCurrent && canDrop && (
          <div className={'tab-line-drag-overlay'} />
        )}
        <Measure
          bounds
          onResize={(contentRect) =>
            this.props.measuredParentWidth(contentRect.bounds.width)
          }
        >
          {({ measureRef }) => this.renderContentTabs(measureRef)}
        </Measure>
        {this.props.overlappingMenuIconActive &&
          this.renderOverlappingMenuIcon()}
        {this.props.overlappingMenuActive && (
          <OverlappingMenu paneId={paneId} />
        )}
      </div>
    );
  }
}

export default DropTarget(
  DND_TYPES.FILE_TAB,
  elementTabLine,
  tabLineCollect
)(TabLine);
