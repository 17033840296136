import React, { FC } from 'react';

import styles from './styles.module.scss';
import Busy from '../../atoms/busy/Busy';
import Logo from '../../atoms/icons/logo/Logo';

export type Props = {};

const AppInitializing: FC<Props> = (props) => {
  return (
    <div className={styles.appInitializing}>
      <header className={styles.header}>
        <div className={styles.headerRow}>
          <div className={styles.headerLeft}>
            <Logo />
          </div>
        </div>
      </header>
      <div className={styles.loadingContainer}>
        <Busy isBusy />
      </div>
    </div>
  );
};

export default AppInitializing;
