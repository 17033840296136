import { HabitatWithScopes } from 'common/dist/types/habitat';
import React, { FC, useState } from 'react';

import Augurs from './augurs/Augurs';
import CodeCapsulesContainer from './code-capsules/CodeCapsules.container';
import Header from './header/Header';
import styles from './styles.module.scss';
import { useCodeCapsules } from '../../../core/api/codeCapsules';
import { defaultOrdering } from '../../../core/dashboard';

type Props = {
  habitat: HabitatWithScopes;
};

export enum HABITAT_TAB {
  AUGURS = 'augurs',
  CODE_CAPSULES = 'code-capsules',
}

export type OrderType = {
  label?: string; // Refine list of available labels
  value: string;
};

const Habitat: FC<Props> = (props) => {
  const { habitat } = props;

  const { data: codeCapsules } = useCodeCapsules(habitat.code);

  const [isListView, setIsListView] = useState(false);
  const [order, setOrder] = useState<OrderType>({
    value: defaultOrdering,
  });
  const [activeTab, setActiveTab] = useState<HABITAT_TAB>(HABITAT_TAB.AUGURS);

  return (
    <div className={styles.habitat} id={`habitat-${habitat.code}`}>
      <Header
        habitat={habitat}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        codeCapsulesCount={(codeCapsules || []).length}
        isListView={isListView}
        setHabitatView={setIsListView}
        order={order}
        setOrder={setOrder}
      />

      <div className={styles.habitatContent}>
        {activeTab === HABITAT_TAB.AUGURS && (
          <div>
            <Augurs
              habitatCode={habitat.code}
              isListView={isListView}
              order={order}
            />
          </div>
        )}

        {activeTab === HABITAT_TAB.CODE_CAPSULES && (
          <div>
            <CodeCapsulesContainer
              habitatCode={habitat.code}
              isListView={isListView}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Habitat;
