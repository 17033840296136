import classNames from 'classnames';
import React, { FC } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { FiAlertOctagon, FiEdit, FiTrash } from 'react-icons/fi';
import { RxDragHandleDots2 } from 'react-icons/rx';

import { AugurMenuIcons } from './icons';
import styles from './styles.module.scss';
import { AugurCategoryType, AugurMenuEntry } from './types';
import Button from '../../atoms/button/Button';
import ClickOutsideDetector from '../../atoms/click-outside-detector/ClickOutsideDetector';

type Props = {
  entry: AugurMenuEntry;
  /** ID of the category this entry belongs to */
  categoryId: string;
  /** Index inside the given category */
  index: number;
  /** Is this entry currently selected? */
  isActive?: boolean;
  /** Callback if the entry was selected */
  handleSelect: () => void;
  /** Callback to delete the entry */
  deleteEntry: () => void;
  /** The entry that is currently being edited (the text input element is shown instead of the static label) */
  isEditing: boolean;
  /** Set whether the entry is being edited */
  setEditing: (isEditing: boolean) => void;
  renameEntry: (newName: string) => void;
  // categoryIsFixed: boolean;
  isDisabled: boolean;
  categoryType: AugurCategoryType;
};

const EntryEditable: FC<Props> = (props) => {
  const {
    entry,
    categoryId,
    index,
    isActive,
    handleSelect,
    deleteEntry,
    isEditing,
    setEditing,
    renameEntry,
    isDisabled,
    categoryType,
  } = props;

  const renderEnabled = () => {
    const Icon = AugurMenuIcons[entry.iconId];
    return (
      <Draggable
        key={entry.id}
        draggableId={entry.id}
        type={categoryType}
        index={index}
      >
        {(provided, snapshot) => (
          <div
            className={classNames(styles.entry, styles.editable, {
              [styles.isActive]: isActive,
              [styles.isDragging]: snapshot.isDragging,
            })}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <div
              key={`${categoryId}_${index}`}
              className={styles.tabLink}
              onClick={handleSelect}
            >
              <div className={styles.tabLinkContent}>
                <div className={styles.iconsParentLeft}>
                  <RxDragHandleDots2 size={16} className={styles.dragIcon} />
                </div>
                <div className={styles.icon}>
                  <Icon size={16} />
                </div>
                {!isEditing && (
                  <div className={styles.labelContainer}>
                    <div className={styles.label}>{entry.name}</div>
                    {!!entry.error && (
                      <div
                        className={styles.errorIndicator}
                        title={entry.error}
                      >
                        <FiAlertOctagon size={16} color={'red'} />
                      </div>
                    )}
                  </div>
                )}
                {isEditing && (
                  <ClickOutsideDetector
                    onClickOutside={() => setEditing(false)}
                  >
                    <div className={styles.input}>
                      <input
                        type={'text'}
                        value={entry.name}
                        autoFocus={true}
                        onChange={(e) => renameEntry(e.target.value)}
                      />
                    </div>
                  </ClickOutsideDetector>
                )}
                {!isEditing && (
                  <div
                    className={styles.iconsParentRight}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Button
                      Icon={() => <FiEdit size={16} />}
                      shape={'squared'}
                      onClick={() => setEditing(true)}
                    />
                    <Button
                      Icon={() => <FiTrash size={16} />}
                      shape={'squared'}
                      onClick={deleteEntry}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </Draggable>
    );
  };

  const renderDisabled = () => {
    const Icon = AugurMenuIcons[entry.iconId];
    return (
      <div className={classNames(styles.entry, styles.editable)}>
        <div className={classNames(styles.tabLink, styles.disabled)}>
          <div className={styles.disabledLink}>
            <div className={styles.icon}>
              <Icon size={16} />
            </div>
            <div className={styles.labelContainer}>
              <div className={styles.label}>{entry.name}</div>
              {!!entry.error && (
                <div className={styles.errorIndicator} title={entry.error}>
                  <FiAlertOctagon size={16} color={'red'} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (isDisabled) {
    return renderDisabled();
  } else {
    return renderEnabled();
  }
};

export default EntryEditable;
