import { createAction } from 'redux-act';
import { call, put, takeEvery } from 'redux-saga/effects';

import * as Api from '../../core/api';

export const fetchComponentPermissions = createAction(
  'fetch component permissions'
);

export const fetchComponentPermissionsSuccess = createAction(
  'fetch component permissions - success',
  (data) => ({ data })
);

export const fetchComponentPermissionsFailure = createAction(
  'fetch component permissions - failure',
  (error) => ({ error })
);

export const reducer = {
  [fetchComponentPermissions]: (state) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      permissions: {
        ...state.dashboard.permissions,
        loading: true,
      },
    },
  }),

  [fetchComponentPermissionsSuccess]: (state, { data }) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      permissions: {
        loading: false,
        loaded: true,
        error: undefined,
        data,
      },
    },
  }),

  [fetchComponentPermissionsFailure]: (state, { error }) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      permissions: {
        loading: false,
        loaded: true,
        error,
        data: undefined,
      },
    },
  }),
};

export function* fetchComponentPermissionsSaga() {
  const { response, error } = yield call(
    Api.permissions.evaluatePermissions,
    'dashboard-component'
  );
  if (response) {
    yield put(fetchComponentPermissionsSuccess(response));
  } else {
    yield put(fetchComponentPermissionsFailure(error));
  }
}

export function* watchFetchComponentPermissions() {
  yield takeEvery(
    fetchComponentPermissions.getType(),
    fetchComponentPermissionsSaga
  );
}
