import { CodeCapsuleVersion } from 'common/dist/types/codeCapsule';
import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useCodeCapsule } from '../../../core/api/codeCapsules';
import { triggerRunCodeCapsuleJob } from '../../../redux/modules/triggerJobs.module';
import { useAppDispatch } from '../../../store/store';
import Busy from '../../atoms/busy/Busy';
import {
  FormattedDateTime,
  FormattedRelativeTimeConvenient,
} from '../../atoms/formatted-date-time/FormattedDateTime';
import { CodeCapsuleDetailsRouteParams } from '../../index/routes';
import RunCodeCapsuleModal from '../../runCodeCapsuleModal/RunCodeCapsuleModal.container';

const Versions: FC = () => {
  const dispatch = useAppDispatch();

  const [runCodeCapsuleModalOpen, setRunCodeCapsuleModalOpen] = useState(false);
  const [selectedVersionInModal, setSelectedVersionInModal] = useState<
    CodeCapsuleVersion | undefined
  >();

  const {
    habitatCode,
    codeCapsuleCode,
  } = useParams<CodeCapsuleDetailsRouteParams>();

  const {
    data: codeCapsule,
    error,
    isLoading,
    isError,
    isSuccess,
  } = useCodeCapsule(codeCapsuleCode);

  const renderLoaded = () => {
    const sortedVersions = codeCapsule.versions;
    if (sortedVersions)
      sortedVersions.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));

    return (
      <div className={'code-capsule-container versions-container'}>
        <div className={'code-capsules custom-table'}>
          <div className={'ct-row ct-headline'}>
            <div className={'ct-col ct-col-280px'}>
              <span>Version Number</span>
            </div>
            <div className={'ct-col ct-col-200px'}>
              <span>Created At</span>
            </div>
          </div>

          {sortedVersions?.map((version) => (
            <div className={'ct-row'} key={version.number}>
              <div
                className={'ct-col ct-col-280px'}
                style={{
                  justifyContent: 'start',
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%',
                  margin: 'auto',
                }}
              >
                <div className={'ct-value'}>
                  <span>{version.number}</span>
                </div>
              </div>
              <div
                className={'ct-col ct-col-200px'}
                style={{
                  justifyContent: 'start',
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%',
                  margin: 'auto',
                }}
              >
                <div
                  className={'ct-value'}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <>
                    <strong
                      style={{
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      }}
                    >
                      <FormattedRelativeTimeConvenient
                        date={new Date(version.createdAt)}
                        tooltip={false}
                      />
                    </strong>
                    <span
                      style={{
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      }}
                    >
                      <FormattedDateTime date={new Date(version.createdAt)} />
                    </span>
                  </>
                </div>
              </div>
              <div
                className={'ct-col ct-col-flex-grow'}
                style={{
                  justifyContent: 'end',
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%',
                  margin: 'auto',
                }}
              >
                <ul className={'code-capsule-button-list'}>
                  <li className={'code-capsule-li'}>
                    <div
                      className={'code-capsule-run-now'}
                      onClick={() => {
                        setSelectedVersionInModal(version);
                        setRunCodeCapsuleModalOpen(true);
                      }}
                    >
                      <span className={'icon-play'} />
                      <span className={'button-text'}>
                        Run Code Capsule Now
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          ))}
        </div>
        {runCodeCapsuleModalOpen && (
          <RunCodeCapsuleModal
            close={() => {
              setRunCodeCapsuleModalOpen(false);
              setSelectedVersionInModal(undefined);
            }}
            onSubmit={(...args) => {
              // @ts-ignore this requires a tuple type which args is, but I'm too lazy to type that here because this should be refactored anyways
              dispatch(triggerRunCodeCapsuleJob(...args));
            }}
            versions={[selectedVersionInModal]}
            repoCode={codeCapsule.repoCode}
            codeCapsuleCode={codeCapsuleCode}
            habitatCode={habitatCode}
          />
        )}
      </div>
    );
  };

  const renderLoading = () => {
    return (
      <div className={'code-capsule-container versions-container'}>
        <Busy isBusy />
      </div>
    );
  };

  const renderError = () => {
    return (
      <div className={'code-capsule-container versions-container'}>
        <span className={'error'}>{error}</span>
      </div>
    );
  };

  if (isSuccess) return renderLoaded();
  else if (isError) return renderError();
  else if (isLoading) return renderLoading();
  else return <div />;
};

export default Versions;
