import React, { FC } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

import styles from './styles.module.scss';
import ThemedSwitch from '../../themed-switch/ThemedSwitch';

export type Props = {
  /** Is the field disabled? */
  disabled: boolean;
  /** ID of the input element */
  id?: string;
  /** Name of the input field */
  name?: string;
  /** Optional Label of the toggle */
  label?: MessageDescriptor;
  /** Description of the toggle (left of the toggle) */
  description?: MessageDescriptor;
  /** Is the toggle checked? */
  checked: boolean;
  /** onChange callback */
  onChange: (e: boolean) => void;
};

const ToggleInput: FC<Props> = ({
  label,
  description,
  checked,
  onChange,
  disabled,
}) => {
  function renderLabel() {
    if (!label) return null;
    else if (!label.id) {
      return <span className={styles.label}>{label.defaultMessage}</span>;
    } else {
      return (
        <FormattedMessage id={label.id} defaultMessage={label.defaultMessage}>
          {(text) => <span className={styles.label}>{text}</span>}
        </FormattedMessage>
      );
    }
  }

  function renderDescription() {
    if (!description) return null;
    else if (!description.id) {
      return (
        <span className={styles.description}>{description.defaultMessage}</span>
      );
    } else {
      return (
        <FormattedMessage
          id={description.id}
          defaultMessage={description.defaultMessage}
        >
          {(text) => <span className={styles.description}>{text}</span>}
        </FormattedMessage>
      );
    }
  }

  return (
    <div>
      <div className={styles.header}>{label && renderLabel()}</div>

      <div className={styles.body}>
        {description && renderDescription()}

        <ThemedSwitch
          onChange={onChange}
          checked={checked}
          disabled={disabled}
          height={20}
          width={40}
          uncheckedIcon={false}
          checkedIcon={false}
          boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
          activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
          themeColor={'primary'}
        />
      </div>
    </div>
  );
};

/** @deprecated use react hook form variant instead*/
export default ToggleInput;
