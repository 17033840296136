import classNames from 'classnames';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './styles.module.scss';
import Busy from '../../atoms/busy/Busy';

type DropZoneProps = {
  /** Is the image being fetched? */
  loading: boolean;
  /** Fetched image */
  data?: {
    imageType: string;
    imageData: string;
  };
  /** Error while fetching the image? */
  error?: string;
  /** Delete the users avatar */
  deleteAvatar: () => void;

  /** Is the dropped file too large? */
  isFileTooLarge?: boolean;

  /** Is the image being uploaded? */
  uploading: boolean;
  /** Upload progress */
  progress?: number;
};

const DropZoneBody: FC<DropZoneProps> = ({
  loading,
  data,
  deleteAvatar,
  isFileTooLarge,
  uploading,
  progress,
}) => {
  if (uploading) {
    return (
      <div>
        <span>Uploading {progress}%</span>
      </div>
    );
  }

  if (loading) {
    return <Busy isBusy />;
  }

  if (isFileTooLarge) {
    return (
      <div>
        <span style={{ color: 'red' }}>
          File too large. Max allowed size: 1MB.
        </span>
      </div>
    );
  }

  if (data) {
    const avatarSrc = `data:${data.imageType};base64, ${data.imageData}`;

    return (
      <div className={styles.dropzoneBody}>
        <img src={avatarSrc} alt='avatar' />
        <span
          className={classNames(styles.fa, styles.faTimes)}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            deleteAvatar();
          }}
        />
        <span className={classNames(styles.fa, styles.faPencil)} />
      </div>
    );
  }

  return (
    <div>
      <span className='icon-plus' />
      <div>
        <FormattedMessage
          id='account.info.add_account_photo'
          defaultMessage='Add Account Photo'
        />
      </div>
    </div>
  );
};

export default DropZoneBody;
