import augurMsgs from 'common/dist/messages/augurs';
import { FEATURES_TO_SHOW_COUNT } from 'common/src/constants/constants';
import React, { FC, useMemo, useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import {
  RejectedFeaturesS3AugurSettings,
  RejectedFeaturesS3Config,
} from './type';
import { useS3TableSamplesForHabitat } from '../../../../../../core/api/data';
import TextInputSearch from '../../../../../atoms/input-elements/text-input-search/TextInputSearch';
import { HabitatRouteParams } from '../../../../../index/routes';
import Header from '../../rejected-features-cassandra/v1/Header';
import InfluencerBar from '../../rejected-features-cassandra/v1/InfluencerBar';
import {
  OrderByType,
  SortByType,
  sortFeatures,
} from '../../rejected-features-cassandra/v1/sorting';
import styles from '../../rejected-features-cassandra/v1/styles.module.scss';
import { AugurSettingsProps } from '../../types/meta';

export type Props = AugurSettingsProps<
  RejectedFeaturesS3AugurSettings,
  RejectedFeaturesS3Config
>;

const RejectedFeaturesS3: FC<Props> = (props: Props) => {
  const {
    config,
    value = { rejected: [], enforced: [] },
    onChange,
    readOnly,
  } = props;
  const { s3Bucket, s3File } = config;
  const { habitatCode } = useParams<HabitatRouteParams>();

  const [sortBy, setSortBy] = useState<SortByType>('feature');
  const [orderBy, setOrderBy] = useState<OrderByType>('descending');
  const [numFeaturesToShow, setNumFeaturesToShow] = useState<number>(
    FEATURES_TO_SHOW_COUNT
  );
  const [searchQuery, setSearchQuery] = useState<string>('');

  const tableSamples = useS3TableSamplesForHabitat(
    s3Bucket?.dataSourceCode,
    s3Bucket?.bucketName,
    s3File,
    habitatCode
  );
  const colSpecs = tableSamples.data?.colSpecs || [];
  const columns = colSpecs.map((colSpec) => colSpec.colName);

  const filteredColumns = useMemo(() => {
    return columns.filter((column) =>
      column.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [columns, searchQuery]);

  const processedColumns = filteredColumns.sort((c1, c2) =>
    sortFeatures(c1, c2, value, sortBy, orderBy)
  );

  const columnsToShow = processedColumns.slice(0, numFeaturesToShow);

  const handleSort = (newSortBy: SortByType, newOrderBy: OrderByType) => {
    setSortBy(newSortBy);
    setOrderBy(newOrderBy);
  };

  return (
    <div className={styles.container}>
      <div className={styles.searchBar}>
        <TextInputSearch
          submitSearchQuery={(search) => {
            setSearchQuery(search || '');
          }}
        />
      </div>
      <div className={styles.featureCountInfo}>
        Showing {columnsToShow.length} out of {columns.length} features
      </div>

      <div className={styles.gridContainer}>
        <div className={styles.grid}>
          <Header sort={handleSort} sortBy={sortBy} orderBy={orderBy} />

          {columnsToShow.length > 0 ? (
            columnsToShow.map((column: string) => (
              <InfluencerBar
                key={column}
                colName={column}
                value={value}
                onChange={onChange}
                readOnly={readOnly}
              />
            ))
          ) : (
            <div className={styles.noFeaturesAvailable}>
              <FormattedMessage {...augurMsgs.noFeaturesAvailable} />
            </div>
          )}

          {numFeaturesToShow < processedColumns.length && (
            <button
              type={'button'}
              className={styles.showMoreButton}
              id={'collapsableWizardButton'}
              onClick={() => {
                setNumFeaturesToShow(
                  numFeaturesToShow + FEATURES_TO_SHOW_COUNT
                );
              }}
            >
              <FormattedMessage
                id='dashboard.show_more'
                defaultMessage='Show More'
              />
              <FiChevronDown className={styles.buttonIcon} />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default RejectedFeaturesS3;
