import commonMessages from 'common/dist/messages/common';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { WrappedFieldProps } from 'redux-form';

import styles from './styles.module.scss';
import { isAdmin as isAdminSelector } from '../../../../../redux/selectors/user.selector';
import { RootState } from '../../../../../store/store';
import Button from '../../../../atoms/button/Button';
import { JobGroupEditor } from '../../../../molecules/job-groups/job-group-editor/JobGroupEditor';

export const JobGroupEditorField: FC<
  {
    onClickSave: () => void;
  } & WrappedFieldProps
> = (props) => {
  const { onClickSave } = props;

  const isAdmin = useSelector<RootState, boolean>(isAdminSelector);

  return (
    <div className={styles.container}>
      <JobGroupEditor input={props.input} meta={props.meta} isAdmin={isAdmin} />
      <div className={styles.buttonContainer}>
        <Button
          label={{
            id: 'todo',
            defaultMessage: 'Close Editor',
          }}
          color={'primary'}
          onClick={onClickSave}
        />
      </div>
    </div>
  );
};

export default JobGroupEditorField;
