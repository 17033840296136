import React, { FC, PropsWithChildren } from 'react';

export type Action = 'copy' | 'cut';
type PropsWithSelector = {
  /**
   * CSS-Selector for element whose content should be copied.
   */
  dataSelector: string;
  /**
   * "cut" or "copy"
   */
  action?: Action;
};
type PropsWithoutSelector = {
  /**
   * String to be copied
   */
  data: string;
};
export type Props = PropsWithChildren<PropsWithSelector | PropsWithoutSelector>;

function hasSelector(props: Props): props is PropsWithSelector {
  return (props as PropsWithSelector).dataSelector !== undefined;
}

/**
 * Clicks on wrapped child components will copy the specified text to the clipboard.
 * @param props
 * @constructor
 */
const ClipboardWrapper: FC<Props> = (props) => {
  if (hasSelector(props)) {
    return (
      <div
        className='clipboard'
        data-clipboard-target={props.dataSelector}
        data-clipboard-action={props.action || 'copy'}
      >
        {props.children}
      </div>
    );
  } else {
    return (
      <div className='clipboard' data-clipboard-text={props.data}>
        {props.children}
      </div>
    );
  }
};

export default ClipboardWrapper;
