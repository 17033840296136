import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { TabContent } from 'react-tabs-redux';

import Orchestration from '../../orchestration/Orchestration';
import ErrorBoundary from '../../pages/error-boundary/ErrorBoundary';

export default class TabContentOrchestration extends Component {
  render() {
    const { match } = this.props;
    const isVisible = 'orchestration' === match.params.mainTab;

    return (
      <TabContent
        className={'orchestration-tab-content'}
        for={'orchestration'}
        isVisible={isVisible}
        renderActiveTabContentOnly={true}
      >
        <ErrorBoundary
          errorDescription={{
            id: 'error.tab.description',
          }}
          fullScreenHeight={true}
        >
          <Orchestration match={match} />
        </ErrorBoundary>
      </TabContent>
    );
  }
}
TabContentOrchestration.propTypes = {
  match: PropTypes.object,
};
