import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import Habitats from '../../dashboard/habitats/Habitats';
import * as routes from '../../index/routes';
import NewAugurWizard from '../augur/NewAugurWizard';
import {
  AddHabitatWizard,
  UpdateHabitatWizard,
} from '../habitat-wizard/HabitatWizard';
import NoPermissions from '../no-permissions/NoPermissions';

interface ModelManagementProps {
  missingPermissionsError?: boolean;
}

const ModelManagement: FC<ModelManagementProps> = ({
  missingPermissionsError,
}) => {
  if (missingPermissionsError) {
    return <NoPermissions fullViewHeight />;
  }
  return (
    <Switch>
      {/* New Augur */}
      <Route
        path={`${routes.app.prefix}${routes.app.models}/${routes.app.newAugur}`}
        component={NewAugurWizard}
      />

      {/* Update Habitat */}
      <Route
        path={`${routes.app.prefix}${routes.app.models}/${routes.app.newHabitat}/:habitatCode`}
        component={UpdateHabitatWizard}
      />

      {/* New Habitat */}
      <Route
        path={
          routes.app.prefix + routes.app.models + '/' + routes.app.newHabitat
        }
        component={AddHabitatWizard}
      />

      <Route component={Habitats} />
    </Switch>
  );
};

export default ModelManagement;
