import classNames from 'classnames';
import React, { FC, useEffect, useState } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { FiMaximize, FiMinimize, FiPower } from 'react-icons/fi';
import PanelGroup from 'react-panelgroup';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import Notifications from './Notifications.container';
import styles from './styles.module.scss';
import Swagger from './Swagger';
import Terminal from './Terminal';
import VsCode from './VsCode';
import { defaultSidebarSize } from '../../../../core/constants/layout';
import { showShutdownWorkbenchModal } from '../../../../redux/workbench/modules/connection.module';
import { DeprecatedRootState } from '../../../../store/state.type';
import { getActiveProjectPath } from '../../../../store/workbench/activeProject.slice';
import { workbenchRoutes } from '../../../../workbench/common/workbenchRoutes';
import Button from '../../../atoms/button/Button';
import ShutdownWorkbenchModal from '../../../organisms/workbench/shutdown-workbench-modal/ShutdownWorkbenchModal.container';
import DevAugur from '../../../pages/augur/DevAugur';
import GeneralConfigurationPage from '../../../pages/general-configuration/GeneralConfigurationPage';
import MainContainer from '../../../pages/main-container/MainContainer';
import ProjectsOverview from '../../../pages/project-overview/ProjectsOverview';
import fullscreenStyles from '../../fullscreenStyles.module.scss';
import Browser from '../../part-left/Browser';
import ConfigureCodeCapsuleModal from '../../part-right/code-capsule/ConfigureCodeCapsuleModal.container';
import RepositoryInfo from '../../part-right/repository/info/RepositoryInfo.container';
import RightPart from '../../part-right/RightPart';
import ResourceManagement from '../resource-management/ResourceManagement';

type Props = {
  fetchSessions: () => void;
  fetchKernelspecs: () => void;
  mainBrowserResize: (size: number) => void;
  notebookUser: string;
  resetEventSource: () => void;
  checkWhetherNotebookIsRunning: (notebookUser: string, retry: boolean) => void;
};

const WorkbenchMainContainer: FC<Props> = ({
  mainBrowserResize,
  checkWhetherNotebookIsRunning,
  fetchSessions,
  fetchKernelspecs,
  notebookUser,
  resetEventSource,
}: Props) => {
  useEffect(() => {
    checkWhetherNotebookIsRunning(notebookUser, false);
    fetchSessions();
    fetchKernelspecs();
    resetEventSource();
  }, [
    checkWhetherNotebookIsRunning,
    fetchSessions,
    fetchKernelspecs,
    resetEventSource,
    notebookUser,
  ]);

  const [sidebarVisible, setSidebarVisible] = useState(true);

  const dispatch = useDispatch();

  const width =
    useSelector<DeprecatedRootState, number>(
      (state) => state.workbench.browserWidth
    ) || defaultSidebarSize;

  const fullscreenHandle = useFullScreenHandle();

  function renderEditor() {
    // For !sidebarVisible add an empty div with size 0, so that the RightPart is not remounted when switching
    return (
      <>
        <PanelGroup
          panelWidths={
            sidebarVisible
              ? [
                  {
                    minSize: 240,
                    size: width || defaultSidebarSize,
                    resize: 'dynamic',
                  },
                  { minSize: 300, resize: 'stretch' },
                ]
              : [
                  { minSize: 0, size: 0, resize: 'fixed' },
                  { minSize: 300, resize: 'stretch' },
                ]
          }
          onResizeEnd={(panels) => mainBrowserResize(panels[0].size)}
          spacing={0}
        >
          {sidebarVisible && <Browser setSidebarVisible={setSidebarVisible} />}
          <RightPart
            sidebarVisible={sidebarVisible}
            setSidebarVisible={setSidebarVisible}
          />
        </PanelGroup>
        {renderFullscreenButton('notebook')}
      </>
    );
  }

  function renderFullscreenButton(position: 'vscode' | 'notebook') {
    return (
      <div
        className={classNames(styles.buttonsRight, {
          [styles.vscode]: position === 'vscode',
          [styles.notebook]: position === 'notebook',
        })}
      >
        <Button
          Icon={() => <FiPower className={'fullscreen-icon'} size={'16px'} />}
          shape={'squared'}
          onClick={() => dispatch(showShutdownWorkbenchModal())}
          title={'Shutdown Workbench'}
        />
        {fullscreenHandle.active ? (
          <Button
            Icon={() => (
              <FiMinimize className={'fullscreen-icon'} size={'16px'} />
            )}
            shape={'squared'}
            onClick={fullscreenHandle.exit}
            title={'Exit Fullscreen'}
          />
        ) : (
          <Button
            Icon={() => (
              <FiMaximize className={'fullscreen-icon'} size={'16px'} />
            )}
            shape={'squared'}
            onClick={fullscreenHandle.enter}
            title={'Enter Fullscreen'}
          />
        )}
      </div>
    );
  }

  function workbenchRouter() {
    const activeProjectPath: string = useSelector(getActiveProjectPath);
    const activeProjectExists = activeProjectPath && activeProjectPath !== '';
    const projectsOverviewPath = `${workbenchRoutes.basePath}${workbenchRoutes.projectsOverview}`;

    return (
      <>
        <Switch>
          {/* Overview Workbench Projects */}
          <Route path={projectsOverviewPath}>
            <ProjectsOverview />
          </Route>
          {/* VS Code */}
          <Route path={`${workbenchRoutes.basePath}${workbenchRoutes.vsCode}`}>
            <>
              <VsCode
                notebookUser={notebookUser}
                activeProjectPath={activeProjectPath}
              />
              {renderFullscreenButton('vscode')}
            </>
          </Route>
          {/* Resource Management */}
          <Route
            path={`${workbenchRoutes.basePath}${workbenchRoutes.resourceManagement}`}
            component={ResourceManagement}
          />
          {/* Repository Info */}
          <Route
            path={`${workbenchRoutes.basePath}${workbenchRoutes.versionControl}`}
            render={() =>
              activeProjectExists ? (
                <RepositoryInfo />
              ) : (
                <Redirect to={projectsOverviewPath} />
              )
            }
          />
          {/* General Configuration */}
          <Route
            path={`${workbenchRoutes.basePath}${workbenchRoutes.generalConfig}`}
            render={() =>
              activeProjectExists ? (
                <GeneralConfigurationPage
                  notebookUser={notebookUser}
                  activeProjectPath={activeProjectPath}
                />
              ) : (
                <Redirect to={projectsOverviewPath} />
              )
            }
          />
          {/* Dev Augur */}
          <Route
            path={`${workbenchRoutes.basePath}${workbenchRoutes.devAugur}`}
            render={() =>
              activeProjectExists ? (
                <DevAugur
                  notebookUser={notebookUser}
                  activeProjectPath={activeProjectPath}
                />
              ) : (
                <Redirect to={projectsOverviewPath} />
              )
            }
          />
          {/* Terminal */}
          <Route
            path={`${workbenchRoutes.basePath}${workbenchRoutes.terminal}`}
            render={() =>
              activeProjectExists ? (
                <Terminal notebookUser={notebookUser} />
              ) : (
                <Redirect to={projectsOverviewPath} />
              )
            }
          />
          {/* Swagger */}
          <Route
            path={`${workbenchRoutes.basePath}${workbenchRoutes.swagger}`}
            render={() =>
              activeProjectExists ? (
                <Swagger
                  notebookUser={notebookUser}
                  activeProjectPath={activeProjectPath}
                />
              ) : (
                <Redirect to={projectsOverviewPath} />
              )
            }
          />
          {/* Notebook environment */}
          <Route path={`${workbenchRoutes.basePath}`}>{renderEditor()}</Route>
        </Switch>
        <ConfigureCodeCapsuleModal />
      </>
    );
  }

  return (
    <FullScreen handle={fullscreenHandle}>
      <MainContainer
        additionalClassname={classNames(
          'Workbench',
          { [fullscreenStyles.fullscreenContainer]: fullscreenHandle.active },
          { 'browser-collapsed': !sidebarVisible }
        )}
        transparent
        fullWidth
        additionalInnerClassname={styles.mainContainerInner}
        data-testid={'workbench-running'}
      >
        {workbenchRouter()}
        <Notifications />
        <ShutdownWorkbenchModal />
      </MainContainer>
    </FullScreen>
  );
};

export default WorkbenchMainContainer;
