import React, { FC } from 'react';

import {
  useDimensions,
  usePrimaryColorScale,
  useThemeColor,
} from '../../../../../../../utils';
import EChartWrapper, {
  ReactEChartsProps,
} from '../../../../../e-chart-wrapper/EChartWrapper';
import { SharedInputProps } from '../../../../common/utils';
import styles from '../../../styles.module.scss';
import { ReportElementProps } from '../../../types/meta';
import { PieChartConfig, PieChartData, PieChartReportData } from '../type';

export type Props = PieChartData & PieChartConfig & SharedInputProps;

export const PieChart: FC<Props> = ({ data, legend }) => {
  const [ref, { width, height }] = useDimensions<HTMLDivElement>(1);

  const keys = Object.keys(data);

  const primary = useThemeColor('primary');
  const primaryHighlight = useThemeColor('primary-highlight');
  const secondary = useThemeColor('secondary');

  const colorScale = usePrimaryColorScale(keys.length - 1);

  const legendColors: { [label: string]: string } = {};
  if (legend) {
    legend.forEach((legendEntry) => {
      legendColors[legendEntry.label] = legendEntry.color;
    });
  }

  const smallSize = 280;
  const isSmall = (height < smallSize || width < smallSize) && height < 450;

  const option: ReactEChartsProps['option'] = {
    animation: true,

    tooltip: {
      trigger: 'item',
      position: function (point, params, dom, rect, size) {
        return [width / 2 - size.contentSize[0] / 2, 30]; // [x, y]
      },
      displayMode: 'single',
      verticalAlign: 'middle',
    },
    legend: {
      width: width,
      top: '0px',
      left: 'center',
      show: !isSmall,
    },

    series: [
      {
        cursor: 'default',
        animation: true,
        animationEasing: 'linear',
        animationDuration: 0,
        top: isSmall ? 0 : 37,
        height: isSmall ? height : height - 20,

        name: '',
        type: 'pie',
        radius: isSmall ? ['45%', '95%'] : ['38%', '75%'],
        avoidLabelOverlap: false,

        itemStyle: {
          borderRadius: 0,
          borderColor: '#fff',
          borderWidth: 0,

          color: (val) => {
            // @ts-ignorename name prop is not recognized by echart
            const label = val?.data?.name;
            // Use legendColor if defined
            const legendColor = legendColors[label];
            if (legendColor === 'primary') return primary;
            if (legendColor === 'primaryHighlight') return primaryHighlight;
            if (legendColor === 'secondary') return secondary;
            if (legendColor) return legendColor;

            return colorScale(val.dataIndex);
          },
        },
        label: {
          show: false,
        },

        data: keys.map((key) => {
          return { value: data[key], name: key };
        }),
      },
    ],
  };

  return (
    <div ref={ref} className={styles.container}>
      <EChartWrapper option={option} style={{ width: width, height: height }} />
    </div>
  );
};

export type SingleProps = ReportElementProps<
  PieChartReportData,
  PieChartConfig
>;

export const PieChartSingle: FC<SingleProps> = ({ input, config }) => {
  return <PieChart {...input.reportValue} {...config} />;
};
