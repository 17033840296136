import { TriggerType } from '../types/job';

export type SelectedSchedule = {
  trigger: TriggerType;
  trigDelayed?: string;
  trigCron?: string;
};

// CronScheduleSelect component creates cron strings with 5 elements
// JobOrchestration works with quartz cron strings with 7 elements
export const cronToQuartzCron = (cronString: string): string => {
  const split = cronString.split(' ');
  const dayOfTheMonth = split[2];
  const dayOfTheWeek = split[4];

  // Handle day of month and day of week logic
  if (dayOfTheMonth !== '*') {
    split[4] = '?';
  } else if (dayOfTheWeek !== '*') {
    split[2] = '?';
  } else if (dayOfTheMonth === '*' && dayOfTheWeek === '*') {
    split[2] = '?';
  }

  // Convert day of week to Quartz format (1-7 range)
  if (split[4] !== '*' && split[4] !== '?') {
    split[4] = split[4]
      .split(',')
      .map((item) => {
        if (item.includes('/')) {
          // Handle steps
          const [range, step] = item.split('/');
          if (range === '*') {
            return item; // Keep */n as is
          } else {
            if (range.includes('-')) {
              // Handle ranges
              const [start, end] = range.split('-');
              return `${parseInt(start) + 1}-${parseInt(end) + 1}/${step}`;
            } else if (range.includes(',')) {
              // Handle ranges
              const [start, end] = range.split(',');
              return `${parseInt(start) + 1},${parseInt(end) + 1}/${step}`;
            } else {
              const start = parseInt(range) + 1;
              return `${start}/${step}`;
            }
          }
        } else if (item.includes('-')) {
          // Handle ranges
          const [start, end] = item.split('-');
          return `${parseInt(start) + 1}-${parseInt(end) + 1}`;
        } else {
          // Handle single numbers
          return String(parseInt(item) + 1);
        }
      })
      .join(',');
  }

  // Add seconds and year fields for Quartz format
  split.splice(0, 0, '0');
  split.splice(6, 0, '*');

  return split.join(' ');
};
export const quartzCronToCron = (cronString: string): string | undefined => {
  if (!cronString) return undefined;
  const split = cronString.replace('?', '*').split(' ');

  // Handle day of week conversion
  if (split[5] !== '*') {
    split[5] = split[5]
      .split(',')
      .map((item) => {
        if (item.includes('/')) {
          // Handle steps
          const [range, step] = item.split('/');
          if (range === '*') {
            return item; // Keep */n as is
          } else {
            if (range.includes('-')) {
              // Handle ranges
              const [start, end] = range.split('-');
              return `${(parseInt(start) + 6) % 7}-${
                (parseInt(end) + 6) % 7
              }/${step}`;
            } else if (range.includes(',')) {
              // Handle ranges
              const [start, end] = range.split(',');
              return `${(parseInt(start) + 6) % 7},${
                (parseInt(end) + 6) % 7
              }/${step}`;
            } else {
              const start = (parseInt(range) + 6) % 7;
              return `${start}/${step}`;
            }
          }
        } else if (item.includes('-')) {
          // Handle ranges
          const [start, end] = item.split('-');
          return `${(parseInt(start) + 6) % 7}-${(parseInt(end) + 6) % 7}`;
        } else {
          // Handle single numbers
          return String((parseInt(item) + 6) % 7);
        }
      })
      .join(',');
  }

  const fiveElementCron = split.slice(1, 6).join(' ');
  return fiveElementCron;
};
