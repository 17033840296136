import messages from 'common/dist/messages/orchestration';
import { InputSchedule, JobGroupInputType } from 'common/dist/types/job';
import {
  cronToQuartzCron,
  SelectedSchedule,
} from 'common/dist/utils/schedules';
import React, { ComponentProps, FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Field, InjectedFormProps } from 'redux-form';

import {
  Data as FormData,
  fieldDescription,
  fieldName,
  JobGroup,
  jobGroupField,
  newScheduleFormName,
  selectedScheduleField,
} from './newSchedule.form';
import { ConnectedProps } from './NewScheduleWizard.container';
import JobGroupStep from './steps/job-group/JobGroupStep';
import JobGroupEditorField from './steps/JobGroupEditorField';
import styles from './styles.module.scss';
import PeriodStep from '../../../../components/orchestration/job-schedules/add-schedule/steps/period/PeriodStep';
import { ButtonProps } from '../../../atoms/button/Button';
import { WrappedTextInputArea } from '../../../atoms/input-elements/text-input-area/TextInputArea';
import { WrappedTextInputLine } from '../../../atoms/input-elements/text-input-line/TextInputLine';
import GenericFormStep from '../../../molecules/generic-form-step/GenericFormStep';
import Wizard from '../../../pages/wizard/Wizard';
import { orchestrationRoutes } from '../../routes';
// --- Config
const cancelLink = '/app/orchestration/schedulings';
// ---

const formatDateString = (inputDateString: string) => {
  const dateObject = new Date(inputDateString);
  const formattedDateString: string = dateObject.toISOString();
  return formattedDateString;
};
/**
 * Takes the values selected in the form and creates the InputSchedule object (that will be passed to the backend) from
 * them.
 * @param selectedSchedule
 * @param jobGroup
 */
function createInputSchedule(
  selectedSchedule: SelectedSchedule,
  jobGroup: JobGroup
) {
  const inputSchedule: InputSchedule = {
    trigger: selectedSchedule.trigger,
    jobGroupInput: {
      trigger: selectedSchedule.trigger,
      priority: 'medium', // Currently not treated by the Queue anyway
      jobs: jobGroup.jobs,
      jobGroupTopology: jobGroup.jobGroupTopology,
      name: jobGroup.name,
      description: jobGroup.description,
    },
    trigDelayed: selectedSchedule.trigDelayed
      ? formatDateString(selectedSchedule.trigDelayed)
      : undefined,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    ...(selectedSchedule.trigger === 'cron' && {
      trigCron: cronToQuartzCron(selectedSchedule.trigCron),
    }),
  };
  return inputSchedule;
}

/**
 * Creates the InputSchedule object, but adds the scheduleCode as it is expected for updates to schedules
 * @param selectedSchedule
 * @param jobGroup
 * @param scheduleCode
 */
function createUpdateSchedule(
  selectedSchedule: SelectedSchedule,
  jobGroup: JobGroup,
  scheduleCode
) {
  return {
    ...createInputSchedule(selectedSchedule, jobGroup),
    scheduleCode,
  };
}

export interface Props {
  edit?: boolean;
  /** The scheduleCode from the URL if edit = true */
  scheduleCode?: string;
  /** A custom function that resets the forms data in the store */
  reset: (formName: string) => void;
  /** Is the Job Picker shown? */
  jobPickerIsActive?: boolean;
  submitting?: boolean;
  submitted?: boolean;
  selectedSchedule?: SelectedSchedule;
  jobGroup: JobGroupInputType;
  name: string;
  description: string;
  /** Optionally passed as a query parameter, currently only used for the Add Schedule Wizard */
  initialJobGroupCode?: string;
  /** Fetch a single Job Group by its code */
  fetchJobGroup: ({ jobGroupCode }: { jobGroupCode: string }) => void;
  /** Are the job groups (or one of the job groups) currently loading? */
  jobGroupsLoading?: boolean;
}

export const NewScheduleWizard: FC<
  Props & ConnectedProps & InjectedFormProps
> = ({
  handleSubmit,
  edit,
  scheduleCode,
  addSchedule,
  jobPickerIsActive,
  selectedSchedule,
  submitting,
  jobGroup,
  valid,
  anyTouched,
  updateSchedule,
  hideJobPicker,
  jobGroupsLoading,
  destroyScheduleForm,
  touch,
  fetchSchedule,
  fetchJobGroup,
  initialJobGroupCode,
}) => {
  useEffect(() => {
    if (edit) {
      touch(selectedScheduleField, jobGroupField, fieldDescription, fieldName);
      fetchSchedule(scheduleCode);
    }

    // If an initial job group code is give, fetch the Job Group (this is the case if a schedule is created based on an existing Job Group)
    if (initialJobGroupCode) {
      fetchJobGroup({ jobGroupCode: initialJobGroupCode });
    }

    return () => {
      destroyScheduleForm();
    };
  }, [
    destroyScheduleForm,
    fetchSchedule,
    initialJobGroupCode,
    fetchJobGroup,
    scheduleCode,
    // don't add touch or edit to the dependency array, this will break the form, instead just refactor the whole thing and remove Redux form
  ]);

  const history = useHistory();
  const buttons: ButtonProps[] = [
    {
      linkTo: cancelLink,
      color: 'white',
      label: 'Cancel',
    },
    {
      type: 'submit',
      color: 'secondary',
      label: 'Finish',
      disabled: !(anyTouched && valid),
      isBusy: submitting,
      onClick: () => {},
    },
  ];
  const headline = edit ? `Edit Schedule` : 'Add Schedule';

  if (jobPickerIsActive) {
    return (
      <Field
        name={jobGroupField}
        component={JobGroupEditorField}
        onClickSave={() => hideJobPicker()}
      />
    );
  }

  return (
    <form
      onSubmit={handleSubmit((values: FormData) => {
        const submitJobGroup = {
          ...jobGroup,
          name: values[fieldName],
          description: values[fieldDescription],
        };

        if (anyTouched && valid) {
          if (edit) {
            const inputSchedule = createUpdateSchedule(
              selectedSchedule,
              submitJobGroup,
              scheduleCode
            );
            // @ts-ignore: incorrect typing for Redux saga
            updateSchedule(inputSchedule, () =>
              history.push(
                `${orchestrationRoutes.basePath}/${orchestrationRoutes.schedulings.path}`
              )
            );
          } else {
            const inputSchedule = createInputSchedule(
              selectedSchedule,
              submitJobGroup
            );
            // @ts-ignore: incorrect typing for Redux saga
            addSchedule(inputSchedule, () =>
              history.push(
                `${orchestrationRoutes.basePath}/${orchestrationRoutes.schedulings.path}`
              )
            );
          }
        }
      })}
      className={styles.form}
    >
      <Wizard
        additionalClassname={'AddSchedule'}
        headline={headline}
        buttons={buttons}
      >
        <>
          <GenericFormStep
            fieldName={fieldName}
            formName={newScheduleFormName}
            component={WrappedTextInputLine}
            renderError={false}
            title={{
              id: messages.msgFieldNameName.id,
              defaultMessage: messages.msgFieldNameName.defaultMessage,
            }}
            description={{
              id: messages.msgFieldNameDescription.id,
              defaultMessage: messages.msgFieldNameDescription.defaultMessage,
            }}
            num={1}
            fieldProps={
              {
                placeholderId: messages.msgFieldNameName.id,
                placeholderDefault: messages.msgFieldNameName.defaultMessage,
                labelId: messages.msgFieldNameDescription.id,
                labelDefault: messages.msgFieldNameDescription.defaultMessage,
                hasLabel: true,
              } as ComponentProps<typeof WrappedTextInputLine>
            }
          />
          <GenericFormStep
            fieldName={fieldDescription}
            formName={newScheduleFormName}
            component={WrappedTextInputArea}
            renderError={false}
            title={{
              id: messages.msgFieldDescriptionName.id,
              defaultMessage: messages.msgFieldDescriptionName.defaultMessage,
            }}
            description={{
              id: messages.msgFieldDescriptionDescription.id,
              defaultMessage: messages.msgFieldDescriptionDescription.id,
            }}
            num={2}
            fieldProps={
              {
                hasLabel: true,
                label: {
                  id: messages.msgFieldDescriptionName.id,
                  defaultMessage:
                    messages.msgFieldDescriptionName.defaultMessage,
                },
                placeholder: {
                  id: messages.msgFieldDescriptionDescription.id,
                  defaultMessage:
                    messages.msgFieldDescriptionDescription.defaultMessage,
                },
              } as ComponentProps<typeof WrappedTextInputArea>
            }
          />
          <PeriodStep step={3} />
          <JobGroupStep step={4} jobGroupsLoading={jobGroupsLoading} />
        </>
      </Wizard>
    </form>
  );
};

export default NewScheduleWizard;
