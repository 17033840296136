import classNames from 'classnames';
import augurMsgs from 'common/dist/messages/augurs';
import { FEATURES_TO_SHOW_COUNT } from 'common/src/constants/constants';
import _ from 'lodash';
import React, { FC, useMemo, useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { FormattedMessage } from 'react-intl';

import Header from './Header';
import InfluencerBar from './InfluencerBar';
import { OrderByType, SortByType, sortFeatures } from './sorting';
import styles from './styles.module.scss';
import GraphWait from '../../../../../../../components/details/GraphWait';
import TextInputSearch from '../../../../../../atoms/input-elements/text-input-search/TextInputSearch';
import { buildSingleReportWrapper } from '../../../common/SingleReportWrapper';
import { ReportElementProps } from '../../../types/meta';
import { FeatureImportanceConfig, FeatureImportanceReportData } from '../type';

export type Props = FeatureImportanceReportData & FeatureImportanceConfig;

const FeatureImportance: FC<Props> = (props: Props) => {
  const { featureImportanceCalculatedWith, features } = props;

  const [sortBy, setSortBy] = useState<SortByType>('importance');
  const [orderBy, setOrderBy] = useState<OrderByType>('descending');
  const [numFeaturesToShow, setNumFeaturesToShow] = useState<number>(
    FEATURES_TO_SHOW_COUNT
  );
  const [searchQuery, setSearchQuery] = useState<string>('');

  if (_.isEmpty(features)) {
    return (
      <GraphWait jobType={'learning'}>
        <FormattedMessage
          id='augur.influencers.not_available'
          defaultMessage='Influencers are not available'
        />
      </GraphWait>
    );
  }

  const filteredFeatures = useMemo(() => {
    return features.filter((feature) =>
      feature.feature.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [features, searchQuery]);

  const processedFeatures = filteredFeatures.sort((f1, f2) =>
    sortFeatures(f1, f2, sortBy, orderBy)
  );

  const featuresToShow = processedFeatures.slice(0, numFeaturesToShow);

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <div className={styles.infoText}>
          {'Feature importance was calculated with ' +
            featureImportanceCalculatedWith +
            '.'}
        </div>
        <div className={styles.searchBar}>
          <TextInputSearch
            submitSearchQuery={(search) => {
              setSearchQuery(search || '');
            }}
          />
        </div>
      </div>
      <div className={styles.featureCountInfo}>
        Showing {featuresToShow.length} out of {features.length} features
      </div>

      <div className={styles.gridContainer}>
        <div className={classNames(styles.grid, styles.featureImportance)}>
          <Header
            sort={(sortBy, orderBy) => {
              setSortBy(sortBy);
              setOrderBy(orderBy);
            }}
            sortBy={sortBy}
            orderBy={orderBy}
          />

          {featuresToShow.length > 0 ? (
            featuresToShow.map((f) => (
              <InfluencerBar key={`feature-${f.feature}`} feature={f} />
            ))
          ) : (
            <div className={styles.noFeaturesAvailable}>
              <FormattedMessage {...augurMsgs.noFeaturesAvailable} />
            </div>
          )}
        </div>

        {numFeaturesToShow < processedFeatures.length && (
          <button
            type={'button'}
            className={styles.showMoreButton}
            id={'collapsableWizardButton'}
            onClick={() => {
              setNumFeaturesToShow(numFeaturesToShow + FEATURES_TO_SHOW_COUNT);
            }}
          >
            <FormattedMessage
              id='dashboard.show_more'
              defaultMessage='Show More'
            />
            <FiChevronDown className={styles.buttonIcon} />
          </button>
        )}
      </div>
    </div>
  );
};

export const FeatureImportanceSingle: FC<
  ReportElementProps<FeatureImportanceReportData, FeatureImportanceConfig>
> = buildSingleReportWrapper<
  FeatureImportanceReportData,
  FeatureImportanceConfig
>(FeatureImportance);

export default FeatureImportance;
