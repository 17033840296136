import classNames from 'classnames';
import { ModuleAvatar } from 'common/dist/types/module';
import { repoTypeToSpeaking } from 'common/dist/types/repository';
import React, { FC } from 'react';
import Skeleton from 'react-loading-skeleton';

import styles from './styles.module.scss';
import RepositoryAvatar from '../../atoms/repository-avatar/RepositoryAvatar';

export type ProjectLinkProps = {
  name: string;
  repoType?: string;
  avatar: ModuleAvatar;
  isLoading?: boolean;
};

const ProjectLink: FC<ProjectLinkProps> = (props) => {
  const { name, repoType, avatar, isLoading = false } = props;

  return (
    <div className={styles.projectLink}>
      <div className={styles.iconContainer}>
        <RepositoryAvatar
          avatar={avatar}
          name={repoType ? name : undefined}
          isLoading={isLoading}
        />
      </div>
      <div
        className={classNames(styles.textContainer, {
          [styles.noActiveProject]: !repoType,
        })}
      >
        {isLoading ? (
          <Skeleton className={styles.title} />
        ) : (
          <span title={name} className={styles.title}>
            {name}
          </span>
        )}
        {isLoading ? (
          <Skeleton className={styles.description} />
        ) : (
          <span className={styles.description}>
            {repoType
              ? repoTypeToSpeaking[repoType]
              : 'Click to select project'}
          </span>
        )}
      </div>
    </div>
  );
};

export default ProjectLink;
