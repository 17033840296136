import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import Skeleton from 'react-loading-skeleton';

import { deriveElements } from './utils';
import {
  useAugurs,
  useUpdateAugurWithAugurCode,
} from '../../../../core/api/augurs';
import { useProgressSummary } from '../../../../core/api/orchestration';
import { comparators } from '../../../../core/dashboard';
import CollapsibleArea from '../../../molecules/collapsable-area/CollapsibleArea';
import elementCardStyles from '../../../molecules/element-card/styles.module.scss';
import ElementCardGrid from '../../../organisms/element-card-grid/ElementCardGrid';
import elementCardGridStyles from '../../../organisms/element-card-grid/styles.module.scss';
import ElementCardTable from '../../../organisms/element-card-table/ElementCardTable';
import { OrderType } from '../Habitat';
import NoElements from '../no-elements/NoElements';

type Props = {
  /** Habitat Code */
  habitatCode: string;
  isListView: boolean;
  order: OrderType;
};

enum TABS {
  ACTIVE,
  ARCHIVE,
}

export const HABITAT_GRID_MAX_HEIGHT = 300;

const Augurs: FC<Props> = ({ habitatCode, isListView, order }) => {
  const intl = useIntl();
  const [tab, setTab] = useState<TABS>(TABS.ACTIVE);

  const progressQuery = useProgressSummary(true);
  const jobProgressSummary = progressQuery.isSuccess ? progressQuery.data : {};

  const { data: augursData, isLoading } = useAugurs(habitatCode, false);
  const augurs = augursData ?? [];
  const { data: archivedAugursData, isLoading: isLoadingArchived } = useAugurs(
    habitatCode,
    true
  );
  const archivedAugurs = archivedAugursData ?? [];

  // Fix the habitatCode and payload here, so that we get a function we can use where we know the augurCode but can't use hooks
  const { mutate: updateAugur } = useUpdateAugurWithAugurCode();
  const restoreAugur = (augurCode: string) =>
    updateAugur({ augurCode, payload: { archived: false } });

  const comparator = comparators[order.value];
  const sortedAugurs = augurs.sort(comparator);
  const sortedArchivedAugurs = archivedAugurs.sort(comparator);

  const getElements = () => {
    if (tab === TABS.ARCHIVE) {
      // --- Archive tab is opened
      return deriveElements(
        sortedArchivedAugurs,
        habitatCode,
        intl,
        true,
        restoreAugur,
        archivedAugurs.length,
        () => setTab(TABS.ARCHIVE),
        () => setTab(TABS.ACTIVE)
      );
    } else {
      // --- Active augurs tab is opened
      return deriveElements(
        sortedAugurs,
        habitatCode,
        intl,
        false,
        restoreAugur,
        archivedAugurs.length,
        () => setTab(TABS.ARCHIVE),
        () => setTab(TABS.ACTIVE),
        jobProgressSummary
      );
    }
  };
  const elements = getElements();

  if (isLoading || isLoadingArchived) {
    return (
      <div style={{ padding: '0 20px 20px 20px' }}>
        <div className={elementCardGridStyles.elementCardGrid}>
          <Skeleton className={elementCardStyles.elementCard} />
          <Skeleton className={elementCardStyles.elementCard} />
          <Skeleton className={elementCardStyles.elementCard} />
        </div>
      </div>
    );
  }

  if (augurs?.length === 0 && archivedAugurs?.length === 0)
    return (
      <NoElements
        title={{
          id: 'dashboard.augurs.add_first_augur',
          defaultMessage: 'There are no Augurs for this Habitat yet.',
        }}
      />
    );

  return (
    <div className={'augurs'}>
      <CollapsibleArea
        maxHeight={HABITAT_GRID_MAX_HEIGHT}
        contentStyle={{
          borderTop: 'none',
          borderLeft: 'none',
          borderRight: 'none',
          borderRadius: 0,
        }}
        childrenStyle={{
          paddingBottom: '20px',
        }}
      >
        {isListView ? (
          <ElementCardTable elements={elements} />
        ) : (
          <ElementCardGrid elements={elements} />
        )}
      </CollapsibleArea>
    </div>
  );
};

export default Augurs;
