import React, { FC } from 'react';
import { FiHash, FiPlay } from 'react-icons/fi';
import { renderRoutes } from 'react-router-config';
import { Redirect, useLocation, useRouteMatch } from 'react-router-dom';

import * as routes from '../../index/routes';
import { CodeCapsuleDetailsRouteParams } from '../../index/routes';
import WithSidemenu from '../../molecules/with-sidemenu/WithSidemenu';
import Executions from '../../organisms/code-capsule-executions/Executions';
import Versions from '../../organisms/code-capsule-versions/Versions';
import OverlayCodeCapsuleDetails from '../../organisms/sub-header/overlay-code-capsule-details/OverlayCodeCapsuleDetails';
import MainContainer from '../main-container/MainContainer';
import NotFound from '../not-found/NotFound.container';

export const codeCapsuleDefaultPage = routes.app.codeCapsule.versions;

const CodeCapsuleDetails: FC = () => {
  const location = useLocation();

  const getActiveCategory = (): string | null => {
    if (!location.pathname) return null;
    const parts = location.pathname.split('/');
    if (parts.length < 7) return null;

    return parts[6]; // TODO No good idea to pick out the active category like this ...
  };

  const match = useRouteMatch<CodeCapsuleDetailsRouteParams>();
  if (match.isExact) {
    return <Redirect to={`${match.url}/${codeCapsuleDefaultPage}`} />;
  }
  const { codeCapsuleCode, habitatCode } = match.params;

  const selectedCategory = getActiveCategory();

  const menuEntries = [
    {
      type: 'link' as const,
      id: 'versions',
      nameIntlId: 'no-id',
      nameDefault: 'Versions',
      icon: () => <FiHash size={16} />,
      to: `/app/habitat/${habitatCode}/codecapsule/${codeCapsuleCode}/versions`,
      activePerDefault: true,
    },
    {
      type: 'link' as const,
      id: 'executions',
      nameIntlId: 'no-id',
      nameDefault: 'Executions',
      icon: () => <FiPlay size={16} />,
      to: `/app/habitat/${habitatCode}/codecapsule/${codeCapsuleCode}/executions`,
      activePerDefault: true,
    },
  ];

  return (
    <MainContainer fullWidth transparent>
      <div className={'code-capsule-details'}>
        <OverlayCodeCapsuleDetails />

        <WithSidemenu menuEntries={menuEntries} selectedTab={selectedCategory}>
          {renderRoutes([
            {
              path: `${routes.app.prefix + routes.app.codeCapsule.prefix}/${
                routes.app.codeCapsule.versions
              }`,
              component: Versions,
              routes: [],
            },
            {
              path: `${routes.app.prefix + routes.app.codeCapsule.prefix}/${
                routes.app.codeCapsule.executions
              }`,
              component: Executions,
              routes: [],
            },
            {
              component: NotFound,
            },
          ])}
        </WithSidemenu>
      </div>
    </MainContainer>
  );
};

export default CodeCapsuleDetails;
