import { Buffer } from 'buffer';
import {
  REPO_TYPE,
  RepoType as RepoTypeType,
} from 'common/dist/types/repository';
import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useFile } from '../../../../core/api/workbench/collab';
import { showDeleteRepoConfirm } from '../../../../redux/workbench/modules/collab.module';
import { useAppDispatch } from '../../../../store/store';
import Button from '../../../atoms/button/Button';
import RepositoryContent from '../../../molecules/repository-content/RepositoryContent';
import RepositoryFilePreview from '../../../molecules/repository-file-preview/RepositoryFilePreview';
import RepositoryHeader from '../../../molecules/repository-header/RepositoryHeader';
import styles from '../styles.module.scss';

type RepositoryProps = {
  activeBranch: string;
  loading?: boolean;
  branches?: {
    name: string;
  }[];
  loaded?: boolean;
  error?: string;
  data?: RepositoryData;

  fetchRepoDetails(...args: unknown[]): unknown;

  fetchBranches(...args: unknown[]): unknown;

  switchBranch(...args: unknown[]): unknown;
};

export type RepositoryData = {
  name: string;
  repoDescription?: string;
  repoType: RepoTypeType;
  repoFullName: string;
};

const RepositoryOverview: FC<RepositoryProps> = ({
  activeBranch,
  fetchRepoDetails,
  fetchBranches,
  switchBranch,
  loading,
  branches,
  error,
  data,
}) => {
  const dispatch = useAppDispatch();
  const { group, repositoryName } = useParams<{
    group: string;
    repositoryName: string;
  }>();
  const [selectedFile, setSelectedFile] = useState<string>();
  useEffect(() => {
    fetchRepoDetails(group, repositoryName);
    fetchBranches(group, repositoryName);
    // Only runs on mount and cleanup
  }, [fetchRepoDetails, fetchBranches, group, repositoryName]);

  const { name, repoType, repoFullName } = data || {};
  const parts = (repoFullName || '').split('/');
  const { data: file, isInitialLoading: fileLoading } = useFile(
    group,
    parts[1],
    selectedFile
  );
  let content: string;
  if (file && file.content) {
    const encoding: BufferEncoding = file?.encoding as BufferEncoding;
    content = Buffer.from(file.content, encoding).toString('utf8');
  }

  return (
    <div className={styles.pageContainer}>
      <RepositoryHeader
        repositoryType={repoType}
        loading={loading}
        error={error}
        data={data}
        branches={branches}
        activeBranch={branches?.find(
          (b) => b.name === (activeBranch || 'master')
        )}
        switchBranch={switchBranch}
      />

      <RepositoryContent
        setSelectedFile={setSelectedFile}
        repoFullName={repoFullName}
        activeBranch={activeBranch || 'master'}
      />
      <RepositoryFilePreview
        selectedFile={selectedFile}
        fileLoading={fileLoading}
        content={content}
        file={file}
      />
      {repoType === REPO_TYPE.PLAIN && (
        // Only allow the delete dialog for plain repositories. For apps, code-capsules it would just delete the repository part
        //  and leave the other tables and code capsule versions/reports etc. alone. Better to be consistent.
        <div className={styles.deleteButtonContainer}>
          <Button
            color={'red'}
            label={'Delete Repository'}
            onClick={() =>
              dispatch(
                showDeleteRepoConfirm(
                  name,
                  // @ts-ignore
                  repoFullName,
                  repoType
                )
              )
            }
          />
        </div>
      )}
    </div>
  );
};

export default RepositoryOverview;
