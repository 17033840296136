import { connect } from 'react-redux';

import OverlappingMenuEntry from './OverlappingMenuEntry';
import {
  selectNotebook,
  closeNotebook,
} from '../../../../../../redux/workbench/modules/notebook.module';
import {
  hideMenu,
  updateOverlappingTabs,
} from '../../../../../../redux/workbench/modules/notebook.overlapping.module';

export function mapStateToProps(state) {
  return {
    mostRecentPaneId: state.workbench.mostRecentPaneId,
  };
}

export const mapDispatchToProps = {
  selectNotebook,
  closeNotebook,
  hideOverlappingMenu: hideMenu,
  updateOverlappingTabs,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OverlappingMenuEntry);
