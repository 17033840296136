import { isApiError } from 'common/dist/constants/errors';
import msgsCommon from 'common/dist/messages/common';
import msgsRepository from 'common/dist/messages/repository';
import {
  RepositoryListing,
  RepoType,
  repoTypeToSpeaking,
} from 'common/dist/types/repository';
import { ApiError } from 'common/dist/types/responseBodies/errors';
import { DateFree } from 'common/dist/types/utils';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import ContentElement from './ContentElement';
import styles from './styles.module.scss';
import { DEFAULT_PAGE_SIZE } from './util';
import { useUserSummary } from '../../../../core/api/users';
import { useRepositories } from '../../../../core/api/workbench/repositories';
import Busy from '../../../atoms/busy/Busy';
import Button from '../../../atoms/button/Button';
import IndicatorEmpty from '../../../molecules/indicator-empty/IndicatorEmpty';
import Paging, { usePagingParameters } from '../../../molecules/paging/Paging';
import { repositoryCreateLink, repositoryDetailsLink } from '../../routes';

interface RepositoriesProps {
  type: RepoType;
}

const Repositories: FC<RepositoriesProps> = ({ type }) => {
  const { offset, search } = usePagingParameters();
  const { data, isError, error, isLoading } = useRepositories(
    type,
    offset,
    DEFAULT_PAGE_SIZE,
    search
  );

  const renderContent = () => {
    if (isError) {
      return (
        <div className={'repositories'}>
          <ErrorText error={error} />
        </div>
      );
    } else if (isLoading) {
      return <Busy isBusy={true} />;
    } else if (search && data?.length === 0) {
      return <NoSearchResults />;
    } else if (offset === 0 && data?.length === 0) {
      return <Empty type={type} />;
    } else {
      return (
        <div className={'repositories'}>
          {(data ?? []).map((repo) => (
            <WrappedContentElement key={repo.code} repo={repo} />
          ))}
        </div>
      );
    }
  };

  return (
    <div className={'CollaborationSpace--content'}>
      <Paging
        itemsPerPage={DEFAULT_PAGE_SIZE}
        searchEnabled
        currentItems={(data || []).length}
        Headline={() => <Headline type={type} />}
      >
        {renderContent()}
      </Paging>
    </div>
  );
};
const Headline: FC<Pick<RepositoriesProps, 'type'>> = ({ type }) => {
  const intl = useIntl();
  return (
    <div className={styles.headline}>
      <Button
        color={'secondary'}
        linkTo={repositoryCreateLink(type)}
        label={intl.formatMessage(msgsRepository.createButton, {
          type: repoTypeToSpeaking[type],
        })}
      />
    </div>
  );
};

/**
 * Its own component to fetch and display the userSummary
 * @param repo
 * @param i
 * @constructor
 */
const WrappedContentElement: FC<{
  repo: DateFree<RepositoryListing>;
}> = ({ repo }) => {
  // Gitea specific
  const groupName = repo.repoFullName.split('/')[0];
  const user = useUserSummary(groupName)?.data;
  const createdBy = useUserSummary(repo.createdByUserId)?.data;
  const speakingOwner =
    user === undefined ? undefined : `${user.firstName} ${user.lastName}`;
  return (
    <Link
      to={repositoryDetailsLink(repo.repoFullName)}
      style={{ textDecoration: 'none' }}
    >
      <ContentElement
        name={repo.name}
        repoDescription={repo.repoDescription}
        speakingOwner={speakingOwner}
        createdBy={createdBy}
        repoType={repo.repoType}
        createdAt={repo.createdAt}
        repoFullName={repo.repoFullName}
      />
    </Link>
  );
};

const ErrorText: FC<{ error: ApiError | Error | unknown }> = ({ error }) => {
  if (isApiError(error)) {
    return <FormattedMessage {...error.formattedMessage} />;
  } else if (error instanceof Error) {
    return <span>{error.message}</span>;
  } else {
    return <span>{JSON.stringify(error)}</span>;
  }
};

const Empty: FC<Pick<RepositoriesProps, 'type'>> = ({ type }) => {
  const intl = useIntl();
  return (
    <IndicatorEmpty
      classNameImage={'git-repositories-empty-pic'}
      headline={{
        ...msgsRepository.noRepositoriesTitle,
        values: { type: repoTypeToSpeaking[type] },
      }}
      description={{
        ...msgsRepository.noRepositoriesDescription,
        values: { type: repoTypeToSpeaking[type] },
      }}
      Actions={() => (
        <Button
          color={'secondary'}
          linkTo={repositoryCreateLink(type)}
          label={intl.formatMessage(msgsRepository.createButton, {
            type: repoTypeToSpeaking[type],
          })}
        />
      )}
    />
  );
};

const NoSearchResults: FC = () => {
  return (
    <IndicatorEmpty
      classNameImage={'git-repositories-empty-pic'}
      headline={msgsCommon.noSearchResultsTitle}
      description={msgsCommon.noSearchResultsDescription}
    />
  );
};
export default Repositories;
