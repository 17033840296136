import { CodeCapsule } from 'common/dist/types/codeCapsule';
import React from 'react';

import scssColors from '../../../../../scss/base/var.module.scss';
import Button from '../../../atoms/button/Button';
import { repositoryCreateHabitatLink } from '../../../collaborationSpace/routes';
import { getCodeCapsulesDetailsLink } from '../../../index/routes';
import { CardOrButtonType } from '../../../organisms/element-card-grid/ElementCardGrid';

export function deriveElements(
  codeCapsules: CodeCapsule[],
  habitatCode: string,
  openRunCodeCapsuleModal
): CardOrButtonType[] {
  const elements: CardOrButtonType[] = codeCapsules.map((codeCapsule) => {
    const codeCapsuleDetailsLink = getCodeCapsulesDetailsLink({
      codeCapsuleCode: codeCapsule.code,
      habitatCode,
      tabId: undefined,
    });
    const amountVersions = codeCapsule.versions
      ? codeCapsule.versions.length
      : 0;
    const amountVersionsText = `${amountVersions} ${
      amountVersions === 1 ? 'Version' : 'Versions'
    }`;

    return {
      type: 'card',
      linkTo: codeCapsuleDetailsLink,
      header: codeCapsule.name,
      subHeader: amountVersionsText,
      info: { Code: codeCapsule.code },
      barColor: scssColors.colorBlue,
      icons: [
        {
          Icon: () => (
            <div
              className={'icon-play'}
              style={{
                color: scssColors.colorTextLight,
                fontSize: '15px',
              }}
              onClick={(e) => {
                openRunCodeCapsuleModal({
                  versions: codeCapsule.versions,
                  name: codeCapsule.name,
                  repoCode: codeCapsule.repoCode,
                  codeCapsuleCode: codeCapsule.code,
                  habitatCode: codeCapsule.habitatCode,
                });
                e.preventDefault();
              }}
            />
          ),
          tooltip: {
            id: 'no-id',
            defaultMessage: 'Run Code Capsule',
          },
        },
      ],
    };
  });

  // 'Add Code Capsule' button
  elements.push({
    type: 'button',
    Button: () => (
      <Button
        label={'New Code Capsule'}
        color={'secondary'}
        Icon={() => (
          <span
            style={{
              fontSize: '18px',
              margin: '-3px 0px -2px -10px',
              opacity: 0.5,
            }}
            className={'icon-plus'}
          />
        )}
      />
    ),
    linkTo: repositoryCreateHabitatLink('code-capsule', habitatCode),
  });

  return elements;
}
