import Fallback from './Fallback';
import vars from '../../../../../../../../../../../../scss/base/var.module.scss';
import DefaultElementIcon from '../../../settings-dialog/DefaultElementIcon';
import P3InputElement, { AsInputElement } from '../_interface/P3InputElement';

export default class FallbackWrapper extends P3InputElement {
  // --- Elements

  ChildComponent: React.ComponentType = Fallback;
  AdditionalSettings: any = undefined;
  name = 'Fallback';
  parentContainerClass = 'fallback';
  type = 'fallback';
  icon: any = DefaultElementIcon;
  iconColor = vars.colorPrimary;

  // --- Functions

  getSource: (asElement: AsInputElement) => string = () => {
    return 'This element is not supported.';
  };

  /**
   * No validation implemented - every input is valid.
   * @param asElement
   */
  validate: (asElement: AsInputElement, variables: object[]) => string = (
    asElement: AsInputElement,
    variables: object[]
  ) => 'This element is not supported.';

  /**
   * Function to validate the settings in the element modal (label, description, outputVariable, ...)
   * For the NumberInput no additional validation apart from the common settings validation is required
   */
  validateSettings: (
    values: object,
    variables: object[]
  ) => object = () => ({});
}
