import { CodeCapsule } from 'common/dist/types/codeCapsule';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import {
  BuildCodeCapsuleData,
  BuildCodeCapsuleError,
  fieldTag,
  form,
} from './form';
import StepTag from './step-tag/StepTag';
import { RepoDetails } from '../../../../store/workbench/state.types';
import Busy from '../../../atoms/busy/Busy';
import Wizard from '../../../pages/wizard/Wizard';
import { repositoryDetailsLink2 } from '../../routes';

export interface Props {
  tag?: string;
  loading?: boolean;
  loaded?: boolean;
  error?: string;
  data?: RepoDetails;
  codeCapsule?: CodeCapsule;

  triggerCodeCapsuleBuild(...args: unknown[]): unknown;

  fetchRepoDetails(...args: unknown[]): unknown;
}

interface State {
  buttonClicked: boolean;
}

export interface RouteParams {
  group?: string;
  repositoryName?: string;
}

class BuildCodeCapsuleWizard extends Component<
  Props &
    InjectedFormProps<
      BuildCodeCapsuleData,
      Props & RouteComponentProps<RouteParams>,
      BuildCodeCapsuleError
    > &
    RouteComponentProps<RouteParams>,
  State
> {
  constructor(props) {
    super(props);
    this.state = { buttonClicked: false };
  }

  componentDidMount() {
    const { fetchRepoDetails } = this.props;
    const {
      match: {
        params: { group, repositoryName },
      },
    } = this.props;
    fetchRepoDetails(group, repositoryName);
  }

  render() {
    const {
      valid,
      tag,
      triggerCodeCapsuleBuild,
      data,
      handleSubmit,
      loading,
      history,
    } = this.props;
    const {
      match: {
        params: { group, repositoryName },
      },
    } = this.props;
    const { code: repoCode, codeCapsule } = data || {};

    if (loading) {
      return <Busy isBusy={true} />;
    }

    return (
      <form
        onSubmit={handleSubmit((values) => {
          this.setState({ buttonClicked: true });
          triggerCodeCapsuleBuild(
            repoCode, // repositoryCode
            codeCapsule.code,
            tag // capsuleVersionNumber,
          );
          history.push(repositoryDetailsLink2(group, repositoryName));
        })}
        style={{ height: '100%' }}
      >
        <Wizard
          headline={'Build Code Capsule'}
          buttons={[
            {
              id: 'wizard.cancel',
              label: 'Cancel',
              color: 'white',
              linkTo: repositoryDetailsLink2(group, repositoryName),
            },
            {
              id: 'wizard.submit',
              label: 'Build',
              color: 'secondary',
              type: 'submit',
              disabled: loading || !valid || this.state.buttonClicked,
            },
          ]}
        >
          <Field
            name={fieldTag}
            label={'Capsule Tag'}
            component={StepTag}
            versions={codeCapsule?.versions}
          />
        </Wizard>
      </form>
    );
  }
}

export default withRouter(
  reduxForm<
    BuildCodeCapsuleData,
    Props & RouteComponentProps<RouteParams>,
    BuildCodeCapsuleError
  >(form)(BuildCodeCapsuleWizard)
);
