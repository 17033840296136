import { ToBeRefined } from 'common/dist/types/todo_type';
import React, { FC } from 'react';

type Props = {
  path: string;
  cellId: string;
  /** Function to update the user input */
  updateDataOfInputElement: ToBeRefined;
  /** Input element (including the settings + the user input) */
  element: ToBeRefined;
  cell: ToBeRefined;
};

const Fallback: FC<Props> = ({ element }) => {
  return (
    <div className={'inner-input-element'}>
      DEPRECATED: Type {element.type} is not supported.
    </div>
  );
};

export default Fallback;
