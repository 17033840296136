import commonMessages from 'common/dist/messages/common';
import React, { FC, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import {
  fieldDescription,
  fieldSrcBranch,
  fieldTargetBranch,
  fieldTitle,
} from './mergeRequest.form';
import NameInputComponent from './NameInputComponent';
import StepBranch from './StepBranch.container';
import { workbenchRoutes } from '../../../../workbench/common/workbenchRoutes';
import Button from '../../../atoms/button/Button';
import GenericFormStep from '../../../molecules/generic-form-step/GenericFormStep';
import TextAreaInput from '../../../workbench/part-right/wizards/_components/TextAreaInput';
import { moduleDetailsLink, repositoryDetailsLink2 } from '../../routes';

type Props = {
  filledCompletely: boolean;
  openMergeRequest: (...args: unknown[]) => unknown;
  editMergeRequest: (...args: unknown[]) => unknown;
  fetchBranches: (...args: unknown[]) => unknown;
  loadMergeRequestDetails: (...args: unknown[]) => unknown;
  sourceBranch?: string;
  targetBranch?: string;
  title?: string;
  description?: string;
};

const OpenMergeRequestWizard: FC<Props> = (props) => {
  const {
    loadMergeRequestDetails,
    editMergeRequest,
    openMergeRequest,
    description,
    targetBranch,
    fetchBranches,
    sourceBranch,
    title,
    filledCompletely,
  } = props;

  const { group, repositoryName, id, moduleCode } = useRouteMatch<{
    moduleCode?: string;
    group: string;
    repositoryName: string;
    id: string;
  }>().params;
  const history = useHistory();
  useEffect(() => {
    fetchBranches(group, repositoryName);
    if (id) {
      loadMergeRequestDetails(group, repositoryName, id);
    }
  }, [fetchBranches, loadMergeRequestDetails, group, repositoryName, id]);
  let cancelButtonLink = repositoryDetailsLink2(group, repositoryName);
  if (history.location.pathname.includes('modules') && moduleCode) {
    cancelButtonLink = moduleDetailsLink(moduleCode);
  }

  return (
    <div className={'dialog-container'}>
      <div className={'dialog-inner-container'}>
        <p className={'dialog-headline'}>
          {id ? 'Edit' : 'Create'} Merge Request
        </p>
        <form
          onKeyPress={(e) => {
            if (e.which === 13) e.preventDefault();
          }}
        >
          <StepBranch
            title='Select Source Branch'
            description={
              id
                ? 'The source branch cannot be edited.'
                : 'Select the branch you want to merge.'
            }
            fieldName={fieldSrcBranch}
            stateKey='sourceBranch'
            num={1}
            isDisabled={!!id}
          />
          <StepBranch
            title='Select Target Branch'
            description='Select the branch you want to merge into.'
            fieldName={fieldTargetBranch}
            stateKey='targetBranch'
            num={2}
          />
          <GenericFormStep
            formName={'mergeRequest'}
            fieldName={fieldTitle}
            title={{
              defaultMessage: 'Name',
            }}
            description={{
              defaultMessage: 'Enter a name for the merge request.',
            }}
            num={3}
            component={NameInputComponent}
          />
          <GenericFormStep
            formName={'mergeRequest'}
            fieldName={fieldDescription}
            title={{
              defaultMessage: 'Description',
            }}
            description={{
              defaultMessage: 'Enter a description for the merge request.',
            }}
            num={4}
            component={TextAreaInput}
          />
          <div className={'dialog-button-container'}>
            <Button
              label={commonMessages.cancel}
              color='white'
              linkTo={cancelButtonLink}
            />

            <Button
              label='Finish'
              onClick={() => {
                if (filledCompletely) {
                  if (id)
                    editMergeRequest(
                      group,
                      repositoryName,
                      id,
                      title,
                      description,
                      targetBranch
                    );
                  else
                    openMergeRequest(
                      group,
                      repositoryName,
                      sourceBranch,
                      targetBranch,
                      title,
                      description
                    );
                }
              }}
              linkTo={`${workbenchRoutes.basePath}${workbenchRoutes.fileBrowser}`}
              color='secondary'
              disabled={!filledCompletely}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default OpenMergeRequestWizard;
