import classNames from 'classnames';
import React, { FC, PropsWithChildren } from 'react';

import styles from './styles.module.scss';

export enum ASC_DESC_VALUE {
  ASC = 'asc',
  DESC = 'desc',
}

export type Value = ASC_DESC_VALUE | undefined;

export type Props = PropsWithChildren<{
  value: Value;
  onChange: (value: Value) => void;
}>;

const AscDesc: FC<Props> = ({ value, onChange, children }) => {
  const className = classNames(styles.ascDesc, {
    [styles.asc]: value === ASC_DESC_VALUE.ASC,
    [styles.desc]: value === ASC_DESC_VALUE.DESC,
  });

  return (
    <span
      className={className}
      onClick={() => {
        switch (value) {
          case ASC_DESC_VALUE.ASC: {
            onChange(ASC_DESC_VALUE.DESC);
            break;
          }
          default: {
            // ASC_DESC_VALUE.ASC or inactive
            onChange(ASC_DESC_VALUE.ASC);
          }
        }
      }}
    >
      <span className={styles.top} />
      {children}
      <span className={styles.bottom} />
    </span>
  );
};

export default AscDesc;
