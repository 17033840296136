import { FlagdWebProvider } from '@openfeature/flagd-web-provider';
import { OpenFeature, OpenFeatureProvider } from '@openfeature/react-sdk';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import ClipboardJS from 'clipboard';
import errorMessages from 'common/dist/messages/error';
import { ToBeRefined } from 'common/dist/types/todo_type';
import { ConnectedRouter } from 'connected-react-router';
import React, { FC } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ReactIntlErrorCode } from 'react-intl';
import { IntlProvider } from 'react-intl-redux';
import { Provider } from 'react-redux';

import { queryClient } from './react_query';
import RootRoutes from './RootRoutes';
import { useSockets } from '../../socket';
import { history } from '../../store/store';
import Button from '../atoms/button/Button';
import AppInitializing from '../pages/appInitializing/AppInitializing';
import ErrorBoundary from '../pages/error-boundary/ErrorBoundary';

OpenFeature.setProvider(
  new FlagdWebProvider({
    host: window.location.hostname,
    // port: 443, // default
    // tls: true, // default
  })
);

type Props = {
  store: ToBeRefined;
  /** Default route for the model management */
  mainTabsDefaultLink: string;

  /** Has the dashboard been successfully been initialized? */
  initialized: boolean;
};

const App: FC<Props> = (props) => {
  const { store, mainTabsDefaultLink, initialized } = props;
  new ClipboardJS('.clipboard');

  // register global socket event listeners
  useSockets(store.dispatch, queryClient);

  if (!initialized) {
    return <AppInitializing />;
  }

  return (
    <Provider store={store}>
      {/* @ts-ignore */}
      <IntlProvider
        // Wrap Provider/IntlProvider manually to pass onError through react-intl-redux to react-intl, because the missing translations spam the error log
        // https://github.com/formatjs/formatjs/issues/465#issuecomment-643887472 | https://github.com/ratson/react-intl-redux#provider-vs-intlprovider
        onError={(err) => {
          // @ts-ignore
          if (err.code === ReactIntlErrorCode.MISSING_TRANSLATION) {
            // @ts-ignore
            console.warn('Missing translation', err.message);
            return;
          }
          throw err;
        }}
      >
        <OpenFeatureProvider>
          <ErrorBoundary
            errorHeadline={errorMessages.headline}
            errorDescription={errorMessages.description}
            fullScreenHeight={true}
            actions={
              <Button
                label={'Back to Dashboard'}
                color={'primary'}
                linkTo={'/'}
              />
            }
          >
            <QueryClientProvider client={queryClient}>
              {/* @ts-ignore */}
              <ConnectedRouter history={history}>
                <DndProvider backend={HTML5Backend}>
                  <RootRoutes mainTabsDefaultLink={mainTabsDefaultLink} />
                </DndProvider>
              </ConnectedRouter>
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </ErrorBoundary></OpenFeatureProvider>
      </IntlProvider>
    </Provider>
  );
};

export default App;
