import { QueryClient } from '@tanstack/react-query';
import notificationsMsgs from 'common/dist/messages/notifications';
import socketTypes, {
  ClientToServerEvents,
  ServerToClientEvents,
} from 'common/dist/socket/socketTypes';
import { useEffect } from 'react';
import io, { Socket } from 'socket.io-client';

import { augurKeys } from './core/api/augurs';
import { habitatKeys } from './core/api/habitats';
import { event } from './core/notifications';
import { sendNotification } from './redux/modules/notifications.module';
import {
  appendLogLines,
  updateJobKubernetesEvents,
  updateJobStatus,
  updateProgressSteps,
} from './redux/modules/orchestration.jobdetails.module';
import { predictionStarted } from './redux/modules/predictions.module';
import {
  createRealtimeSummary,
  deleteRealtimeSummary,
  updateRealtimeSummary,
} from './store/realtime/slice';
import { AppDispatch } from './store/store';
import keycloak from '../keycloak';

const getSocketUrl = () => '/';

export let socket: Socket<ServerToClientEvents, ClientToServerEvents>;

export const configureSockets = () => {
  if (socket) {
    socket.close();
  }

  socket = io(getSocketUrl(), {
    extraHeaders: {
      Authorization: `Bearer ${keycloak.token}`,
    },
  });
};

export const useSockets = (dispatch: AppDispatch, queryClient: QueryClient) => {
  useEffect(() => {
    socket.on(socketTypes.augur.created, async (augur) => {
      await queryClient.invalidateQueries(augurKeys.all());

      dispatch(
        sendNotification(
          notificationsMsgs.msgTitleAugurAddSuccess.id,
          // @ts-ignore
          notificationsMsgs.msgDescriptionAugurAddSuccess.id,
          event,
          { augurCode: augur.code, augurName: augur.name }
        )
      );
    });

    socket.on(socketTypes.habitat.created, async (habitat) => {
      await queryClient.invalidateQueries(habitatKeys.habitats());
    });

    socket.on(socketTypes.prediction.started, (prediction) => {
      const {
        augur: { code, name },
      } = prediction;
      dispatch(predictionStarted({ augurCode: code, augurName: name }));
    });

    socket.on(
      socketTypes.s3Bucket.created,
      ({ bucketName, bucketMessage, bucketStatusType }) => {
        console.log('received message', socket.id);
        dispatch(
          sendNotification(
            notificationsMsgs.titleS3BucketCreation.id,
            // @ts-ignore
            bucketMessage,
            bucketStatusType,
            { bucketName }
          )
        );
      }
    );

    socket.on(socketTypes.jobProgress.update, (data, roomId) => {
      // @ts-ignore
      dispatch(updateProgressSteps(roomId.split('-')[1], data));
    });

    socket.on(socketTypes.jobEvents.update, (data, roomId) => {
      // @ts-ignore
      dispatch(updateJobKubernetesEvents(roomId.split('-')[1], data));
    });

    socket.on(socketTypes.jobStatus.update, (status, roomId) => {
      // @ts-ignore
      dispatch(updateJobStatus(roomId.split('-')[1], status));
    });

    socket.on(socketTypes.jobLog.appendLines, (logLines, roomId) => {
      // @ts-ignore
      dispatch(appendLogLines(roomId.split('-')[2], logLines));
    });

    socket.on(socketTypes.realtimeAugur.create, (summary, roomId) => {
      dispatch(createRealtimeSummary(summary));
    });

    socket.on(socketTypes.realtimeAugur.update, (summary, roomId) => {
      dispatch(updateRealtimeSummary(summary));
    });

    socket.on(socketTypes.realtimeAugur.delete, (augurCode, roomId) => {
      dispatch(deleteRealtimeSummary(augurCode));
    });

    return () => {
      socket.off(socketTypes.augur.created);
      socket.off(socketTypes.habitat.created);
      socket.off(socketTypes.prediction.started);
      socket.off(socketTypes.s3Bucket.created);
      socket.off(socketTypes.jobProgress.update);
      socket.off(socketTypes.jobEvents.update);
      socket.off(socketTypes.jobStatus.update);
      socket.off(socketTypes.jobLog.appendLines);
      socket.off(socketTypes.realtimeAugur.create);
      socket.off(socketTypes.realtimeAugur.update);
      socket.off(socketTypes.realtimeAugur.delete);
    };
  }, [dispatch, queryClient]);
};

export const realtimeAugurCodeToRoom = `realtime`;
