import { connect } from 'react-redux';

import OverlappingMenu from './OverlappingMenu';

export function mapStateToProps(state, { paneId }) {
  return {
    overlappingTabs: state.workbench.overlappingNotebookTabs.overlappingTabs,
    pane: state.workbench.panes[paneId],
  };
}

export default connect(mapStateToProps)(OverlappingMenu);
