import classNames from 'classnames';
import { ModuleWithAllRelations } from 'common/dist/types/module';
import React, { FC } from 'react';
import LinesEllipsis from 'react-lines-ellipsis';

import styles from './styles.module.scss';
import AvatarIcon from '../../atoms/avatar-icon/AvatarIcon';
import RepositoryAvatar from '../../atoms/repository-avatar/RepositoryAvatar';

export type ModuleCardProps = ModuleWithAllRelations;

const ModuleCard: FC<ModuleCardProps> = ({
  name,
  versions,
  repository,
  description,
  createdBy,
  isStock,
  moduleAvatar,
}) => {
  const codeAvailable = repository?.code != null;
  const latestPublishedVersion = versions.sort((a, b) =>
    a.createdAt < b.createdAt ? 1 : -1
  )[0]?.number;
  const amountVersions = (versions || []).length;

  return (
    <div className={styles.moduleCard}>
      <div className={styles.body}>
        <div className={styles.iconContainer}>
          <RepositoryAvatar avatar={moduleAvatar} name={name} />
        </div>
        <div className={styles.textContainer}>
          <span className={styles.title} title={name}>
            {name}
          </span>
          <span className={styles.description}>
            {description ? (
              <LinesEllipsis
                text={description}
                maxLine={2}
                ellipsis={'...'}
                trimRight
                basedOn={'words'}
              />
            ) : (
              <em>No Description available.</em>
            )}
          </span>
        </div>
      </div>

      <div className={styles.footer}>
        {latestPublishedVersion && (
          <div
            className={classNames(
              styles.footerElement,
              styles.versionInfoContainer
            )}
          >
            <span
              className={styles.latestVersion}
              title={latestPublishedVersion}
            >
              {latestPublishedVersion}
            </span>
            <span
              className={styles.amountVersions}
              title={amountVersions.toString()}
            >
              {amountVersions}
            </span>
          </div>
        )}

        {codeAvailable && (
          <span
            className={classNames(styles.footerElement, styles.codeAvailable)}
          >
            Code
          </span>
        )}

        <div className={classNames(styles.footerElement, styles.avatar)}>
          <div className={styles.avatarIcon}>
            {createdBy && !isStock && (
              <AvatarIcon userId={createdBy} size={20} fontSize={8} />
            )}
            {isStock && (
              <div className={styles.stockIcon}>
                <img
                  src={'/favicons/apple-touch-icon.png'}
                  alt={'as'}
                  title={'AltaSigma'}
                  width={16}
                  height={16}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModuleCard;
