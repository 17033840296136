import React, { FC, ReactNode } from 'react';
import { FiInfo } from 'react-icons/fi';

import styles from './styles.module.scss';

const InfoBox: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <div className={styles.infoBox}>
      <div className={styles.iconContainer}>
        <FiInfo size={16} />
      </div>
      <div className={styles.infoContainer}>{children}</div>
    </div>
  );
};

export default InfoBox;
