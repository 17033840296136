import { connect } from 'react-redux';

import CodeCapsules from './CodeCapsules';
import { triggerRunCodeCapsuleJob } from '../../../../redux/modules/triggerJobs.module';
import { RootState } from '../../../../store/store';

type OwnProps = {
  habitatCode: string;
};

export function mapStateToProps(state: RootState, { habitatCode }: OwnProps) {
  return {
    habitatCode,
  };
}

export const mapDispatchToProps = {
  triggerRunCodeCapsuleJob,
};

export default connect(mapStateToProps, mapDispatchToProps)(CodeCapsules);
