import { RepoType } from 'common/dist/types/repository';
import { formatDate } from 'common/dist/utils/dates';
import React, { FC } from 'react';

import { iconFromRepoType } from '../../../atoms/altasigma-icons/AltaSigmaIcons';

interface ContentElementProps {
  /** Name of the element - Can be missing if the extra information besides git is not available */
  name?: string;
  /** Description of the repository */
  repoDescription: string;
  /** Type of the repository. */
  repoType?: RepoType;
  /** Creation timestamp */
  createdAt: string;
  /** Full name of the repository - Always guaranteed to be there, as it comes directly from git */
  repoFullName: string;
  createdBy?: {
    firstName?: string;
    middleName?: string;
    lastName?: string;
    code?: string;
  };
  /** If available can replace the group */
  speakingOwner?: string;
}

const ContentElement: FC<ContentElementProps> = ({
  repoFullName,
  repoDescription,
  createdAt,
  repoType,
  createdBy,
  speakingOwner,
  name,
}) => {
  const parts = (repoFullName || '').split('/');
  const group = parts[0];
  const pathName = parts[1];
  const ContentIcon = iconFromRepoType(repoType);

  return (
    <div className={'repository'}>
      <div className={'repo-icon-parent'}>
        <ContentIcon size={'20px'} />
      </div>

      <div className={'repo-name-parent'}>
        <div className={'repo-name'}>
          <span className={'repo-group'}>{speakingOwner ?? group}</span> /{' '}
          <span className={'name'}>{name ?? pathName}</span>
        </div>
        <div className={'repo-description'}>
          {repoDescription ? (
            <span>{repoDescription}</span>
          ) : (
            <span>
              <i>No description available</i>
            </span>
          )}
        </div>
        <div className={'repo-created'}>
          <span>
            Created at{' '}
            {createdAt &&
              formatDate(new Date(createdAt), 'dd.MM.yyyy HH:mm:ss')}
            {createdBy && ` by ${createdBy.firstName} ${createdBy.lastName}`}{' '}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ContentElement;
