import { Augur } from 'common/src/types/augur';

export const comparators: Record<string, (a: Augur, b: Augur) => number> = {
  '1a': (a, b) => (b.health ?? 0) - (a.health ?? 0),
  '1b': (a, b) => (a.health ?? 0) - (b.health ?? 0),

  '2a': (a, b) => a.name.localeCompare(b.name),
  '2b': (a, b) => b.name.localeCompare(a.name),

  '3a': (a, b) =>
    new Date(b.createdAt).getDate() - new Date(a.createdAt).getDate(),
  '3b': (a, b) =>
    new Date(a.createdAt).getDate() - new Date(b.createdAt).getDate(),
};

export const defaultOrdering = '2a';
export const defaultComparator = comparators[defaultOrdering];
