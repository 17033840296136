import classNames from 'classnames';
import React, { FC, useCallback, useState } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';

import DropZoneBody from './DropZoneBody';
import styles from './styles.module.scss';
import {
  useAvatar,
  useDeleteAvatar,
  useUploadAvatar,
} from '../../../core/api/accounts';

export const MAX_SIZE = 1048576; // 1MB max

const AccountAddPhoto: FC = () => {
  const [uploadProgress, setUploadProgress] = useState<number>();

  const { data: avatar, isLoading } = useAvatar();
  const { mutate: uploadAvatar, isLoading: isUploading } = useUploadAvatar();
  const { mutate: deleteAvatar } = useDeleteAvatar();

  const onDrop: DropzoneOptions['onDrop'] = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      if (file) {
        // Can be undefined if a too large file was dropped (and was then rejected by the DropZone)
        uploadAvatar({
          file,
          onUploadProgress: setUploadProgress,
        });
      }
    },
    [uploadAvatar]
  );

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    accept: {
      'image/*': [],
    },
    minSize: 0,
    maxSize: MAX_SIZE,
    multiple: false,
  });

  const isFileTooLarge =
    fileRejections.length > 0 && fileRejections[0].file.size > MAX_SIZE;
  const dropZoneClassName = classNames(styles.dropzone, {
    [styles.dropzoneAvatar]: !!avatar,
    [styles.dropzoneNoAvatar]: !avatar,
  });

  return (
    <div className={styles.container0}>
      <div className={styles.container1}>
        <div className={styles.container2}>
          <div {...getRootProps({ className: dropZoneClassName })}>
            <input {...getInputProps()} />
            <DropZoneBody
              loading={isLoading}
              data={avatar ?? undefined}
              deleteAvatar={() => deleteAvatar()}
              isFileTooLarge={isFileTooLarge}
              uploading={isUploading}
              progress={uploadProgress}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountAddPhoto;
