import {
  SelectedSchedule as ScheduleType,
  SelectedSchedule,
} from 'common/src/utils/schedules';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { untouch, WrappedFieldProps } from 'redux-form';

import RadioButtonsInput from '../../atoms/input-elements/radio-buttons-input/RadioButtonsInput';
import CronScheduleSelect from '../../molecules/cron-schedule-select/CronScheduleSelect';
import DelayedScheduleSelect from '../../molecules/delayed-schedule-select/DelayedScheduleSelect';
import {
  newScheduleFormName,
  selectedScheduleField,
} from '../../orchestration/job-schedules/add-schedule/newSchedule.form';

export const validateField = (value: SelectedSchedule) => {
  if (!value) return undefined;
  if (value.trigger === 'delayed') {
    if (value.trigDelayed !== undefined) {
      return undefined;
    } else {
      return {};
    }
  }

  return undefined;
};

const ScheduleSelect: FC<WrappedFieldProps> = (props) => {
  const dispatch = useDispatch();
  const {
    meta: { error, touched },
    input: { value, onChange },
  } = props;
  const untouchField = () => {
    untouch(newScheduleFormName, selectedScheduleField);
    dispatch(untouch(newScheduleFormName, selectedScheduleField));
  };

  function renderOptions() {
    return (
      <RadioButtonsInput
        id={'scheduleType'}
        touched={touched}
        error={error?.trigger}
        disabled={false}
        hasLabel={false}
        value={value?.trigger}
        onChange={(trigger: ScheduleType['trigger']) => {
          if (trigger !== value.trigger) {
            onChange({ trigger: trigger });
            untouchField();
          }
        }}
        entriesPerRow={1}
        columnWidth={'200px'}
        radioButtons={[
          {
            value: 'delayed',
            labelId: 'no-id',
            labelDefault: 'Delayed Schedule',
          },
          {
            value: 'cron',
            labelId: 'no-id',
            labelDefault: 'Cron Schedule',
          },
        ]}
      />
    );
  }
  return (
    <>
      {renderOptions()}
      {value?.trigger === 'delayed' && (
        <DelayedScheduleSelect
          {...props}
          untouch={untouchField}
          validateField={validateField}
        />
      )}
      {value?.trigger === 'cron' && <CronScheduleSelect {...props} />}
    </>
  );
};

export default ScheduleSelect;
