// --- These are the message keys used for the socket communication

import { Augur } from '../types/augur';
import { HabitatWithScopes } from '../types/habitat';
import { StatusType } from '../types/job';
import { GenericJobSupervisorInitializeJobType } from '../types/jobsupervisor';
import { ToBeRefined } from '../types/todo_type';

export default {
  augur: {
    created: 'augur.created',
    progress: 'augur.progress',
  },
  dataConnection: {
    created: 'dataConnection.created',
  },
  habitat: {
    created: 'habitat.created',
  },
  prediction: {
    started: 'prediction.started',
  },
  s3Bucket: {
    created: 's3Bucket.created',
  },
  jobProgress: {
    update: 'progress_update',
  },
  jobEvents: {
    update: 'events_update',
  },
  jobStatus: {
    update: 'status_update',
  },
  jobLog: {
    appendLines: 'log_append_lines',
  },
  realtimeAugur: {
    create: 'realtime_augur_create',
    update: 'realtime_augur_update',
    delete: 'realtime_augur_delete',
  },
} as const;

/*
  Socket events from the Orchestration.
 */
interface OrchestrationToClientEvents {
  progress_update: (progress: ToBeRefined, roomId: string) => void;
  events_update: (events: ToBeRefined, roomId: string) => void;
  status_update: (status: StatusType, roomId: string) => void;
  log_append_lines: (lines: ToBeRefined, roomId: string) => void;
  realtime_augur_create: (summary: ToBeRefined, roomId: string) => void;
  realtime_augur_update: (summary: ToBeRefined, roomId: string) => void;
  realtime_augur_delete: (augurCode: string, roomId: string) => void;
}

export interface ServerToClientEvents extends OrchestrationToClientEvents {
  'augur.created': (augur: Augur) => void;
  'dataConnection.created': () => void;
  'habitat.created': (habitat: HabitatWithScopes) => void;
  'prediction.started': (
    prediction: GenericJobSupervisorInitializeJobType
  ) => void;
  's3Bucket.created': (bucketInfo: {
    bucketName: string;
    bucketMessage: string;
    bucketStatusType: string;
  }) => void;
}

export interface ClientToServerEvents {
  subscribe: (code: string) => void;
  unsubscribe: (code: string) => void;
}

export interface InterServerEvents {}
export interface SocketData {}
