import type { TableColumnsType } from 'antd';
import { Table } from 'antd';
import React, { FC } from 'react';

import styles from './styles.module.scss';
import { useDimensions } from '../../../../../../../utils';
import { ReportElementProps } from '../../../types/meta';
import {
  PerformanceDriftConfig,
  PerformanceDriftReportData,
  PerformanceDriftValueFormat,
} from '../type';

export type Props = {
  data: PerformanceDriftReportData;
} & PerformanceDriftConfig;

interface DataType {
  name: string;
  value: number;
  format: PerformanceDriftValueFormat;
}

export function formatValue(
  val: Pick<DataType, 'value' | 'format'>
): string | number {
  if (!val.value) {
    return 'N/A';
  } else if (val.format === 'percentage') {
    return (val.value * 100).toFixed(2) + '%';
  } else if (val.format === 'decimal') {
    return val.value.toFixed(3);
  } else {
    return val.value;
  }
}

const Columns: TableColumnsType<DataType> = [
  {
    title: 'Name',
    dataIndex: 'name',
    render: (_, val) => {
      return <span title={val.name}>{val.name}</span>;
    },
  },
  {
    title: 'Value',
    dataIndex: 'value',
    render: (_, val) => {
      const inner = String(formatValue(val));
      return <span title={inner}>{inner}</span>;
    },
  },
];

export const PerformanceDrift: FC<Props> = (props) => {
  const { data } = props;
  const [ref, { width }] = useDimensions<HTMLDivElement>();

  // When the table splits in multiple parallel tables
  const tableSplitPixels = 340;
  const tablePreCount = Math.floor(width / tableSplitPixels);
  const tableCount = tablePreCount === 0 ? 1 : tablePreCount;

  const rows = Object.keys(data).length;
  const basicElementsPerTable = Math.floor(rows / tableCount);
  const restElements = rows % tableCount;

  const elementsPerTable: number[] = [];
  for (let i = 0; i < tableCount; i++) {
    elementsPerTable.push(basicElementsPerTable);
  }
  for (let i = 0; i < restElements; i++) {
    elementsPerTable[i]++;
  }

  const keys: string[] = Object.keys(data);

  const tableKeys: string[][] = [];

  let elementCount = 0;
  elementsPerTable.forEach((e, index1) => {
    elementCount += e;
    tableKeys.push([]);
    keys.forEach((k, index) => {
      if (index < elementCount && index >= elementCount - e) {
        tableKeys[index1].push(k);
      }
    });
  });

  const dataTypeFormats: DataType[][] = tableKeys.map((tK) => {
    return tK.map((key) => {
      return {
        name: key,
        value: data[key].value,
        format: data[key].format,
      };
    });
  });

  const filteredData = dataTypeFormats.filter((data) => data.length > 0);
  if (filteredData.length === 0) {
    filteredData.push([]);
  }

  return (
    <div ref={ref} className={styles.container}>
      {filteredData.map((d, index) => {
        return (
          <Table
            key={index}
            className={styles.table}
            size='small'
            showHeader={false}
            columns={Columns}
            pagination={false}
            dataSource={d}
          />
        );
      })}
    </div>
  );
};

export const PerformanceDriftSingle: FC<
  ReportElementProps<PerformanceDriftReportData, PerformanceDriftConfig>
> = ({ input: { reportValue }, config, ...rest }) => (
  <PerformanceDrift data={reportValue} {...config} {...rest} />
);
