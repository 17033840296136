import { ToBeRefined } from 'common/dist/types/todo_type';
import React, { FC, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import CodeCapsulesCardView from './CodeCapsulesCardView';
import CodeCapsulesListView from './CodeCapsulesListView';
import { useCodeCapsules } from '../../../../core/api/codeCapsules';
import CollapsibleArea from '../../../molecules/collapsable-area/CollapsibleArea';
import elementCardStyles from '../../../molecules/element-card/styles.module.scss';
import elementCardGridStyles from '../../../organisms/element-card-grid/styles.module.scss';
import RunCodeCapsuleModal from '../../../runCodeCapsuleModal/RunCodeCapsuleModal.container';
import { HABITAT_GRID_MAX_HEIGHT } from '../augurs/Augurs';
import NoElements from '../no-elements/NoElements';

type Props = {
  habitatCode: string;
  triggerRunCodeCapsuleJob: (...args: ToBeRefined) => void;
  isListView: boolean;
};

const CodeCapsules: FC<Props> = ({
  habitatCode,
  triggerRunCodeCapsuleJob,
  isListView,
}) => {
  const {
    data: codeCapsules,
    isSuccess,
    isLoading,
    isError,
    error,
  } = useCodeCapsules(habitatCode);

  const [runCodeCapsuleModalOpen, setRunCodeCapsuleModalOpen] = useState(false);
  const [
    codeCapsuleModalProps,
    setCodeCapsuleModalProps,
  ] = useState<ToBeRefined>({});

  const openRunCodeCapsuleModal = (props: ToBeRefined) => {
    setRunCodeCapsuleModalOpen(true);
    setCodeCapsuleModalProps(props);
  };

  const closeRunCodeCapsuleModal = () => {
    setRunCodeCapsuleModalOpen(false);
    setCodeCapsuleModalProps({});
  };

  const renderLoaded = () => {
    if (isListView) {
      return (
        <CodeCapsulesListView
          habitatCode={habitatCode}
          codeCapsules={codeCapsules}
          openRunCodeCapsuleModal={openRunCodeCapsuleModal}
        />
      );
    } else {
      return (
        <CodeCapsulesCardView
          habitatCode={habitatCode}
          codeCapsules={codeCapsules}
          openRunCodeCapsuleModal={openRunCodeCapsuleModal}
        />
      );
    }
  };

  if (isLoading) {
    return (
      <div style={{ padding: '0 20px 20px 20px' }}>
        <div className={elementCardGridStyles.elementCardGrid}>
          <Skeleton className={elementCardStyles.elementCard} />
          <Skeleton className={elementCardStyles.elementCard} />
          <Skeleton className={elementCardStyles.elementCard} />
        </div>
      </div>
    );
  }

  if (!codeCapsules || codeCapsules.length === 0) {
    return (
      <NoElements
        title={{
          id: 'dashboard.code_capsules.no_code_capsules',
          defaultMessage: 'There are no Code Capsules for this Habitat yet.',
        }}
      />
    );
  }

  return (
    <div className={'code-capsules'}>
      <CollapsibleArea
        maxHeight={HABITAT_GRID_MAX_HEIGHT}
        contentStyle={{
          borderTop: 'none',
          borderLeft: 'none',
          borderRight: 'none',
          borderRadius: 0,
        }}
        childrenStyle={{
          paddingBottom: '20px',
        }}
      >
        {renderLoaded()}
      </CollapsibleArea>
      {runCodeCapsuleModalOpen && (
        <RunCodeCapsuleModal
          close={closeRunCodeCapsuleModal}
          onSubmit={triggerRunCodeCapsuleJob}
          {...codeCapsuleModalProps}
        />
      )}
    </div>
  );
};

export default CodeCapsules;
