import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux-act';

import styles from './styles.module.scss';
import { DeprecatedRootState } from '../../../../store/state.type';
import Button, { ButtonProps } from '../../../atoms/button/Button';
import SimpleContextMenu, {
  ContextMenuEntry,
} from '../context-menu/SimpleContextMenu';

export type IconButtonBarButton = Omit<
  ButtonProps,
  'onClick' | 'disabled' | 'contextMenu'
> & {
  onClick?: (state: DeprecatedRootState, dispatch: Dispatch) => void;
  disabled?: (state: DeprecatedRootState) => boolean;
  /** If a contextMenu is set, the onClick will be ignored */
  contextMenu?: ContextMenuEntry[];
};

export type Props = {
  buttons: IconButtonBarButton[];
};

const IconButtonBar: FC<Props> = ({ buttons }) => {
  const state = useSelector<DeprecatedRootState, DeprecatedRootState>(
    (state) => state
  );
  const dispatch = useDispatch();
  // For which id is the contextMenu shown?
  const [expandedId, setExpandedId] = useState<string>(null);
  return (
    <div className={styles.buttonBar}>
      {buttons
        .map((b) => ({
          ...b,
          onClick: b.onClick ? () => b.onClick(state, dispatch) : undefined,
          disabled: b.disabled ? b.disabled(state) : false,
        }))
        .map((b) => {
          const { id, Icon, contextMenu, ...buttonProps } = b;
          return (
            <div
              key={id}
              className={styles.buttonContainer}
              onClick={() =>
                setExpandedId((prev) =>
                  prev === id || !contextMenu ? null : id
                )
              }
            >
              <Button
                Icon={() => <Icon size={20} />}
                shape={'squared'}
                {...buttonProps}
              />
              {expandedId === id && (
                <SimpleContextMenu
                  menuId={'settings'}
                  entries={contextMenu}
                  hide={() => setExpandedId(null)}
                />
              )}
            </div>
          );
        })}
    </div>
  );
};

export default IconButtonBar;
