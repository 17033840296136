import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import ActionButtons from './ActionButtons';
import DetailValues from './DetailValues';
import NameContainer from './NameContainer';
import { CProps } from './OverlayAugurDetails.container';
import styles from './styles.module.scss';
import { useAugur } from '../../../../core/api/augurs';
import { useHabitat } from '../../../../core/api/habitats';
import { AugurDetailsRouteParams } from '../../../index/routes';

const OverlayAugurDetails: FC<CProps> = ({
  triggerLearningJob,
  triggerEvaluationJob,
  triggerPredictionJob,
}) => {
  const { habitatCode, augurCode } = useParams<AugurDetailsRouteParams>();

  const { data: augur, isSuccess } = useAugur(augurCode);
  const { data: habitat } = useHabitat(habitatCode);

  return (
    <div className={styles.detailsBar}>
      <NameContainer
        isLoaded={isSuccess}
        augurName={augur?.name}
        augurHealth={augur?.health}
        habitatName={habitat?.name}
      />
      <DetailValues />
      <ActionButtons
        triggerLearningJob={triggerLearningJob}
        triggerEvaluationJob={triggerEvaluationJob}
        triggerPredictionJob={triggerPredictionJob}
      />
    </div>
  );
};

export default OverlayAugurDetails;
