import { RouteComponentProps } from 'react-router';
import { ConfigProps } from 'redux-form';

import { Props, RouteParams } from './BuildCodeCapsuleWizard';

export const formName = 'buildCodeCapsule';

export const fieldTag = 'tag';

export interface BuildCodeCapsuleData {
  [fieldTag]: string;
}

export type BuildCodeCapsuleError = string;

export const form: ConfigProps<
  BuildCodeCapsuleData,
  Props & RouteComponentProps<RouteParams>,
  BuildCodeCapsuleError
> = {
  form: formName,
  initialValues: {},
  enableReinitialize: true,
  validate: (values, props) => {
    let errors = {};

    // --- Validate Tag
    const tagRegex = /^[a-zA-Z0-9\.\-_]+$/;
    const tag = values[fieldTag];
    if (!tag || tag.trim() === '') {
      errors = { ...errors, [fieldTag]: 'Please enter a tag' };
    } else if (tag.length > 128) {
      // Seems to be enforced by the go builders use as a git tag
      errors = {
        ...errors,
        [fieldTag]: 'Please enter a tag with at maximum 128 characters',
      };
    } else if (!tagRegex.test(tag)) {
      errors = { ...errors, [fieldTag]: 'Please enter a valid tag' };
    } else if (
      (props.codeCapsule?.versions || [])
        .map((version) => version.number)
        .includes(tag)
    ) {
      errors = {
        ...errors,
        [fieldTag]: 'Please enter a tag different from existing tags',
      };
    }

    return errors;
  },
};
