import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import {
  Repository,
  RepositoryListing,
  RepoType,
} from 'common/dist/types/repository';
import { PostAddRepositoryBody } from 'common/dist/types/requestBodies/repositories';
import { DateFree } from 'common/dist/types/utils';
import qs from 'qs';

import { apiRequest, deleteApiRequest } from './_apiRequests';
import { fetchQueryFn, fetchQueryFnWorkbench, postApiRequest } from '../_tools';

export const repositoryKeys = {
  all: () => ['repositories'] as const,
  category: (type?: RepoType) => [...repositoryKeys.all(), type] as const,
  some: (type: RepoType, offset?: number, limit?: number, search?: string) =>
    [...repositoryKeys.category(type), offset, limit, search] as const,
  byRepoFullName: (repoFullNames: string[]) =>
    [...repositoryKeys.all(), 'byRepoFullName', repoFullNames] as const,
  add: () => [...repositoryKeys.all(), 'add'] as const,
  delete: () => [...repositoryKeys.all(), 'delete'] as const,
};

const getRepositories = ({
  type,
  offset,
  limit,
  search,
  repoFullNames,
}: {
  type?: RepoType;
  offset?: number;
  limit?: number;
  search?: string;
  repoFullNames?: string[];
}) => {
  const query = qs.stringify(
    { type, offset, limit, search, repoFullNames },
    { addQueryPrefix: true, arrayFormat: 'comma' }
  );
  return apiRequest(`/api/workbench/collab/repositories${query}`);
};

export const useRepositories = (
  type?: RepoType,
  offset?: number,
  limit?: number,
  search?: string
): UseQueryResult<DateFree<RepositoryListing>[]> => {
  const key = repositoryKeys.some(type, offset, limit, search);
  return useQuery(
    key,
    () =>
      fetchQueryFnWorkbench(key, () =>
        getRepositories({
          type,
          offset,
          limit,
          search,
        })
      ),
    {
      keepPreviousData: true, // Only use this for paging
    }
  );
};

export const useRepositoriesByRepoFullName = (
  repoFullNames: string[],
  enabled = true
): UseQueryResult<DateFree<RepositoryListing>[]> => {
  const key = repositoryKeys.byRepoFullName(repoFullNames);
  return useQuery(
    key,
    () => fetchQueryFnWorkbench(key, () => getRepositories({ repoFullNames })),
    { enabled }
  );
};

export function useAddRepository(
  repositoryType: RepoType
): UseMutationResult<Repository, unknown, PostAddRepositoryBody> {
  const queryClient = useQueryClient();
  const key = repositoryKeys.add();
  return useMutation(
    key,
    (body) =>
      fetchQueryFn(key, () => {
        return postApiRequest('/api/workbench/collab/gitrepo', body);
      }),
    {
      onSettled: async () => {
        await queryClient.invalidateQueries(
          repositoryKeys.category(repositoryType)
        );
      },
    }
  );
}

interface DeleteRepositoryVariables {
  repoFullName: string;
}

export function useDeleteRepository(
  repositoryType: RepoType,
  onMutateSuccess?: () => void
): UseMutationResult<unknown, unknown, DeleteRepositoryVariables> {
  const queryClient = useQueryClient();
  const key = repositoryKeys.delete();
  return useMutation(
    key,
    ({ repoFullName }) => {
      return fetchQueryFnWorkbench(key, () =>
        deleteApiRequest(`/api/workbench/collab/gitrepo/${repoFullName}`)
      );
    },
    {
      onSettled: async () => {
        await queryClient.invalidateQueries(
          repositoryKeys.category(repositoryType)
        );
      },
      onSuccess: onMutateSuccess,
    }
  );
}
