export const test = {
  loaded: false,
  loading: true,
  initialized: false,
  search: {
    query: 'augur',
    loading: false,
    loaded: true,
    error: '',
    data: {
      habitats: [],
      augurs: [{ name: 'augur 1', code: 'augurcode1' }],
    },
  },
};

export const initial = {
  loaded: false,
  loading: false,
  initialized: false,
  search: {
    query: '',
    loading: false,
    loaded: false,
    error: '',
    data: {
      habitats: [],
      augurs: [],
    },
  },
};
