import collabSpaceMsgs from 'common/dist/messages/collaborationSpace';
import msgsForm from 'common/dist/messages/form';
import notificationMsgs from 'common/dist/messages/notifications';
import { HabitatWithScopes } from 'common/dist/types/habitat';
import { REPO_TYPE } from 'common/dist/types/repository';
import { PostAddRepositoryBody } from 'common/dist/types/requestBodies/repositories';
import { User } from 'common/dist/types/users';
import qs from 'qs';
import React, { FC, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { MessageDescriptor } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';

import styles from './styles.module.scss';
import {
  validateRepositoryDescription,
  validateRepositoryHabitat,
  validateRepositoryName,
  validateRepositorySlug,
} from './validation';
import { useAppsNames } from '../../../../core/api/apps';
import { useCodeCapsuleNames } from '../../../../core/api/codeCapsules';
import { useHabitats } from '../../../../core/api/habitats';
import {
  usePlainRepositoriesNames,
  useRepositorySlugs,
} from '../../../../core/api/repositories';
import { useAddRepository } from '../../../../core/api/workbench/repositories';
import * as NOTIFICATION_TYPES from '../../../../core/notifications';
import { sendNotification } from '../../../../redux/modules/notifications.module';
import { RootState, useAppDispatch } from '../../../../store/store';
import Busy from '../../../atoms/busy/Busy';
import {
  DropdownSelectInput,
  Option,
} from '../../../atoms/react-hook-form-input-elements/dropdown-select-input/DropdownSelectInput';
import IntlTextInputArea from '../../../atoms/react-hook-form-input-elements/text-input-area/TextInputArea';
import IntlTextInputLine from '../../../atoms/react-hook-form-input-elements/text-input-line/TextInputLine';
import BubbleStep from '../../../molecules/bubble-step/BubbleStep';
import Wizard from '../../../pages/wizard/Wizard';

type RepositoryType = 'plain' | 'code-capsule' | 'app';

type CodeCapsuleHabitat = {
  code: string;
  name: string;
};

type Props = {
  repositoryType?: RepositoryType;
};

export type RepositoryFormData = {
  repositoryName?: {
    display: string;
    slug: string;
  };
  repositoryDescription?: string;
  repositoryType?: RepositoryType;
  codeCapsuleHabitat?: CodeCapsuleHabitat;
};

const AddRepositoryWizard: FC<Props> = (props) => {
  const { repositoryType = 'plain' } = props;
  const addRepositoryMutation = useAddRepository(repositoryType);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const initialHabitatCode = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  }).habitatCode as string;

  const methods = useForm<RepositoryFormData>({
    mode: 'all',
    defaultValues: {
      codeCapsuleHabitat: { code: initialHabitatCode },
      ...props,
    },
    values: { ...props },
  });

  const {
    formState: { isDirty, isValid, isSubmitting },
    control,
    getFieldState,
    getValues,
  } = methods;

  const displayToSlug = (display: string) =>
    display
      .trim()
      .toLowerCase()
      .replaceAll(/[^a-z0-9-]/g, '-')
      .replace(/-+/g, '-')
      .replace(/(^-)|(-$)/, '');
  const [isSlugUnmodified, setIsSlugUnmodified] = useState(true);

  const user = useSelector<RootState, User>((state) => state.currentUser);

  let wizardHeadline: MessageDescriptor;
  if (repositoryType === REPO_TYPE.CODE_CAPSULE) {
    wizardHeadline = collabSpaceMsgs.addCodeCapsuleWizardHeadline;
  } else if (repositoryType === REPO_TYPE.APP) {
    wizardHeadline = collabSpaceMsgs.addAppWizardHeadline;
  } else {
    wizardHeadline = collabSpaceMsgs.addRepositoryWizardHeadline;
  }
  const onSubmit = async () => {
    if (isValid) {
      let body: PostAddRepositoryBody;
      const {
        repositoryName,
        repositoryDescription,
        repositoryType,
        codeCapsuleHabitat,
      } = getValues();
      if (repositoryType === REPO_TYPE.CODE_CAPSULE) {
        body = {
          repositoryType,
          repositoryName,
          repositoryDescription,
          codeCapsuleHabitat,
        };
      } else {
        body = {
          repositoryType,
          repositoryName,
          repositoryDescription,
        };
      }

      await addRepositoryMutation
        .mutateAsync(body)
        .then(() => {
          let notificationTitle: MessageDescriptor;
          let notificationDescription: MessageDescriptor;
          switch (body.repositoryType) {
            case 'app':
              notificationTitle = notificationMsgs.msgTitleAppCreateSuccess;
              notificationDescription =
                notificationMsgs.msgDescriptionAppCreateSuccess;
              break;
            case 'code-capsule':
              notificationTitle =
                notificationMsgs.msgTitleCodeCapsuleCreateSuccess;
              notificationDescription =
                notificationMsgs.msgDescriptionCodeCapsuleCreateSuccess;
              break;
            default:
              notificationTitle =
                notificationMsgs.msgTitlePlainRepositoryCreateSuccess;
              notificationDescription =
                notificationMsgs.msgDescriptionPlainRepositoryCreateSuccess;
          }
          dispatch(
            sendNotification(
              notificationTitle.defaultMessage,
              // @ts-ignore
              notificationDescription.defaultMessage,
              NOTIFICATION_TYPES.event
            )
          );
        })
        .catch((e) => {
          let notificationTitle: MessageDescriptor;
          let notificationDescription: MessageDescriptor;
          switch (body.repositoryType) {
            case 'app':
              notificationTitle = notificationMsgs.msgTitleAppCreateFailure;
              notificationDescription =
                notificationMsgs.msgDescriptionAppCreateFailure;
              break;
            case 'code-capsule':
              notificationTitle =
                notificationMsgs.msgTitleCodeCapsuleCreateFailure;
              notificationDescription =
                notificationMsgs.msgDescriptionCodeCapsuleCreateFailure;
              break;
            case 'plain':
              notificationTitle =
                notificationMsgs.msgTitlePlainRepositoryCreateFailure;
              notificationDescription =
                notificationMsgs.msgDescriptionPlainRepositoryCreateFailure;
          }
          dispatch(
            sendNotification(
              notificationTitle.defaultMessage,
              // @ts-ignore
              notificationDescription.defaultMessage,
              NOTIFICATION_TYPES.error
            )
          );
        });
      history.goBack();
    }
  };

  const {
    data: codeCapsuleNames,
    isLoading: isCodeCapsuleNamesLoading,
  } = useCodeCapsuleNames(undefined, repositoryType === REPO_TYPE.CODE_CAPSULE);
  const { data: appsNames, isLoading: isAppsNamesLoading } = useAppsNames(
    repositoryType === REPO_TYPE.APP
  );
  const {
    data: plainRepositoriesNames,
    isLoading: isPlainRepositoriesNamesLoading,
  } = usePlainRepositoriesNames(repositoryType === REPO_TYPE.PLAIN);
  const {
    data: slugNames,
    isLoading: isSlugNamesLoading,
  } = useRepositorySlugs();
  const { data: habitats, isLoading: isHabitatsLoading } = useHabitats(
    repositoryType === REPO_TYPE.CODE_CAPSULE
  );

  const renderNameStep = () => {
    const { invalid, isDirty, error } = getFieldState(
      'repositoryName',
      methods.formState
    );

    return (
      <BubbleStep
        title={collabSpaceMsgs.addRepositoryWizardNameStepTitle}
        description={collabSpaceMsgs.addRepositoryWizardNameStepDescription}
        stepNumber={1}
        isValid={isDirty && !invalid}
        isErroneous={isDirty && !!error}
      >
        <Controller
          name={'repositoryName.display'}
          control={control}
          rules={{
            validate: (name: string) => {
              if (repositoryType === REPO_TYPE.CODE_CAPSULE) {
                return validateRepositoryName(
                  name,
                  codeCapsuleNames,
                  isCodeCapsuleNamesLoading
                );
              } else if (repositoryType === REPO_TYPE.APP) {
                return validateRepositoryName(
                  name,
                  appsNames,
                  isAppsNamesLoading
                );
              } else if (repositoryType === REPO_TYPE.PLAIN) {
                return validateRepositoryName(
                  name,
                  plainRepositoriesNames,
                  isPlainRepositoriesNamesLoading
                );
              }
              return true;
            },
          }}
          render={({ field, fieldState }) => {
            const { ref, onChange, ...rest } = field; // extract ref to pass as inputRef
            return (
              <IntlTextInputLine
                label={collabSpaceMsgs.addRepositoryWizardNameStepTitle}
                placeholder={collabSpaceMsgs.addRepositoryWizardNamePlaceholder}
                {...rest}
                {...fieldState}
                // we want to always show the errors (especially after clicking on existing elements, and it is acceptable for new elements)
                isTouched={true}
                inputRef={ref}
                error={fieldState.error?.message}
                // custom onChange to update slug
                onChange={(e) => {
                  onChange(e);
                  methods.setValue(
                    'repositoryName.slug',
                    isSlugUnmodified
                      ? displayToSlug(e.target.value)
                      : methods.getValues('repositoryName.slug'),
                    { shouldDirty: true, shouldValidate: true }
                  );
                }}
              />
            );
          }}
        />
        <div className={styles.container}>
          <div className={styles.group}>
            <span>
              {user?.firstName}&nbsp;{user?.lastName}
            </span>
            <span>/</span>
          </div>

          <Controller
            name={'repositoryName.slug'}
            rules={{
              validate: (slug: string) => {
                return validateRepositorySlug(
                  slug,
                  slugNames,
                  isSlugNamesLoading
                );
              },
            }}
            control={control}
            render={({ field, fieldState }) => {
              const { ref, onChange, ...rest } = field; // extract ref to pass as inputRef
              return (
                <IntlTextInputLine
                  placeholder={
                    collabSpaceMsgs.addRepositoryWizardSlugPlaceholder
                  }
                  {...rest}
                  {...fieldState}
                  // we want to always show the errors (especially after clicking on existing elements, and it is acceptable for new elements)
                  isTouched={true}
                  inputRef={ref}
                  error={fieldState.error?.message}
                  // custom onChange to update slug
                  onChange={(e) => {
                    setIsSlugUnmodified((e.target.value ?? '').length === 0);
                    onChange(e);
                  }}
                />
              );
            }}
          />
        </div>
      </BubbleStep>
    );
  };

  const renderDescriptionStep = () => {
    const { invalid, isDirty, error } = getFieldState(
      'repositoryDescription',
      methods.formState
    );

    return (
      <BubbleStep
        title={collabSpaceMsgs.addRepositoryWizardDescriptionStepTitle}
        description={
          collabSpaceMsgs.addRepositoryWizardDescriptionStepDescription
        }
        stepNumber={2}
        isErroneous={isDirty && !!error}
        isValid={isDirty && !invalid}
      >
        <Controller
          name={'repositoryDescription'}
          rules={{
            validate: validateRepositoryDescription,
          }}
          control={control}
          render={({ field, fieldState }) => {
            const { ref, ...rest } = field; // extract ref to pass as inputRef
            return (
              <IntlTextInputArea
                label={collabSpaceMsgs.addRepositoryWizardDescriptionStepTitle}
                placeholder={
                  collabSpaceMsgs.addRepositoryWizardDescriptionPlaceholder
                }
                {...rest}
                {...fieldState}
                // we want to always show the errors (especially after clicking on existing elements, and it is acceptable for new elements)
                isTouched={true}
                amountRows={5}
                inputRef={ref}
                error={fieldState.error?.message}
              />
            );
          }}
        />
      </BubbleStep>
    );
  };

  const storedHabitatInfo = (
    habitats: HabitatWithScopes[],
    habitatCode: string
  ): Partial<CodeCapsuleHabitat> => {
    if (!habitatCode) return {};

    const habitat = habitats.find((habitat) => habitat.code === habitatCode);
    if (!habitat) return {};

    return {
      code: habitat.code,
      name: habitat.name,
    };
  };

  const renderCodeCapsuleHabitatStep = () => {
    const { invalid, isDirty, isTouched, error } = getFieldState(
      'codeCapsuleHabitat',
      methods.formState
    );

    return (
      <BubbleStep
        title={collabSpaceMsgs.addRepositoryWizardHabitatStepName}
        description={collabSpaceMsgs.addRepositoryWizardHabitatStepDescription}
        stepNumber={3}
        isValid={isDirty && !invalid}
        isErroneous={isDirty && !!error}
      >
        <Controller
          name={'codeCapsuleHabitat'}
          rules={{
            validate: validateRepositoryHabitat,
          }}
          control={control}
          render={({ field }) => {
            const { onChange, onBlur, value } = field;

            if (isHabitatsLoading) {
              return <Busy isBusy={true} />;
            }

            const options = habitats.map((habitat) => ({
              label: habitat.name,
              value: habitat.code,
            }));

            return (
              <div className={'GenericFormStep--field'}>
                <DropdownSelectInput
                  isTouched={isTouched}
                  error={error?.message}
                  disabled={false}
                  id={'habitat'}
                  name={'habitat'}
                  placeholder={
                    collabSpaceMsgs.addRepositoryWizardHabitatStepName
                      .defaultMessage
                  }
                  value={options.find((o) => o.value === value?.code)}
                  onChange={(option: Option) =>
                    onChange(storedHabitatInfo(habitats, option.value))
                  }
                  onBlur={onBlur}
                  isLoading={isHabitatsLoading}
                  options={options}
                />
              </div>
            );
          }}
        />
      </BubbleStep>
    );
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        onKeyPress={(e) => {
          if (e.which === 13) e.preventDefault();
        }}
        style={{ height: '100%' }}
      >
        <Wizard
          headline={wizardHeadline}
          buttons={[
            {
              color: 'white',
              label: msgsForm.cancel,
              onClick: () => {
                history.goBack();
              },
            },
            {
              color: 'secondary',
              type: 'submit',
              label: msgsForm.submit,
              disabled: !isDirty || !isValid,
              isBusy: isSubmitting,
            },
          ]}
        >
          {/* --- Wizard Steps */}
          {renderNameStep()}
          {renderDescriptionStep()}
          {repositoryType === REPO_TYPE.CODE_CAPSULE &&
            renderCodeCapsuleHabitatStep()}
        </Wizard>
      </form>
    </FormProvider>
  );
};

export default AddRepositoryWizard;
