import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import ActionButtons from './ActionButtons';
import NameContainer from './NameContainer';
import { useCodeCapsule } from '../../../../core/api/codeCapsules';
import { triggerRunCodeCapsuleJob } from '../../../../redux/modules/triggerJobs.module';
import { useAppDispatch } from '../../../../store/store';
import { CodeCapsuleDetailsRouteParams } from '../../../index/routes';
import styles from '../styles.module.scss';

const OverlayCodeCapsuleDetails: FC = () => {
  const dispatch = useAppDispatch();
  const { codeCapsuleCode } = useParams<CodeCapsuleDetailsRouteParams>();

  const { data: codeCapsule, isSuccess, isLoading } = useCodeCapsule(
    codeCapsuleCode
  );

  return (
    <div className={styles.subheaderLeft}>
      <div className={styles.detailsBar}>
        <NameContainer
          loading={isLoading}
          loaded={isSuccess}
          codeCapsule={codeCapsule}
        />

        <ActionButtons
          loading={isLoading}
          loaded={isSuccess}
          codeCapsule={codeCapsule}
          triggerRunCodeCapsuleJob={(...props) => {
            // @ts-ignore this requires a tuple type which args is, but I'm too lazy to type that here because this should be refactored anyways
            dispatch(triggerRunCodeCapsuleJob(...props));
          }}
        />
      </div>
    </div>
  );
};

export default OverlayCodeCapsuleDetails;
