import { REPO_TYPE, RepoType } from 'common/dist/types/repository';
import React, { FC } from 'react';
import { useRouteMatch } from 'react-router-dom';

import styles from './styles.module.scss';
import Button from '../../atoms/button/Button';
import {
  repositoryBuildAppLink,
  repositoryBuildCodeCapsuleLink,
  repositoryCloneLink2,
} from '../../collaborationSpace/routes';

type Props = {
  repoType?: RepoType;
};

const RepositoryButtonBar: FC<Props> = ({ repoType }) => {
  const {
    params: { group, repositoryName },
  } = useRouteMatch<{ group: string; repositoryName: string }>();

  if (repoType === REPO_TYPE.MODULE) return null; // No buttons for Module repositories in this screen

  return (
    <div className={styles.buttonContainer}>
      {repoType === REPO_TYPE.CODE_CAPSULE && (
        <Button
          color={'primary'}
          label={'Build Code Capsule'}
          linkTo={repositoryBuildCodeCapsuleLink(group, repositoryName)}
        />
      )}
      {repoType === REPO_TYPE.APP && (
        <Button
          color={'primary'}
          label={'Build App'}
          linkTo={repositoryBuildAppLink(group, repositoryName)}
        />
      )}

      <Button
        color={'secondary'}
        label={'Clone Repository'}
        linkTo={repositoryCloneLink2(group, repositoryName)}
      />
    </div>
  );
};

export default RepositoryButtonBar;
