import React, { Component, PropsWithChildren, ReactNode } from 'react';
import { MessageDescriptor } from 'react-intl';

import './styles.scss';
import ErrorPage from './ErrorPage';

export type Props = PropsWithChildren<{
  errorHeadline?: MessageDescriptor;
  errorDescription?: MessageDescriptor;
  actions?: ReactNode;
  /** Sets "height: 100vh" to the Error Boundary */
  fullScreenHeight?: boolean;
}>;

interface State {
  hasError: boolean;
}

export default class ErrorBoundary extends Component<Props, State> {
  static defaultProps = {
    errorHeadline: {
      id: 'error.headline',
      defaultMessage: 'An unexpected error occurred.',
    },
  };

  constructor(props: PropsWithChildren<Props>) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage {...this.props} />;
    }
    return this.props.children;
  }
}
