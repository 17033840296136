import { CodeCapsuleVersion } from 'common/dist/types/codeCapsule';
import React, { Component, FC } from 'react';
import { WrappedFieldProps } from 'redux-form';

import TextInputLine from '../../../../atoms/input-elements/text-input-line/TextInputLine';
import GenericFormStep from '../../../../molecules/generic-form-step/GenericFormStep';
import Table from '../../../../molecules/table/Table';
import { versionsRenderColumns } from '../../common';
import { fieldTag, formName } from '../form';

export interface Props {
  versions?: CodeCapsuleVersion[];
}

type CombinedProps = Props & WrappedFieldProps;

const Inner: FC<CombinedProps> = ({
  versions,
  input: { value, onChange, onBlur, onFocus },
  meta: { touched, valid },
}) => {
  const sortedVersions = versions;
  if (sortedVersions)
    sortedVersions.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));

  const renderVersions = () => {
    if (sortedVersions && sortedVersions.length > 0) {
      return (
        <div className={'tags-container'}>
          <Table<CodeCapsuleVersion>
            data={sortedVersions}
            renderColumns={versionsRenderColumns}
            showHeader={true}
            striped={true}
          />
        </div>
      );
    } else {
      return (
        <div>
          <span>There are no Tags for this Code Capsule yet</span>
        </div>
      );
    }
  };

  return (
    <div className={'input-container'}>
      <TextInputLine
        touched={touched}
        valid={valid}
        disabled={!versions}
        hasLabel={false}
        placeholderDefault={'Tag'}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
      />
      <div className={'existing-tags-parent'}>
        <div className={'tags-headline'}>
          Existing Tags for this Code Capsule
        </div>
        {renderVersions()}
      </div>
    </div>
  );
};

export default class StepTag extends Component<Props & WrappedFieldProps> {
  render() {
    return (
      <GenericFormStep
        fieldName={fieldTag}
        formName={formName}
        component={Inner}
        title={{ id: 'todo', defaultMessage: 'Set Tag' }}
        description={{
          id: 'todo',
          defaultMessage: 'Set the Tag for the Code Capsule',
        }}
        num={1}
        fieldProps={this.props}
      />
    );
  }
}
