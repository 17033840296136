import { REPO_TYPE } from 'common/dist/types/repository';
import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';

import Modules from './browser/modules/Modules';
import Repositories from './browser/repositories/Repositories';
import ModuleDetails from './module-details/ModuleDetails';
import RepositoryDetails from './repository-details/RepositoryDetails';
import { collaborationSpaceRoutes } from './routes';
import AddRepositoryWizard from './wizards/repository-add/AddRepositoryWizard';
import BuildAppWizard from './wizards/repository-build-app/BuildAppWizard.container';
import BuildCodeCapsuleWizard from './wizards/repository-build-code-capsule/BuildCodeCapsuleWizard.container';
import BuildModuleWizard from './wizards/repository-build-module/BuildModuleWizard';
import CloneRepositoryWizard from './wizards/repository-clone/CloneRepositoryWizard.container';
import MainContainer from '../pages/main-container/MainContainer';
import NewModuleWizard from '../pages/NewModuleWizard/NewModuleWizard';
import NoPermissions from '../pages/no-permissions/NoPermissions';
import NotFound from '../pages/not-found/NotFound.container';

export interface Props {
  missingPermissionsError?: boolean;
}

const CollaborationSpace: FC<Props & RouteComponentProps> = ({
  missingPermissionsError,
}) => {
  if (missingPermissionsError) return <NoPermissions fullViewHeight />;

  return (
    <MainContainer
      additionalClassname={'CollaborationSpace'}
      transparent
      fullWidth={true}
    >
      <Switch>
        {/* Add Repository Wizard */}
        <Route
          path={`${collaborationSpaceRoutes.basePath}/${collaborationSpaceRoutes.repositories.create}`}
        >
          <AddRepositoryWizard repositoryType='plain' />
        </Route>
        <Route
          path={`${collaborationSpaceRoutes.basePath}/${collaborationSpaceRoutes.codeCapsules.create}`}
        >
          <AddRepositoryWizard repositoryType='code-capsule' />
        </Route>
        <Route
          path={`${collaborationSpaceRoutes.basePath}/${collaborationSpaceRoutes.apps.create}`}
        >
          <AddRepositoryWizard repositoryType='app' />
        </Route>

        {/* Clone Repository Wizard */}
        <Route
          path={`${collaborationSpaceRoutes.basePath}/${collaborationSpaceRoutes.repositories.clone}/:group/:repositoryName`}
          component={CloneRepositoryWizard}
        />

        {/* Build App Wizard */}
        <Route
          path={`${collaborationSpaceRoutes.basePath}/${collaborationSpaceRoutes.repositories.buildApp}/:group/:repositoryName`}
        >
          <BuildAppWizard />
        </Route>

        {/* Build Code Capsule Wizard */}
        <Route
          path={`${collaborationSpaceRoutes.basePath}/${collaborationSpaceRoutes.repositories.buildCodeCapsule}/:group/:repositoryName`}
        >
          <BuildCodeCapsuleWizard />
        </Route>

        {/* Build the module*/}
        <Route
          path={`${collaborationSpaceRoutes.basePath}/${collaborationSpaceRoutes.modules.basePath}/${collaborationSpaceRoutes.modules.build}`}
          render={(routeProps) => (
            <BuildModuleWizard
              moduleCode={routeProps.match.params.moduleCode}
              repoGroup={routeProps.match.params.group}
              repoName={routeProps.match.params.repositoryName}
            />
          )}
        />

        {/* New Module Wizard */}
        <Route
          path={`${collaborationSpaceRoutes.basePath}/${collaborationSpaceRoutes.modules.basePath}/${collaborationSpaceRoutes.modules.create}`}
          exact
        >
          <NewModuleWizard />
        </Route>

        {/* Edit Module Wizard */}
        <Route
          path={`${collaborationSpaceRoutes.basePath}/${collaborationSpaceRoutes.modules.basePath}/${collaborationSpaceRoutes.modules.edit}`}
          render={(routeProps) => (
            <NewModuleWizard moduleCode={routeProps.match.params.moduleCode} />
          )}
        />

        {/* Overview Code Capsules */}
        <Route
          path={`${collaborationSpaceRoutes.basePath}/${collaborationSpaceRoutes.overviewCodeCapsules}`}
          exact
        >
          <Repositories type={REPO_TYPE.CODE_CAPSULE} />
        </Route>

        {/* Overview Apps */}
        <Route
          path={`${collaborationSpaceRoutes.basePath}/${collaborationSpaceRoutes.overviewApps}`}
          exact
        >
          <Repositories type={REPO_TYPE.APP} />
        </Route>

        {/* Overview Plain */}
        <Route
          path={`${collaborationSpaceRoutes.basePath}/${collaborationSpaceRoutes.overviewPlain}`}
          exact
        >
          <Repositories type={REPO_TYPE.PLAIN} />
        </Route>

        {/* Overview Modules */}
        <Route
          path={`${collaborationSpaceRoutes.basePath}/${collaborationSpaceRoutes.overviewModules}`}
          exact
        >
          <Modules />
        </Route>

        {/* Module Details Pages (with SideMenu, not a Wizard) These all share the .../details prefix */}
        <Route
          path={`${collaborationSpaceRoutes.basePath}/${collaborationSpaceRoutes.modules.basePath}/${collaborationSpaceRoutes.modules.details.basePath}`}
        >
          <ModuleDetails />
        </Route>

        {/* Repository Details */}
        <Route
          path={`${collaborationSpaceRoutes.basePath}/:group/:repositoryName`}
        >
          <RepositoryDetails />
        </Route>

        {/* Default redirect to the code capsule overview */}
        <Redirect
          exact
          path={`${collaborationSpaceRoutes.basePath}`}
          to={`${collaborationSpaceRoutes.basePath}/${collaborationSpaceRoutes.overviewCodeCapsules}`}
        />

        {/* Fallback: Not found. */}
        <Route component={() => <NotFound fullViewHeight={false} />} />
      </Switch>
    </MainContainer>
  );
};

export default CollaborationSpace;
