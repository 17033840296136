import {
  augurCodeFromBucket,
  isDatapoolBucket,
} from 'common/dist/constants/dataManagement/s3';
import {
  S3_BUCKET_SCOPE_IDS,
  S3_BUCKET_SCOPES,
} from 'common/dist/constants/keycloak';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useAugurNames } from '../../../../core/api/augurs';
import { useBuckets } from '../../../../core/api/data';
import { isAdmin } from '../../../../redux/selectors/user.selector';
import { S3Permissions } from '../../../../store/dataManagement/state.types';
import Busy from '../../../atoms/busy/Busy';
import Button from '../../../atoms/button/Button';
import TextInputSearch from '../../../atoms/input-elements/text-input-search/TextInputSearch';
import PermissionTable from '../../../organisms/permission-table/PermissionTable';
import { dataManagementRoutes } from '../../routes';
import { UPLOAD_WIZARDS } from '../upload/uploadWizards';

export type Props = {
  fetchS3Permissions: (dataSourceCode: string) => void;
  dataSourceCode: string;
  setActiveUploadWizard: (activeWizard: string, uploadCode: string) => void;
  permissions: S3Permissions;
};

const Buckets = ({
  fetchS3Permissions,
  dataSourceCode,
  setActiveUploadWizard,
  permissions,
}: Props) => {
  const { isLoading, isError, data, error } = useBuckets(dataSourceCode);

  const [bucketSearch, setBucketSearch] = useState('');

  const { data: augurNames } = useAugurNames();

  const isCurrentUserAdmin = useSelector(isAdmin);
  useEffect(() => {
    fetchS3Permissions(dataSourceCode);
  }, [fetchS3Permissions, dataSourceCode]);

  const renderError = () => (
    <div className='buckets'>
      <span className='error'>{JSON.stringify(error)}</span>
    </div>
  );

  const renderHeader = () => {
    const allowedToUpload = new Set(
      Object.values(permissions.data || {}).flat()
    ).has(S3_BUCKET_SCOPE_IDS.WRITE);

    return (
      <div className='buckets-headline-container'>
        <span className='buckets-headline' />
        <div style={{ width: '260px' }}>
          <TextInputSearch
            initialValue={bucketSearch}
            submitSearchQuery={setBucketSearch}
            submitOnChange
          />
        </div>
        {allowedToUpload && (
          <Button
            color='secondary'
            label='Upload Data'
            linkTo={`${dataManagementRoutes.basePath}/s3/${dataSourceCode}/upload`}
            onClick={() =>
              setActiveUploadWizard(UPLOAD_WIZARDS.UPLOAD, undefined)
            }
          />
        )}

        {isCurrentUserAdmin && (
          <Button
            color='secondary'
            label='Create Bucket'
            linkTo={`${dataManagementRoutes.basePath}/s3/${dataSourceCode}/create`}
          />
        )}
      </div>
    );
  };

  const renderLoaded = () => {
    return (
      <div className='buckets'>
        {renderHeader()}
        {data && (
          <PermissionTable
            title='S3 Bucket'
            permissionColumns={S3_BUCKET_SCOPES.map((s) => s.displayName)}
            rowProps={data
              .filter((bucket) =>
                bucketSearch ? bucket.name.includes(bucketSearch) : true
              )
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((bucket) => {
                const permissionSet = new Set(permissions.data?.[bucket.name]);
                const allowedToRead = permissionSet.has(
                  S3_BUCKET_SCOPE_IDS.READ
                );
                const allowedToWrite = permissionSet.has(
                  S3_BUCKET_SCOPE_IDS.WRITE
                );
                const augurName =
                  augurNames?.[
                    augurCodeFromBucket(bucket.name).toUpperCase()
                  ] || '';
                return {
                  title: bucket.name,
                  displayTitle:
                    isDatapoolBucket(bucket.name) && `Augur Data ${augurName}`,
                  subInfo: `Created at: ${bucket.createdAt}`,
                  withLink: allowedToRead || allowedToWrite,
                  linkTo: `${dataManagementRoutes.basePath}/s3/${dataSourceCode}/bucket/${bucket.name}/path/`,
                  permissionList: S3_BUCKET_SCOPES.map((s) =>
                    permissionSet.has(s.id)
                  ),
                };
              })}
          />
        )}
      </div>
    );
  };

  const renderEmpty = () => (
    <div className='buckets buckets-empty-parent'>
      {renderHeader()}
      <div className='buckets-empty'>
        <div className='buckets-empty-elements'>
          <div className='buckets-empty-pic'>&nbsp;</div>
          <span className='headline'>No Buckets yet</span>
          <span className='description'>
            Please create a new bucket or ask an Administrator for permission to
            existing Buckets.
          </span>
        </div>
      </div>
    </div>
  );

  if (isLoading) return <Busy isBusy={true} />;
  if (isError) return renderError();
  if (data && data.length === 0) return renderEmpty();
  return renderLoaded();
};

export default Buckets;
