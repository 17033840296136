import { useQueryClient } from '@tanstack/react-query';
import msgsNotifications from 'common/dist/messages/notifications';
import React, { FC, useState } from 'react';
import { MemoryRouter, useLocation, useRouteMatch } from 'react-router-dom';

import { baseConfig, baseSettings } from './config.template';
import EditAugur from './EditAugur';
import styles from './styles.module.scss';
import { ModuleConfiguration } from './type';
import {
  fromAugurSettingsFormState,
  toAugurSettingsFormState,
} from './utils/augurSettings.form';
import {
  filterConfigForElementVisibility,
  getVisibilityDependencyArray,
} from './utils/cfe';
import { FALLBACK_CONFIG } from './utils/config';
import { reportAndSettingsSchemas } from './utils/reportAndSettingsSchemas';
import ViewAugur from './ViewAugur';
import {
  modulesKeys,
  useInfiniteModuleFiles,
  useModules,
} from '../../../core/api/modules';
import { saveConfig, saveSettings } from '../../../core/api/workbench/content';
import NotebookApi from '../../../core/api/workbench/git.notebook';
import {
  error as ERROR_NOTIFICATION,
  event as EVENT_NOTIFICATION,
} from '../../../core/notifications';
import { sendNotification } from '../../../redux/modules/notifications.module';
import { useAppDispatch } from '../../../store/store';
import Busy from '../../atoms/busy/Busy';
import { AugurCategory } from '../../molecules/augur-menu/types';
import OverlayDevAugurDetails from '../../organisms/sub-header/overlay-augur-details/OverlayDevAugurDetails';
import {
  getFirstActiveJobCodeOfCurrentCategory,
  getTimeTravelEntryFromReport,
} from '../../organisms/time-travel/helpers';
import { useTimeTravelHelpers } from '../../organisms/time-travel/hooks';
import ErrorBoundary from '../error-boundary/ErrorBoundary';

type Props = {
  notebookUser: string;
  activeProjectPath: string;
};

export const DevAugur: FC<Props> = (props) => {
  const { notebookUser, activeProjectPath } = props;
  const [editMode, setEditMode] = useState(false);
  const queryClient = useQueryClient();

  const dispatch = useAppDispatch();
  const notebookApi = new NotebookApi(notebookUser);

  const { data: moduleFilesData } = useInfiniteModuleFiles(activeProjectPath);

  const reports = moduleFilesData
    ? moduleFilesData?.pages?.flatMap((page) => page.info.reports)
    : [];

  const pageMatch = useRouteMatch<{
    pageCategory?: AugurCategory;
    pageId?: string;
  }>(`/:pageCategory?/:pageId?`);
  const { pageCategory: selectedPageCategory } = pageMatch?.params || {};

  const { isHighlighted } = useTimeTravelHelpers(selectedPageCategory);

  const { data: modules, isInitialLoading: isModulesLoading } = useModules();

  if (isModulesLoading) {
    return <Busy isBusy={true} />;
  }
  const latestAugurReports = moduleFilesData?.pages[0].info.latestReports
    ? Object.values(moduleFilesData?.pages[0].info.latestReports)
    : [];
  if (latestAugurReports && Array.isArray(latestAugurReports)) {
    latestAugurReports.forEach((additionalJob) => {
      if (!reports.some((job) => job.jobCode === additionalJob.jobCode)) {
        reports.push(additionalJob);
      }
    });
  }
  // calculate the reports to be displayed from the TimeTravel selection
  // this uses the same logic that is used for calculating the highlighted entries of the TimeTravel component
  const activeModelCode =
    latestAugurReports.length > 0
      ? latestAugurReports.find(
          (report) => report.jobType === selectedPageCategory
        )?.modelCode
      : '';

  const firstActiveJobCodeOfCurrentCategory = getFirstActiveJobCodeOfCurrentCategory(
    reports.map(getTimeTravelEntryFromReport),
    selectedPageCategory,
    activeModelCode
  )?.code;

  const displayedReports = reports.filter((report) =>
    isHighlighted(
      report.jobCode,
      report.jobType,
      firstActiveJobCodeOfCurrentCategory
    )
  );
  // Local variables to simplify the tree
  const config = moduleFilesData?.pages[0].info.config
    ? moduleFilesData?.pages[0].info.config
    : baseConfig;

  const augurSettings = moduleFilesData?.pages[0].info.settings
    ? { ...baseSettings, settingsData: moduleFilesData?.pages[0].info.settings }
    : baseSettings;

  async function invalidateModuleFilesData() {
    await queryClient.invalidateQueries(modulesKeys.files(activeProjectPath));
  }

  const getModuleConfig = (moduleCode?: string, moduleVersionCode?: string) => {
    return (
      ((modules
        ?.find((module) => module.code === moduleCode)
        ?.versions?.find((version) => version.code === moduleVersionCode)
        ?.config as unknown) as ModuleConfiguration) ?? FALLBACK_CONFIG
    );
  };

  const onSubmitConfig = async (moduleConfig: ModuleConfiguration) => {
    await saveConfig(notebookUser, moduleConfig, activeProjectPath);

    notebookApi
      .generateModuleTypes(
        activeProjectPath,
        reportAndSettingsSchemas(moduleConfig)
      )
      .then(() =>
        dispatch(
          sendNotification(
            msgsNotifications.titleWorkbenchTerminalPythonGenerated.id,
            // @ts-ignore
            msgsNotifications.titleWorkbenchTerminalPythonGenerated.id,
            EVENT_NOTIFICATION
          )
        )
      )
      .catch((e) =>
        dispatch(
          sendNotification(
            msgsNotifications.titleWorkbenchTerminalPythonGenerationFailed.id,
            // @ts-ignore
            msgsNotifications.titleWorkbenchTerminalPythonGenerationFailed.id,
            ERROR_NOTIFICATION
          )
        )
      );

    // There are two issues when changing the config in the EditAugur
    // - values of deleted elements are not cleared from the AugurSettings file
    // - in the ViewAugur the settings element fields are automatically filled with the default values
    //    this is inconsistent with the actual AugurSettings file but Dirk cannot recognize this or even submit because the form is not dirty
    // --> because of this we update the AugurSettings on EditAugur submission

    // convert the new config and the old settings to the AugurSettings form state to add new default values
    const settingsWithDefaults = toAugurSettingsFormState(
      moduleConfig,
      augurSettings
    );
    // filter new config of invisible elements
    const visibilityDependenciesLookupMap = getVisibilityDependencyArray(
      moduleConfig
    );
    const filteredModuleConfig = filterConfigForElementVisibility(
      moduleConfig,
      visibilityDependenciesLookupMap,
      augurSettings.settingsData
    );
    // convert it back with filtered config (omitting values of invisible elements)
    const newAugurSettings = fromAugurSettingsFormState(
      settingsWithDefaults,
      filteredModuleConfig
    );

    await saveSettings(
      notebookUser,
      newAugurSettings.settingsData,
      activeProjectPath
    );
    await invalidateModuleFilesData();
  };

  if (editMode) {
    return (
      <EditAugur
        moduleConfig={config}
        augurReports={latestAugurReports}
        augurSettings={augurSettings}
        onLeaveEditMode={() => setEditMode(false)}
        onSubmitConfig={onSubmitConfig}
      />
    );
  } else {
    return (
      <ViewAugur
        moduleConfig={config}
        getModuleConfig={getModuleConfig}
        augurReports={displayedReports}
        augurSettings={augurSettings}
        pathPrefix={''}
        isDevMode={true}
        onSubmitAugurSettings={async (settings) => {
          await saveSettings(
            notebookUser,
            settings.settingsData,
            activeProjectPath
          ).then(invalidateModuleFilesData);
        }}
        onEnterEditMode={() => setEditMode(true)}
        hasNoModel={!activeModelCode}
      />
    );
  }
};

/**
 * MemoryRouter wrapper for hiding the Augur paths in the URL for the DevAugur.
 * Necessary because the ViewAugur functions with routing.
 * @param props DevAugur props
 */
const MemoryRouterWrapper: FC<Props> = (props) => {
  const location = useLocation();

  return (
    <ErrorBoundary fullScreenHeight>
      {/* @ts-ignore https://gitlab.sigmalto.com/altasigma-platform/ticket-system/-/issues/1770 */}
      <MemoryRouter
        initialEntries={[
          {
            pathname: '/',
            // this ensures that components can still access the selected dir path inside the DevAugur
            search: location.search,
          },
        ]}
      >
        <div className={styles.devAugurDetailsInner}>
          <OverlayDevAugurDetails />
          <DevAugur {...props} />
        </div>
      </MemoryRouter>
    </ErrorBoundary>
  );
};

export default MemoryRouterWrapper;
