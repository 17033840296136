import PropTypes from 'prop-types';
import React, { Component } from 'react';

import OverlappingMenuEntry from './OverlappingMenuEntry.container';

export default class OverlappingMenu extends Component {
  render() {
    return (
      <div className={'overlapping-menu'}>
        {this.props.overlappingTabs.map((e) => (
          <OverlappingMenuEntry
            key={e.path}
            path={e.path}
            name={
              (
                this.props.pane.content.find(
                  (contentElement) => contentElement.path === e.path
                ) || {}
              ).tabName || e.name
            }
            isActive={this.props.pane.selectedContent === e.path}
            paneId={this.props.pane.id}
          />
        ))}
      </div>
    );
  }
}
OverlappingMenu.propTypes = {
  overlappingTabs: PropTypes.array.isRequired,
  pane: PropTypes.any.isRequired,
};
