import React, { Component } from 'react';
import './styles.scss';
import { FiEdit } from 'react-icons/fi';

import Table from './big-table/Table';

import { ToBeRefined } from 'common/dist/types/todo_type';
import { ColSpec } from 'common/dist/types/dataManagement/cassandra';

import Button from '../../atoms/button/Button';

export type ValueType = {
  /** Indexed by columnId as set for the react-table id (=index of colSpec returned by backend to allow duplicate names) */
  [columnId: string]: {
    oldName: string;
    newName: string;
    newType: string;
  };
};

export type Props = {
  /** Is the data preview editable? */
  editable: boolean;

  /** "input" object that is required if the preview is editable */
  input?: {
    value: ValueType;
    onChange: (value: ValueType) => void;
  };

  /** List of all columns */
  colSpecs: ColSpec[];

  /** List of data */
  data: string[][];

  /** Optional sort function */
  sortBy?: (colSpecA: ColSpec, colSpecB: ColSpec) => number; // -1, 0 or 1
};

type State = {
  editing: boolean;
};

export default class CassandraTablePreview extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { editing: false };
  }

  getSortedData(): { colSpecs: ColSpec[]; data: ToBeRefined } {
    const { colSpecs, data, sortBy } = this.props;

    if (!sortBy) {
      return { colSpecs, data };
    } else {
      const colSpecsWithIndexSorted = colSpecs
        .map((colSpec, __indexOld) => ({ ...colSpec, __indexOld }))
        .sort(sortBy);
      const indexMapping = colSpecsWithIndexSorted.map(
        (colSpec) => colSpec.__indexOld
      );

      return {
        colSpecs: indexMapping.map((indexOld) => colSpecs[indexOld]),
        data: data.map((row) => indexMapping.map((indexOld) => row[indexOld])),
      };
    }
  }

  render() {
    const { editable, input } = this.props;
    const { colSpecs, data } = this.getSortedData();

    return (
      <div className={'CassandraTablePreview'}>
        {editable && (
          <div className={'CassandraTablePreview--headline'}>
            <Button
              Icon={() => <FiEdit size={16} className={'refresh-icon'} />}
              shape={'squared'}
              onClick={() => this.setState({ editing: !this.state.editing })}
            />
          </div>
        )}

        <div className={'CassandraTablePreview--table table-reset'}>
          <Table
            colSpecs={colSpecs}
            data={data}
            editable={editable}
            editing={this.state.editing}
            input={input}
          />
        </div>

        <div className={'CassandraTablePreview--bottom'}>
          <span>Limited table preview with at most 30 observations</span>
        </div>
      </div>
    );
  }
}
