import classNames from 'classnames';
import { Base64Image } from 'common/dist/types/users';
import React, { FC } from 'react';
import Skeleton from 'react-loading-skeleton';

import styles from './styles.module.scss';
import { colors, hashString } from '../avatar-icon/AvatarIcon';

export type Props = {
  avatar?: Base64Image;
  name?: string;
  isLoading?: boolean;
};

const RepositoryAvatar: FC<Props> = ({ name, avatar, isLoading = false }) => {
  if (isLoading) {
    return (
      <div className={styles.repoAvatar}>
        <Skeleton height={'100%'} />
      </div>
    );
  }

  if (!avatar) {
    return (
      <div
        className={classNames(styles.repoAvatar, styles.fallback)}
        style={{
          backgroundColor: colors[hashString(name || '', colors.length)],
        }}
      >
        {(name || '').trim().charAt(0) || '–'}
      </div>
    );
  }

  return (
    <img
      className={styles.repoAvatar}
      src={`data:${avatar.imageType};base64, ${avatar.imageData}`}
      alt={''}
    />
  );
};

export default RepositoryAvatar;
