import React, { CSSProperties, FC, PropsWithChildren } from 'react';
import './borderBox.scss';

type Props = PropsWithChildren<{
  title?: string;
  style?: CSSProperties;
}>;

const BorderBox: FC<Props> = ({ title, children, style = {} }) => (
  <div>
    {title && title !== '' && <div className={'title-box'}>{title}</div>}
    <div className={'border-box'} style={style}>
      {children}
    </div>
  </div>
);

export default BorderBox;
