import { Location } from 'history';
import qs from 'qs';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

import styles from './styles.module.scss';
import TimedSchedules from './TimedSchedules.container';
import { PAGE_SIZE } from '../../../redux/modules/orchestration.schedules.module';
import { JobSchedulesState } from '../../../store/jobSchedules/state.types';
import { locationWithUpdatedQuery } from '../../../utils';
import Busy from '../../atoms/busy/Busy';
import DropdownSelectInput from '../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';
import { PagingParams } from '../../molecules/paging/Paging';

export interface FilterParams extends PagingParams {
  trigger?: string;
}

type Props = {
  /** Overloaded types for the redux state as long as it is js */
  jobSchedules: JobSchedulesState;
  fetchSchedules: (
    trigger?: string,
    offset?: number,
    limit?: number,
    search?: string
  ) => void;
  // Props from pagination to be able to fetch the correct schedules
  deleteScheduleThenFetch: (
    scheduleCode: string,
    trigger?: string,
    offset?: number,
    limit?: number,
    search?: string
  ) => void;
};

function locationWithNewTrigger(
  location: Location,
  newTrigger: string | undefined
): Location {
  const updatedQuery = {
    trigger: newTrigger || undefined,
    offset: '0',
  } as Record<string, string>;
  if (!newTrigger) updatedQuery.period = undefined; // clear period if no trigger is selected
  return locationWithUpdatedQuery(location, updatedQuery);
}

type OptionType = { value: string; label: string };

class JobSchedules extends Component<Props & RouteComponentProps> {
  constructor(props: Props & RouteComponentProps) {
    const {
      jobSchedules: { codes },
    } = props;
    super(props);
    this.state = {
      jobSchedulesMeta: Object.fromEntries(
        codes.map((scheduleCode) => [scheduleCode, { expanded: false }])
      ),
    };
    this.renderFilter = this.renderFilter.bind(this);
  }

  componentDidMount() {
    const { fetchSchedules, location } = this.props;
    const queryParameter: FilterParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    const { trigger, search } = queryParameter;
    const offset = Number.isInteger(Number(queryParameter.offset))
      ? Number(queryParameter.offset)
      : 0;
    const limit = Number.isInteger(Number(queryParameter.limit))
      ? Number(queryParameter.limit)
      : PAGE_SIZE;
    fetchSchedules(trigger, offset, limit, search);
  }

  componentDidUpdate(prevProps: Readonly<Props & RouteComponentProps>) {
    const {
      fetchSchedules,
      location,
      jobSchedules: { codes },
    } = this.props;
    const queryParameter: FilterParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    const { trigger, offset, limit, search } = queryParameter;

    const {
      location: { search: prevSearch },
    } = prevProps;
    const prevQueryParameter: FilterParams = qs.parse(prevSearch, {
      ignoreQueryPrefix: true,
    });
    const { trigger: prevTrigger, offset: prevOffset } = prevQueryParameter;
    if (trigger !== prevTrigger || offset !== prevOffset) {
      const offsetInt = Number.isInteger(Number(offset)) ? Number(offset) : 0;
      const limitInt = Number.isInteger(Number(limit))
        ? Number(limit)
        : PAGE_SIZE;
      fetchSchedules(trigger, offsetInt, limitInt, search);
    }

    // Reset the state that is held for the changed codes, this will add an additional render, but what are you going to do?
    if (prevProps.jobSchedules.codes !== codes) {
      this.setState({
        jobSchedulesMeta: Object.fromEntries(
          codes.map((code) => [code, { expanded: false }])
        ),
      });
    }
  }

  renderFilter() {
    const {
      location: { search },
      location,
      history,
    } = this.props;
    const queryParameter: FilterParams = qs.parse(search, {
      ignoreQueryPrefix: true,
    });
    const { trigger } = queryParameter;

    const timingOptions: OptionType[] = [
      { label: 'Delayed', value: 'delayed' },
      { label: 'Cron', value: 'cron' },
    ];

    return (
      <div className={styles.JobSchedulesFilters}>
        <DropdownSelectInput
          className={styles.JobSchedulesTrigger}
          placeholder={{
            id: 'no-id',
            defaultMessage: 'Filter Schedule Type',
          }}
          options={timingOptions}
          value={timingOptions.find((o) => o.value === trigger)}
          onChange={(option: OptionType) => {
            history.push(locationWithNewTrigger(location, option?.value));
          }}
          clearable={true}
        />
      </div>
    );
  }

  renderLoading() {
    return <Busy isBusy positionAbsolute />;
  }

  renderError() {
    const {
      jobSchedules: { error },
    } = this.props;
    return (
      <div>
        <span>Error: {JSON.stringify(error)}</span>
      </div>
    );
  }

  render() {
    const {
      jobSchedules: { loading, error },
      location,
      deleteScheduleThenFetch,
    } = this.props;

    // TODO: when replacing this with React Query, consider removing location, otherwise remove TODO
    const queryParameter: FilterParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    const { trigger, offset, limit, search } = queryParameter;

    let inner;
    if (loading) {
      inner = this.renderLoading();
    } else if (error) {
      inner = this.renderError();
    } else {
      const offsetInt = Number.isInteger(Number(offset)) ? Number(offset) : 0;
      const limitInt = Number.isInteger(Number(limit))
        ? Number(limit)
        : PAGE_SIZE;
      inner = (
        <TimedSchedules
          /** Pass the deleteScheduleThenFetch action to be able to fill the fetch parameters
           * with offset and limit from the paging component and trigger from this component */
          deleteScheduleThenFetch={(scheduleCode) => {
            deleteScheduleThenFetch(
              scheduleCode,
              trigger,
              offsetInt,
              limitInt,
              search
            );
          }}
          isFilterActive={trigger}
        />
      );
    }

    return (
      <div className={styles.JobSchedulesSchedules}>
        <div className={styles.zIndex101}>{this.renderFilter()}</div>
        <div className={styles.JobSchedulesSubheader}>
          <div className={styles.JobSchedulesSubheaderDescriptions}>
            <strong>Job Group</strong>
            <strong>Name</strong>
            <strong>{trigger === 'cron' ? 'Period' : 'Info'}</strong>
            <strong>Next Execution</strong>
            <strong>By</strong>
            <strong>Actions</strong>
          </div>
        </div>
        {inner}
      </div>
    );
  }
}

export default withRouter(JobSchedules);
