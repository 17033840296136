import orchestrationMessages from 'common/dist/messages/orchestration';
import { SelectedSchedule } from 'common/dist/utils/schedules';
import cronstrue from 'cronstrue';
import React, { FC, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import { FormattedDateTime } from '../../atoms/formatted-date-time/FormattedDateTime';

export type Props = SelectedSchedule;

const ScheduleInfo: FC<Props> = ({ trigger, trigDelayed, trigCron }) => {
  const renderInfoCaption = () => (
    <Fragment>
      <b>
        {trigCron && (
          <FormattedMessage {...orchestrationMessages.scheduleCron} />
        )}
        {trigger === 'delayed' && !trigCron && (
          <FormattedMessage {...orchestrationMessages.scheduleOneTime} />
        )}
      </b>
    </Fragment>
  );

  const renderTimingInfo = () => {
    if (trigDelayed) {
      return (
        <Fragment>
          <FormattedMessage
            {...orchestrationMessages.scheduleDescriptionDelayed}
            values={{
              b: (chunks) => <b>{chunks}</b>,
              date: <FormattedDateTime date={new Date(trigDelayed)} />,
            }}
          />
        </Fragment>
      );
    }

    if (trigCron) {
      return (
        <p>
          {cronstrue.toString(trigCron, {
            dayOfWeekStartIndexZero: true,
            verbose: true,
          })}
        </p>
      );
    }
  };

  return (
    <div>
      <div>
        <span>{renderInfoCaption()}</span>
      </div>
      {renderTimingInfo()}
    </div>
  );
};

export default ScheduleInfo;
