import { createAction } from 'redux-act';
import { call, put, takeEvery } from 'redux-saga/effects';

import { fetchComponentPermissionsSaga } from './dashboard.module';

export const init = createAction('initialize dashboard');
export const inited = createAction('initialize dashboard - finished');

export const reducer = {
  [inited]: (state) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      initialized: true,
    },
  }),
};

export function* initSaga(data) {
  // Fetch the component permissions
  yield call(fetchComponentPermissionsSaga);

  yield put(inited());
  if (data && data.payload && data.payload.callback) {
    data.payload.callback();
  }
}

export function* watchDashboardInit() {
  yield takeEvery(init.getType(), initSaga);
}
