import classNames from 'classnames';
import { Base64Image } from 'common/dist/types/users';
import React, { FC } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import styles from './styles.module.scss';
import { setActiveProjectPath } from '../../../store/workbench/activeProject.slice';
import RepositoryAvatar from '../../atoms/repository-avatar/RepositoryAvatar';
import { getSelectedDirPathQuery } from '../../workbench/utils';

export type Props = {
  path: string;
  slug: string;
  isActive: boolean;
  isRepoLoading: boolean;
  name?: string;
  avatar?: Base64Image;
};

const ProjectCard: FC<Props> = ({
  path,
  slug,
  isActive,
  isRepoLoading,
  name,
  avatar,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  if (isRepoLoading) {
    return (
      <div
        className={classNames(styles.projectCard, {
          [styles.isActive]: isActive,
        })}
      >
        <div className={styles.iconContainer}>
          <RepositoryAvatar name={slug} />
        </div>
        <div className={styles.textContainer}>
          <span className={styles.title}>
            <Skeleton width={100} />
          </span>
          <span className={styles.description}>
            <Skeleton width={100} />
          </span>
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames(styles.projectCard, {
        [styles.isActive]: isActive,
      })}
      onClick={() => {
        dispatch(setActiveProjectPath(isActive ? '' : path));
        history.replace(
          `${history.location.pathname}${getSelectedDirPathQuery([
            isActive ? '' : path,
          ])}`
        );
      }}
      title={isActive ? 'Close Project' : 'Open Project'}
    >
      <div className={styles.iconContainer}>
        <RepositoryAvatar avatar={avatar} name={name} />
      </div>
      <div className={styles.textContainer}>
        <span className={styles.title}>{name}</span>
        <span className={styles.description} title={path}>
          {path}
        </span>
      </div>
    </div>
  );
};

export default ProjectCard;
