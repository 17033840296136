import { useQuery, UseQueryResult } from '@tanstack/react-query';
import {
  ContentsResponse,
  RepositoryWithEntity,
} from 'common/dist/types/repository';
import { PostAddRepositoryBody } from 'common/dist/types/requestBodies/repositories';
import { useCallback, useMemo } from 'react';

import {
  apiRequest,
  CompletedWorkbenchRequest,
  deleteApiRequest,
  postApiRequest,
  putApiRequest,
} from './_apiRequests';
import { Commit } from '../../../components/collaborationSpace/repository-details/merge-requests/merge-request-details/tab-overview/MergeRequestOverview';
import { fetchQueryFnWorkbench } from '../_tools';

export const collabKeys = {
  file: (repoGroup: string, repoFullname: string, fileName: string) =>
    [repoGroup, repoFullname, fileName] as const,
  repoDetails: (repoGroup: string, repoName: string) =>
    [repoGroup, repoName] as const,
};

export function addRepository(
  repositoryName,
  repositoryType,
  repositoryDescription,
  codeCapsuleHabitat
) {
  const url = '/api/workbench/collab/gitrepo';
  const body: PostAddRepositoryBody = {
    repositoryName,
    repositoryType,
    repositoryDescription,
    codeCapsuleHabitat,
  };
  // @ts-ignore
  return postApiRequest(url, body);
}

/**
 * Takes the full name of the repository and the branch, and sends a request to the Dashboard API to request for the
 * latest commit for this branch
 * @param repoName
 * @param branch
 * @param repoGroup - default is a holdover from when the owner of repos was always "altasigma"
 * @returns An array of commits
 */
export function fetchLatestCommit(
  repoName,
  branch,
  repoGroup = 'altasigma'
): CompletedWorkbenchRequest<Commit[]> {
  const url = `/api/workbench/collab/gitrepo/${repoGroup}/${repoName}/branch/${branch}/latestCommit`;
  return apiRequest(url);
}

/**
 * Fetches the content of a repository, for a given ref at a given path
 * @param repoName
 * @param ref
 * @param path
 * @param repoGroup - default is a holdover from when the owner of repos was always "altasigma"
 * @returns {*}
 */
export function fetchRepoContent(repoName, ref, path, repoGroup = 'altasigma') {
  const url = `/api/workbench/collab/gitrepo/${repoGroup}/${repoName}/contents${path}?ref=${ref}`;
  return apiRequest(url);
}

export function deleteRepository(fullRepoName) {
  const url = `/api/workbench/collab/gitrepo/${fullRepoName}`;
  return deleteApiRequest(url);
}

export function getBranches(repoGroup, repoName) {
  const url = `/api/workbench/collab/repository/${repoGroup}/${repoName}/branches`;
  return apiRequest(url);
}

export function fetchRepoDetails(
  repoGroup: string,
  repoName: string
): CompletedWorkbenchRequest<RepositoryWithEntity> {
  const url = `/api/workbench/collab/repository/${repoGroup}/${repoName}/details`;
  return apiRequest(url);
}

export function useRepoDetails(
  repoGroup: string,
  repoName: string,
  enabled = true
): UseQueryResult<RepositoryWithEntity> {
  const key = collabKeys.repoDetails(repoGroup, repoName);
  return useQuery(
    key,
    () =>
      fetchQueryFnWorkbench(key, () => fetchRepoDetails(repoGroup, repoName)),
    {
      enabled,
    }
  );
}

export function openMergeRequest(
  repoGroup,
  repoName,
  src,
  target,
  title,
  description
) {
  const url = `/api/workbench/collab/gitrepo/${repoGroup}/${repoName}/merge-requests`;
  return postApiRequest(url, { src, target, title, description });
}

/**
 * Loads the details of a MergeRequest
 * @param repoGroup
 * @param repoName
 * @param id
 * @returns {*}
 */
export function loadMergeRequest(repoGroup, repoName, id) {
  const url = `/api/workbench/collab/gitrepo/${repoGroup}/${repoName}/merge-requests/${id}`;
  return apiRequest(url);
}

/**
 * Loads the summary list of MergeRequests (with respect to paging)
 * @param repoGroup
 * @param repoName
 * @param state
 * @param page
 * @param limit
 * @returns {*}
 */
export function loadMergeRequests(repoGroup, repoName, state, page, limit) {
  const url = `/api/workbench/collab/gitrepo/${repoGroup}/${repoName}/merge-requests?state=${state}&page=${page}&limit=${limit}`;
  return apiRequest(url);
}

export function mergeMergeRequest(repoGroup, repoName, id) {
  const url = `/api/workbench/collab/gitrepo/${repoGroup}/${repoName}/merge-requests/${id}/merge`;
  return postApiRequest(url, {});
}

export function updateMergeRequest(
  repoGroup,
  repoName,
  id,
  title,
  description,
  targetBranch
) {
  const url = `/api/workbench/collab/gitrepo/${repoGroup}/${repoName}/merge-requests/${id}`;
  return putApiRequest(url, { title, description, targetBranch });
}

/**
 * Takes the full name of the repository and the filename, and sends a request to the Dashboard API to request for the
 * latest file for this branch
 * @param repoName
 * @param repoGroup -
 * @returns Content of the README.md
 */
export function fetchFile(
  repoName: string,
  repoGroup: string,
  fileName: string
): CompletedWorkbenchRequest<ContentsResponse> | undefined {
  if (!fileName) return undefined;

  const url = `/api/workbench/collab/gitrepo/${repoGroup}/${repoName}/file-content/${fileName}`;
  return apiRequest(url);
}

export function fetchContent(
  repoName: string,
  repoGroup: string
): CompletedWorkbenchRequest<ContentsResponse> {
  const url = `/api/workbench/collab/gitrepo/${repoGroup}/${repoName}/contents`;
  return apiRequest(url);
}

export function useReadme(
  repoGroup: string,
  repoName: string,
  enabled: boolean
): UseQueryResult<ContentsResponse> {
  // Define the query key
  const key = useMemo(() => collabKeys.file(repoGroup, repoName, 'README.md'), [
    repoGroup,
    repoName,
  ]);
  // Memoize the fetch function
  const fetchQueryFn = useCallback(async () => {
    const response = await fetchContent(repoName, repoGroup);
    const regex = /readme.md/i;
    if (response.error) {
      console.error('Error fetching content:', response.error);
      return;
    }
    const readmeFiles = ((response.response as unknown) as ContentsResponse[]).filter(
      (x) => x.name.match(regex)
    )?.[0];
    if (readmeFiles?.name) {
      return fetchFile(repoName, repoGroup, readmeFiles.name).then(
        (response) => response.response
      );
    }
  }, [repoName, repoGroup]);
  // Use the query
  return useQuery(key, fetchQueryFn, {
    enabled,
  });
}

export function useFile(
  repoGroup: string,
  repoName: string,
  fileName: string,
  enabled = true
): UseQueryResult<ContentsResponse> {
  const key = collabKeys.file(repoGroup, repoName, fileName);
  return useQuery(key, () =>
    fetchQueryFnWorkbench(key, () => fetchFile(repoName, repoGroup, fileName))
  );
}
