import classNames from 'classnames';
import { HABITAT_SCOPES } from 'common/dist/constants/keycloak';
import augurMessages from 'common/dist/messages/augurs';
import codeCapsuleMessages from 'common/dist/messages/codeCapsules';
import { HabitatWithScopes } from 'common/dist/types/habitat';
import React, { FC } from 'react';
import { FiEdit } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import AugursTab from './AugursTab';
import CodeCapsulesTab from './CodeCapsulesTab';
import Button from '../../../atoms/button/Button';
import { DefaultTooltip } from '../../../atoms/tooltip/Tooltip';
import { repositoryCreateHabitatLink } from '../../../collaborationSpace/routes';
import * as routes from '../../../index/routes';
import { getNewAugurLink } from '../../../index/routes';
import { HABITAT_TAB, OrderType } from '../Habitat';
import Order from '../Order';
import styles from '../styles.module.scss';
import ViewSwitch from '../ViewSwitch';

type Props = {
  activeTab: HABITAT_TAB;
  habitat: HabitatWithScopes;
  /** Set the active tab for this Habitat */
  setActiveTab: (activeTab: HABITAT_TAB) => void;
  /** Is the augurs tab disabled? */
  codeCapsulesCount: number;
  isListView: boolean;
  setHabitatView: (isListView: boolean) => void;
  order: OrderType;
  setOrder: (order: OrderType) => void;
};

const Header: FC<Props> = (props) => {
  const {
    activeTab,
    habitat,
    setActiveTab,
    codeCapsulesCount,
    isListView,
    setHabitatView,
    order,
    setOrder,
  } = props;

  const editPermitted = habitat.allowedScopes?.includes(HABITAT_SCOPES.EDIT);

  return (
    <div className={styles.habitatHeader}>
      <div className={styles.headerName} data-testid={habitat.name}>
        <span className={classNames('icon-home', styles.habitatIcon)} />
        <span className={styles.habitatName}>{habitat.name}</span>

        {editPermitted && (
          <div
            className={styles.editHabitatIcon}
            data-tooltip-id='edit-habitat-tooltip'
          >
            <Link
              to={
                routes.app.prefix +
                routes.app.models +
                '/' +
                routes.app.newHabitat +
                '/' +
                habitat.code
              }
            >
              <FiEdit size={16} />
            </Link>

            <DefaultTooltip id='edit-habitat-tooltip'>
              Edit Habitat
            </DefaultTooltip>
          </div>
        )}
      </div>
      <div className={styles.headerTabs} data-testid={'Tabs'}>
        <AugursTab
          habitat={habitat}
          isActive={activeTab === HABITAT_TAB.AUGURS}
          setActive={() => setActiveTab(HABITAT_TAB.AUGURS)}
          isDisabled={false}
        />

        <CodeCapsulesTab
          isActive={activeTab === HABITAT_TAB.CODE_CAPSULES}
          setActive={() => setActiveTab(HABITAT_TAB.CODE_CAPSULES)}
          isDisabled={false}
          codeCapsulesCount={codeCapsulesCount}
        />
      </div>

      <div className={styles.headerViewType}>
        <ViewSwitch isListView={isListView} setHabitatView={setHabitatView} />
      </div>

      <div className={styles.headerRightPart}>
        {[HABITAT_TAB.AUGURS].includes(activeTab) && (
          <Order order={order} setOrder={setOrder} />
        )}
        {activeTab === HABITAT_TAB.AUGURS && (
          <Button
            id={'newAugurButton'}
            label={augurMessages.augurNew}
            color={'secondary'}
            linkTo={getNewAugurLink(habitat.code)}
            className={styles.headerButton}
            Icon={() => (
              <span
                className={classNames(styles.headerButtonIcon, 'icon-plus')}
              />
            )}
          />
        )}
        {activeTab === HABITAT_TAB.CODE_CAPSULES && (
          <Button
            id={'newCodeCapsuleButton'}
            label={codeCapsuleMessages.ccNew}
            color={'secondary'}
            linkTo={repositoryCreateHabitatLink('code-capsule', habitat.code)}
            className={styles.headerButton}
            Icon={() => (
              <span
                className={classNames(styles.headerButtonIcon, 'icon-plus')}
              />
            )}
          />
        )}
      </div>
    </div>
  );
};

export default Header;
