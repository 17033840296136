import msgsCommon from 'common/dist/messages/common';
import msgsRepository from 'common/dist/messages/repository';
import { Branch, RepoType } from 'common/dist/types/repository';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import LatestCommit from './LatestCommit';
import RepositoryBreadcrumbs from './RepositoryBreadcrumbs';
import RepositoryButtonBar from './RepositoryButtonBar';
import RepositoryTypeLabel from './RepositoryTypeLabel';
import styles from './styles.module.scss';
import { useDeleteRepository } from '../../../core/api/workbench/repositories';
import { hideDeleteRepoConfirm } from '../../../redux/workbench/modules/collab.module';
import { DeprecatedRootState } from '../../../store/state.type';
import { useAppDispatch } from '../../../store/store';
import DropdownSelectInput, {
  Option,
} from '../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';
import { RepositoryData } from '../../collaborationSpace/repository-details/repositoryOverview/RepositoryOverview';
import { overviewPlainLink } from '../../collaborationSpace/routes';
import ConfirmationModal from '../../organisms/confirmation-modal/ConfirmationModal';

type Props = {
  repositoryType?: RepoType;
  loading?: boolean;
  error?: string;
  data?: RepositoryData;
  branches?: Branch[];
  activeBranch?: Branch;
  switchBranch: (branchName: string) => void;
};

const RepositoryTop: FC<
  Pick<
    Props,
    'loading' | 'error' | 'data' | 'branches' | 'activeBranch' | 'switchBranch'
  >
> = ({ loading, error, data, branches, activeBranch, switchBranch }) => {
  const history = useHistory();
  const deleteRepoConfirm = useSelector<
    DeprecatedRootState,
    DeprecatedRootState['workbench']['collab']['deleteRepoConfirm']
  >((state) => state.workbench.collab.deleteRepoConfirm);
  const dispatch = useAppDispatch();
  const { mutate: deleteRepo } = useDeleteRepository(
    deleteRepoConfirm?.repoType,
    () => {
      // TODO: this should ideally be a generic link, but for now this works
      //   https://gitlab.sigmalto.com/altasigma-platform/ticket-system/-/issues/1571
      history.push(overviewPlainLink());
    }
  );

  if (loading) {
    // TODO Implement nicer loading screen
    return (
      <div className={styles.repositoryTop}>
        <div className={styles.repositoryInfo}>Loading ...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.repositoryTop}>
        <div className={styles.repositoryInfo}>{JSON.stringify(error)}</div>
      </div>
    );
  }

  const { name, repoDescription, repoType, repoFullName } = data || {};
  const parts = (repoFullName || '').split('/');
  const nameShown = name !== undefined ? name : parts[1];

  const options = (branches || []).map((branch) => ({
    label: branch.name,
    value: branch.name,
  }));

  // TODO This is a hack to fix the UI, the default "master" branch should be fetched from the backend
  if (options.length === 0) {
    options.push({ label: 'master', value: 'master' });
  }

  return (
    <div className={styles.repositoryTop}>
      <div className={styles.repositoryInfo}>
        <div className={styles.repositoryTopLine}>
          <span className={styles.repositoryName}>{nameShown}</span>
          <RepositoryTypeLabel repoType={repoType} />
        </div>
        {repoDescription ? (
          <span className={styles.repositoryDescription}>
            {repoDescription}
          </span>
        ) : (
          <span className={styles.repositoryDescription}>
            <i>No description available</i>
          </span>
        )}
        <div className={styles.repositoryActiveBranch}>
          <span>Active Branch</span>
          <DropdownSelectInput
            id='git-branch-selector'
            name='active-branch'
            className={styles.gitBranchSelector}
            options={options}
            value={options.find(
              (o) => o.value === (activeBranch?.name || 'master')
            )}
            onChange={(option: Option) => switchBranch(option.value)}
            clearable={false}
          />
        </div>
      </div>
      <RepositoryButtonBar repoType={repoType} />

      <ConfirmationModal
        show={deleteRepoConfirm?.show}
        payload={deleteRepoConfirm?.repoFullName}
        secure={true}
        secureInput={nameShown}
        headlineColor={'red'}
        headline={msgsRepository.deleteRepositoryModalHeadline}
        description={{
          ...msgsRepository.deleteRepositoryModalDescription,
          values: { nameShown: <code>{nameShown}</code> },
        }}
        buttonConfirmColor={'red'}
        buttonConfirmText={msgsCommon.delete}
        buttonConfirmAction={(repoFullName: string) =>
          deleteRepo({ repoFullName })
        }
        hideModal={() => {
          dispatch(hideDeleteRepoConfirm());
        }}
      />
    </div>
  );
};

const RepositoryHeader: FC<Props> = (props) => {
  const {
    repositoryType,
    loading,
    error,
    data,
    branches,
    activeBranch,
    switchBranch,
  } = props;
  return (
    <div className={styles.repositoryHeader}>
      <RepositoryBreadcrumbs repositoryType={repositoryType} />

      <RepositoryTop
        loading={loading}
        error={error}
        data={data}
        branches={branches}
        activeBranch={activeBranch}
        switchBranch={switchBranch}
      />

      <LatestCommit activeBranch={activeBranch} />
    </div>
  );
};

export default RepositoryHeader;
