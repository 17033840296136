import classNames from 'classnames';
import { ModuleWithAllRelations } from 'common/dist/types/module';
import { ModuleVersion } from 'common/dist/types/moduleVersion';
import React, { FC } from 'react';

import styles from './styles.module.scss';
import { DropdownSelectInput } from '../../atoms/react-hook-form-input-elements/dropdown-select-input/DropdownSelectInput';
import RepositoryAvatar from '../../atoms/repository-avatar/RepositoryAvatar';

type Props = ModuleWithAllRelations & {
  onVersionSelected: (version: ModuleVersion) => void;
  isSelected?: boolean;
  selectedVersion?: ModuleVersion;
  /** Grey out the card because another card is selected */
  explicitlyNotSelected?: boolean;
};

const ModuleVersionSelectorTile: FC<Props> = (props) => {
  const {
    onVersionSelected,
    name,
    isSelected,
    selectedVersion,
    explicitlyNotSelected,
    moduleAvatar,
    versions = [],
    description,
    isStock,
  } = props;

  const latestVersion =
    versions.length < 1
      ? undefined
      : versions.reduce((acc, version) =>
          acc.createdAt > version.createdAt ? acc : version
        );
  const moduleVersion = selectedVersion || latestVersion;

  const options = versions.map((version: ModuleVersion) => ({
    label: version.number,
    value: version.code,
  }));

  return (
    <div
      data-testid={'Module_' + name}
      className={classNames(
        styles.moduleCard,
        { [styles.isSelected]: isSelected },
        { [styles.explicitlyNotSelected]: explicitlyNotSelected },
        { [styles.isStock]: isStock }
      )}
      title={description ?? 'No Description available'}
      onClick={() => {
        onVersionSelected?.(
          versions.find((version) => version.code === latestVersion?.code)
        );
      }}
    >
      {isStock && (
        <img
          src={'/favicons/apple-touch-icon.png'}
          alt={'as'}
          title={'AltaSigma'}
        />
      )}
      <div className={styles.iconContainer}>
        <RepositoryAvatar avatar={moduleAvatar} name={name} />
      </div>
      <div className={styles.nameAndVersionContainer}>
        <span className={styles.name} title={name}>
          {name}
        </span>
        <div
          onClick={(event) => {
            if (isSelected) event.stopPropagation();
          }}
          className={styles.versionSelectContainer}
        >
          <DropdownSelectInput
            disabled={!isSelected}
            options={options}
            name={'ModuleVersion'}
            appearance={'small'}
            value={options.find(
              (option) => option.value === moduleVersion.code
            )}
            onChange={(option) => {
              const newVersion = versions.find(
                (version) => version.code === option.value
              );
              onVersionSelected?.(newVersion);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ModuleVersionSelectorTile;
