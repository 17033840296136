import { RESOURCE_TYPE_DASHBOARD_ORCHESTRATION_SUFFIX } from 'common/dist/constants/keycloak';
import { hasAccessPermission } from 'common/dist/utils/authorization.general';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { JOB_GROUP_VARIANTS } from './details/job-group-details/JobGroupDetails';
import JobHistoryGroupDetails from './details/job-group-details/JobHistoryGroupDetails.container';
import JobScheduleGroupDetails from './details/job-group-details/JobScheduleGroupDetails.container';
import JobTemplateGroupDetails from './details/job-group-details/JobTemplateGroupDetails.container';
import JobConfigurationContainer from './job-configuration/JobConfiguration.container';
import CodeCapsuleOutput from './job-details/code-capsule-output/CodeCapsuleOutput.container';
import JobDetails from './job-details/JobDetails.container';
import JobLogs from './job-details/JobLogs.container';
import JobGroups from './job-groups/JobGroups';
import JobGroupWizard from './job-groups/wizard/JobGroupWizard.container';
import JobHistoryContainer from './job-history/JobHistory.container';
import JobQueueContainer from './job-queues/Queue.container';
import NewScheduleWizard from './job-schedules/add-schedule/NewScheduleWizard.container';
import JobSchedulesPaginated from './job-schedules/JobSchedulesPaginated.container';
import RealtimeDetails from './realtime/details/RealtimeDetails';
import RealtimeSummary from './realtime/RealtimeSummary';
import { orchestrationRoutes } from './routes';
import { isAdmin } from '../../redux/selectors/user.selector';
import { RootState } from '../../store/store';
import { toLinkWhitelistQuery } from '../../utils';
import { MAIN_MENU_QUERY_WHITELIST } from '../organisms/main-menu/MainMenu';
import MainContainer from '../pages/main-container/MainContainer';
import NoPermissions from '../pages/no-permissions/NoPermissions';
import NotFound from '../pages/not-found/NotFound.container';

const Orchestration: FC = () => {
  const location = useLocation();

  const isAdminUser = useSelector(isAdmin);
  const permissions = useSelector<RootState>(
    (state) => state.dashboard?.permissions?.data || {}
  ) as Record<string, string[]>; // FIXME
  const missingPermissionsError = !hasAccessPermission(
    permissions,
    RESOURCE_TYPE_DASHBOARD_ORCHESTRATION_SUFFIX
  );
  if (missingPermissionsError) {
    return <NoPermissions fullViewHeight />;
  }

  return (
    <MainContainer
      additionalClassname={'Orchestration'}
      transparent={false}
      fullWidth
      scrollableY={false}
    >
      <Switch>
        <Route
          path={`${orchestrationRoutes.basePath}/${orchestrationRoutes.queue.path}`}
          component={() => <JobQueueContainer />}
        />
        <Route
          path={`${orchestrationRoutes.basePath}/${orchestrationRoutes.history.path}`}
        >
          <JobHistoryContainer />
        </Route>
        <Route
          path={`${orchestrationRoutes.basePath}/${orchestrationRoutes.schedulings.path}`}
        >
          <JobSchedulesPaginated />
        </Route>
        <Route
          path={`${orchestrationRoutes.basePath}/${orchestrationRoutes.addSchedule.path}`}
        >
          {/*@ts-ignore FIXME */}
          <NewScheduleWizard />
        </Route>
        <Route
          path={`${orchestrationRoutes.basePath}/${orchestrationRoutes.editSchedule.path}/:scheduleCode`}
          render={(routeProps) => (
            //@ts-ignore FIXME
            <NewScheduleWizard
              edit={true}
              scheduleCode={routeProps.match.params.scheduleCode}
            />
          )}
        />
        {/*Realtime Prediction overview*/}
        <Route
          exact
          path={`${orchestrationRoutes.basePath}/${orchestrationRoutes.realtime.path}`}
          component={() => <RealtimeSummary />}
        />
        {/*Realtime Prediction details*/}
        <Route
          path={`${orchestrationRoutes.basePath}/${orchestrationRoutes.realtime.path}/${orchestrationRoutes.realtime.details}/:augurCode`}
          render={(routeProps) => (
            <RealtimeDetails augurCode={routeProps.match.params.augurCode} />
          )}
        />
        {isAdminUser && (
          <Route
            exact
            path={`${orchestrationRoutes.basePath}/${orchestrationRoutes.jobConfiguration.path}`}
            component={() => <JobConfigurationContainer />}
          />
        )}
        {isAdminUser && (
          <Route
            path={`${orchestrationRoutes.basePath}/${orchestrationRoutes.jobDetails.path}/:jobCode/logs`}
            render={(routeProps) => (
              <JobLogs jobCode={routeProps.match.params.jobCode} />
            )}
          />
        )}
        <Route
          path={`${orchestrationRoutes.basePath}/${orchestrationRoutes.jobDetails.path}/:jobCode/ccoutput`}
        >
          <CodeCapsuleOutput />
        </Route>
        <Route
          path={`${orchestrationRoutes.basePath}/${orchestrationRoutes.jobDetails.path}/:jobCode`}
        >
          <JobDetails />
        </Route>
        <Route
          path={`${orchestrationRoutes.basePath}/${orchestrationRoutes.jobGroups.path}`}
        >
          <JobGroups />
        </Route>
        <Route
          path={`${orchestrationRoutes.basePath}/${orchestrationRoutes.addJobGroup.path}`}
        >
          <JobGroupWizard />
        </Route>
        <Route
          path={`${orchestrationRoutes.basePath}/${orchestrationRoutes.editJobGroup.path}/:jobGroupCode`}
          render={(routeProps) => (
            <JobGroupWizard
              edit={true}
              jobGroupCode={routeProps.match.params.jobGroupCode}
            />
          )}
        />
        <Route
          path={`${orchestrationRoutes.basePath}/${orchestrationRoutes.jobGroupDetails.path}/${JOB_GROUP_VARIANTS.jobHistory}/:jobGroupCode`}
        >
          <JobHistoryGroupDetails />
        </Route>
        <Route
          path={`${orchestrationRoutes.basePath}/${orchestrationRoutes.jobGroupDetails.path}/${JOB_GROUP_VARIANTS.jobTemplate}/:jobGroupCode`}
        >
          <JobTemplateGroupDetails />
        </Route>
        <Route
          path={`${orchestrationRoutes.basePath}/${orchestrationRoutes.jobGroupDetails.path}/${JOB_GROUP_VARIANTS.jobSchedule}/:jobGroupCode`}
        >
          <JobScheduleGroupDetails />
        </Route>
        <Redirect
          exact
          path={orchestrationRoutes.basePath}
          to={toLinkWhitelistQuery(
            `${orchestrationRoutes.basePath}/${orchestrationRoutes.queue.path}`,
            location,
            MAIN_MENU_QUERY_WHITELIST
          )}
        />
        <Route component={NotFound} />
      </Switch>
    </MainContainer>
  );
};

export default Orchestration;
