import { ModuleVersion } from './moduleVersion';
import { Repository } from './repository';
import { Base64Image } from './users';

export type Module = {
  /** Code of the module*/
  code: string;
  /** Name of the module */
  name: string;
  isStock: boolean;
  createdBy: string | null;
  description: string | null;
  moduleAvatar: ModuleAvatar | null;
};

export type ModuleWithAllRelations = Module & {
  versions: ModuleVersion[];
  repository?: Repository;
};

/**
 * Url parameters for fetching modules
 */
export type ModulesParameters = {
  offset?: number;
  limit?: number;
  search?: string;
  has_repository?: string; //boolean as string because of the queryparameter
  has_versions?: string; //boolean as string because of the queryparameter
  created_by?: string;
  is_stock?: string;
};

/**
 * Url parameters for fetching versions
 */
export type ModuleVersionsParameters = {
  offset?: number;
  limit?: number;
  search?: string;
  moduleCode?: string;
};

export type BuildModuleJob = {
  jobCode: string;
  superType: string;
  jobType: string;
  priority: string;
  executionType: string;
  repositoryCode: string;
  moduleCode: string;
  moduleVersionNumber: string;
};
export const BuildModuleJobDefaults = {
  superType: 'module',
  jobType: 'build-module',
  priority: 'medium',
  executionType: 'other',
};

export type ModuleAvatar = Base64Image;
