import classNames from 'classnames';
import React, { FC, Fragment } from 'react';
import { IconType } from 'react-icons';
import { MessageDescriptor } from 'react-intl';
import ReactLoading from 'react-loading';
import { Link } from 'react-router-dom';

import styles from './styles.module.scss';
import { useThemeColor } from '../../../utils';
import IntlWrapper from '../intl-wrapper/IntlWrapper';
import { DeIntl } from '../intl-wrapper/type';
import { LinkWithQuery } from '../link-with-query/LinkWithQuery';

export type ButtonProps = Omit<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >,
  'title'
> &
  AdditionalButtonProps;

export type AdditionalButtonProps = {
  /** Target URL of the link */
  linkTo?: string;
  shape?: 'bubble' | 'squared' | 'tab';
  color?:
    | 'primary'
    | 'secondary'
    | 'primaryHighlight'
    | 'blue'
    | 'white'
    | 'green'
    | 'red'
    | 'orange'
    | 'transparent';
  /** Text that is displayed on the button. Can either be a string or a MessageDescriptor. */
  label?: MessageDescriptor | string;
  /** Title button attribute. Included here to include MessageDescriptor. */
  title?: MessageDescriptor | string;
  /** Render a busy indicator instead of the label */
  isBusy?: boolean;
  /** Optional icon to display on the left next to the text */
  Icon?: IconType;
  /** Flag to indicate active toggle state */
  toggled?: boolean;
  /** Whether to disable the hover animation */
  disableHoverAnimation?: boolean;
  /** Whether query parameters are preserved for links, only works with linkTo */
  preserveQueryParams?: boolean;
};

const Button: FC<DeIntl<ButtonProps>> = (props) => {
  const {
    shape = 'bubble',
    color = 'primary',
    Icon,
    label,
    isBusy = false,
    toggled = false,
    linkTo,
    preserveQueryParams = true,
    disableHoverAnimation = false,
    className,
    disabled,
    type = 'button',
    ...buttonProps
  } = props;

  // we use `props.xxx` to circumvent the defaults
  if ((shape === 'squared' || shape === 'tab') && props.color) {
    console.warn('Colors are currently not supported for square button shape!');
  }
  if (shape === 'bubble' && props.toggled) {
    console.warn(
      'Toggle state is currently not supported for rounded button shape!'
    );
  }

  const primaryColor = useThemeColor('primary');

  const buttonClassNames = classNames(styles.button, className, {
    [styles.busy]: isBusy,
    [styles.label]: !!label,
    [styles.noLabel]: !label,
    [styles.toggled]: toggled,
    [styles.rounded]: shape === 'bubble',
    [styles.squared]: shape === 'squared',
    [styles.tab]: shape === 'tab',
    [styles.colorPrimary]: color === 'primary',
    [styles.colorSecondary]: color === 'secondary',
    [styles.colorPrimaryHighlight]: color === 'primaryHighlight',
    [styles.colorBlue]: color === 'blue',
    [styles.colorGreen]: color === 'green',
    [styles.colorOrange]: color === 'orange',
    [styles.colorRed]: color === 'red',
    [styles.colorWhite]: color === 'white',
    [styles.colorTransparent]: color === 'transparent',
    [styles.disableHover]: disableHoverAnimation,
  });

  function renderInner() {
    if (isBusy) {
      return (
        <div className={styles.busy} data-testid={'busyButton'}>
          <ReactLoading
            className={'busy'}
            type={'bubbles'}
            color={
              color === 'white' || color === 'transparent'
                ? primaryColor
                : 'white'
            }
          />
        </div>
      );
    } else {
      return (
        <Fragment>
          {Icon && (
            <div className={styles.icon}>
              <Icon />
            </div>
          )}
          {label && <span>{label}</span>}
        </Fragment>
      );
    }
  }

  const button = (
    <button
      data-testid={label}
      className={buttonClassNames}
      disabled={disabled || isBusy}
      type={type}
      {...buttonProps}
    >
      {renderInner()}
    </button>
  );

  if (linkTo && !disabled && !isBusy) {
    if (preserveQueryParams) {
      return (
        <LinkWithQuery to={linkTo} className={styles.link}>
          {button}
        </LinkWithQuery>
      );
    } else {
      return (
        <Link to={linkTo} className={styles.link}>
          {button}
        </Link>
      );
    }
  }

  return button;
};

const IntlButton: FC<ButtonProps> = (props) => (
  <IntlWrapper WrappedComponent={Button} props={props} />
);

export default IntlButton;
