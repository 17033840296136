import { connect, ConnectedProps } from 'react-redux';

import JobSchedulesPaginated from './JobSchedulesPaginated';
import {
  deleteScheduleThenFetch,
  fetchSchedules,
  PAGE_SIZE,
} from '../../../redux/modules/orchestration.schedules.module';
import { RootState } from '../../../store/store';

export function mapStateToProps(state: RootState) {
  return {
    pageSize: PAGE_SIZE,
    loading: state.jobSchedules.loading,
    loaded: state.jobSchedules.loaded,
    error: state.jobSchedules.error,
    jobSchedules: state.jobSchedules,
  };
}

export const mapDispatchToProps: {
  fetchSchedules: (
    trigger: string | undefined,
    offset: number,
    limit: number,
    search: string
  ) => void;
  deleteScheduleThenFetch: (
    scheduleCode,
    trigger,
    offset,
    limit,
    search
  ) => void;
} = {
  fetchSchedules,
  deleteScheduleThenFetch,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type CProps = ConnectedProps<typeof connector>;
export default connector(JobSchedulesPaginated);
