import commonMessages from 'common/dist/messages/common';
import orchestrationMessages from 'common/dist/messages/orchestration';
import { ToBeRefined } from 'common/dist/types/todo_type';
import React, { Component } from 'react';

import styles from './styles.module.scss';
import { connectedProps } from './TimedSchedules.container';
import IndicatorEmpty from '../../molecules/indicator-empty/IndicatorEmpty';
import Schedule from '../../molecules/job-groups/job-group-schedule/JobGroupSchedule.container';
import ConfirmationModal from '../../organisms/confirmation-modal/ConfirmationModal';

export interface Props {
  /** Array of schedule codes (keys only) */
  codes?: string[];
  /** Map of schedules that run timed with a ${period} schedule */
  schedules?: Record<string, ToBeRefined>;
  /** Delete a schedule and fetch the schedules afterwards */
  deleteScheduleThenFetch: (scheduleCode: string) => void;
  /** These just pass the state from the parent to here where it can be filled with the code and passed on*/
  expandedTemplate: (code: string) => boolean;
  setExpandedTemplate: (code: string) => (newValue: boolean) => void;
  /** Is currently a filter active? */
  isFilterActive?: boolean;
}

export default class TimedSchedules extends Component<Props & connectedProps> {
  renderContent() {
    const {
      codes,
      schedules,
      deleteScheduleThenFetch,
      hideDeleteSchedule,
      deleteModal,
    } = this.props;
    return (
      <div>
        <div className={styles.schedulesList}>
          {codes.map((code, i) => {
            return (
              <Schedule
                schedule={schedules?.[code]}
                key={i}
                scheduleCode={code}
              />
            );
          })}
        </div>
        <ConfirmationModal
          secure={false}
          show={deleteModal?.show}
          payload={{ scheduleCode: deleteModal?.scheduleCode }}
          buttonConfirmAction={({ scheduleCode }) => {
            deleteScheduleThenFetch(scheduleCode);
          }}
          hideModal={hideDeleteSchedule}
          buttonConfirmText={commonMessages.delete}
          buttonConfirmColor={'red'}
          description={orchestrationMessages.jobScheduleDeleteModalDescription}
          headline={orchestrationMessages.jobScheduleDeleteModalHeadline}
          headlineColor={'red'}
        />
      </div>
    );
  }

  render() {
    const { codes, isFilterActive } = this.props;

    const headlineDefault = isFilterActive
      ? 'There are no scheduled jobs for your filter query'
      : 'There are no scheduled jobs yet';

    const descriptionDefault = isFilterActive
      ? ''
      : 'Once a schedule is created it will appear here';

    return (
      <div className={styles.timedSchedules}>
        {codes.length === 0 ? (
          <IndicatorEmpty
            classNameImage={'schedules-empty-pic'}
            headlineId={'no-id'}
            headlineDefault={headlineDefault}
            descriptionId={''}
            descriptionDefault={descriptionDefault}
          />
        ) : (
          this.renderContent()
        )}
      </div>
    );
  }
}
