import { connect } from 'react-redux';

import Workspace from './Workspace';

export function mapStateToProps(state) {
  return {
    notebooks: state.workbench.notebooks,
    showOnRight: state.workbench.showOnRight,
    showDeleteContent: state.workbench.showDeleteContent,
  };
}

export const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Workspace);
